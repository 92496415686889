/////////////////////
// Output Card UI used on Output Step of New Project Wizard
/////////////////////

// Basic Imports
import React, { FC } from 'react'

// Design Imports
import {
  HStack,
  Icon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import {
  FaHashtag,
} from 'react-icons/fa'

// Interfaces
interface Props {
  min?: number
  max?: number
  value?: string
  defaultValue?: number
  onChange?: (value: string) => void
  readOnly?: boolean
}

const RenderQuantity:FC<Props> = ({ value, defaultValue, min, max, onChange, readOnly }) => {
  return (
    <HStack>
      <Icon as={FaHashtag} color="gray.500" />
      <Tooltip hasArrow placement="auto-start" label="How many camera capture locations do you want ?">
        <Text fontWeight="semibold">Quantity: </Text>
      </Tooltip>
      <NumberInput 
        isDisabled={readOnly}
        defaultValue={defaultValue ?? 1} 
        value={readOnly ? defaultValue : value} 
        onChange={(value) => onChange(value)} 
        min={min ?? 1} 
        max={max} 
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </HStack>
  )
}

export default RenderQuantity
