/////////////////////
// Completed Projects Page
/////////////////////

// Basic Imports
import { FC, Fragment, useState } from "react";

// Design Imports
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Layout and Section Imports

// Data Imports
import { REACT_QUERY_KEYS } from "settings/constants/ReactQuery";

// Interfaces

// Custom Hooks and Services
import useAuth from "hooks/useAuth";
import { TaskSectionCard } from "components/sections/TaskSection";
import useFetchTasks from "hooks/useFetchTask";
import { fetchAllTaskByAssignee } from "services/taskService";

// Functions

const InProgressTask: FC = () => {
  const { auth, user }: any = useAuth();
  const [fetchTask, setFetchTask] = useState("");
  const { task, isLoading, refetch } = useFetchTasks(
    [REACT_QUERY_KEYS.COMPLETED_PROJECTS, fetchTask],
    () => fetchAllTaskByAssignee(auth?.accessToken || "", user?._id || ""),
    { enabled: !!user?._id }
  );
  if (!!isLoading || !task) return <SkeletonLoading />;
  return (
    <Fragment>
      <TaskSectionCard
        task={task.filter((task) => task.status === "in-progress")}
        auth={auth}
        fetchFlag={refetch}
      />
    </Fragment>
  );
};

export default InProgressTask;
