/////////////////////
// MobileHamburgerMenu (appMenu) UI
/////////////////////

// Basic Imports


// Design Imports
import {
  Box
} from '@chakra-ui/react'

import {
  HiOutlineMenu,
  HiX
} from 'react-icons/hi'

// Layout and Section Imports


// Data Imports


// Interfaces
interface MobileHamburgerMenuProps {
  onClick?: VoidFunction
  isOpen: boolean
}

// Functions
export const MobileHamburgerMenu = (props: MobileHamburgerMenuProps) => {
  const { onClick, isOpen } = props
  return (
    <Box ms="-4" minW={{ base: '12', lg: '76px' }} display={{ lg: 'none' }}>
      <Box as="button" onClick={onClick} p="2" fontSize="xl">
        <Box aria-hidden as={isOpen ? HiX : HiOutlineMenu} />
        <Box srOnly>{isOpen ? 'Close menu' : 'Open menu'}</Box>
      </Box>
    </Box>
  )
}
