///////////////////////////////
// Project Table: Name Column
///////////////////////////////

// Basic Imports
import { Link } from "react-router-dom";

// Design Imports
import {
  HStack,
  Icon,
  Text,
  Tooltip
} from "@chakra-ui/react";

import { GoProject } from "react-icons/go";
import { AiFillEye, AiOutlineCodepenCircle } from "react-icons/ai";

// Settings Imports
import { getProjectLink } from "utils/getProjectLink";

const Header = () => {
  // Header is wrapper on column (e.g., you can change text color, background-color etc)
  return (
    <HStack>
      <Icon as={GoProject} />
      <Text>Project Name</Text>
    </HStack>
  );
};

const Cell = (props) => {
  const { value, ...metaData } = props;

  // Cell is wrapper on row (e.g., you can change text color, background-color etc)
  const currRow = metaData.cell.row.original;

  // INFO: Throw error if id is invalid because it is being used in in routing, if it is invalid then it will be broken link that is dangerous for SEO purpose
  if (!currRow.id) {
    throw new Error(
      "Id of the project is invalid! Make sure to double check the schema of the project..."
    );
  }


  return (
    <>
      <HStack w="full">
        <HStack width="240px" as={Link} to={getProjectLink(currRow.state, currRow.id)}>
          <Icon as={AiOutlineCodepenCircle} boxSize="6" color={"#E74F4F"} />
          <Tooltip label={value}>
            <Text noOfLines={1}>
              {value}
            </Text>
          </Tooltip>
        </HStack>
        <HStack justifyContent={"right"} as={Link} to={getProjectLink(currRow.state, currRow.id)}>
          <Icon as={AiFillEye} boxSize="4" />
        </HStack>
      </HStack>
    </>
  );
};

export default {
  Header,
  Cell,
};
