import { getObjectFulFillmentPercentage } from "./object";

export function getCompletionPercentage({ projectInfo, selectedAssets, selectedOutputs, selectedStyles, attachments }) {
  let stage = 0;

  // INFO: ProjectInfo -> 20%
  if (Object.keys(projectInfo)) {
    // It will check how many input fields has been filled
    const percentage: number = getObjectFulFillmentPercentage(projectInfo);
    stage += Math.round(percentage * 20 / 100);
  }

  // INFO: selectedAssets + selectedOutputs + selectedStyles -> 60%
  if (selectedAssets.length > 0) stage += 20;
  if (selectedOutputs.length > 0) stage += 20;
  if (selectedStyles?.length > 0) stage += 20;

  // INFO: Attachments -> 20%
  if (attachments.length > 0) stage += 20;

  return stage
}