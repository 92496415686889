export function render_cloudinary_asset_type_db(cloudinaryComment: any) {
  return cloudinaryComment.format;
}

export function getAssetQuantity(assetName: any) {
  return assetName.replace(/[^0-9]/g, "");
}

export const assetType = {
  _other: "other",
  _3d_model: "3d_model",
  _3d_render: "3d_render",
  _2d_render: "2d_render",
  _2d_floor_plan: "2d_floor_plan",
  _3d_floor_plan: "floor_plan_3d",
  _virtual_staging: "virtual_stating",
  _3d_commercial_: "commercial_3d",
  _land_development_3d: "land_development_3d",
  _marketing_brochure: "marketing_brochure",
  _specification_sheet: "specification_sheet",
  _landscape_plan: "landscape_plan",
};

export function render_asset(asset: any) {
  let assetsFilter = [];
  if (asset && asset.data && asset.data.length > 0) {
    let assets = asset.data.reduce(function (accumulator, currentValue) {
      currentValue.attachments.forEach((element) => {
        accumulator &&
          accumulator.push({
            id: currentValue._id,
            url: element.cdnUrl,
            name: currentValue.name,
            createdBy: currentValue.createdBy,
            description: currentValue.description,
            version: currentValue.version,
            projectNotes: currentValue.notes,
          });
      });
      return accumulator;
    }, []);
    assetsFilter = assets;
  }
  return assetsFilter;
}
