/////////////////////
// Nav Menu (appMenu) UI
/////////////////////

// Basic Imports
import { useLocation } from "react-router-dom";

// Design Imports
import { Flex, HStack, Stack } from "@chakra-ui/react";

import { NavItem } from "./NavItem";
import { GrPlan, GrProjects } from "react-icons/gr";
import { ROUTES } from "settings/constants/routes";
import { GoRocket } from "react-icons/go";

// Layout and Section Imports

// Data Imports

// Interfaces
interface NavItemProps {
  label: string;
  href: string;
  icon: React.ReactNode | any;
}

interface MobileNavMenuProps {
  isOpen?: boolean;
  onClickNavItem?: (navItem: NavItemProps) => void;
}

// Functions

const NAV_ITEMS: NavItemProps[] = [
  { label: "Projects", href: ROUTES.PROJECTS_PAGE, icon: GrProjects },
  { label: "My tasks", href: ROUTES.TASK_PAGE, icon: GrPlan },
  { label: "New Project", href: ROUTES.PROJECTS_NEW, icon: GoRocket },
];

const MobileNavMenu = (props: MobileNavMenuProps) => {
  const { isOpen, onClickNavItem } = props;
  const { pathname } = useLocation();

  return (
    <Flex
      hidden={!isOpen}
      as="nav"
      direction="column"
      bg="blue.600"
      position="fixed"
      height="calc(100vh - 4rem)"
      top="16"
      insetX="0"
      zIndex={10}
      w="full"
    >
      <Stack p="4">
        {NAV_ITEMS.map((item: NavItemProps, idx: number) => {
          const isActive = item.href === pathname;
          return (
            <NavItem.Mobile
              key={idx.toString()}
              active={isActive}
              icon={item.icon}
              label={item.label}
              href={item.href}
              onClickNavItem={onClickNavItem}
            />
          );
        })}
      </Stack>
    </Flex>
  );
};

const DesktopNavMenu = () => {
  const { pathname } = useLocation();
  return (
    <HStack spacing="3" flex="1" display={{ base: "none", lg: "flex" }}>
      {NAV_ITEMS.map((item: NavItemProps, idx: number) => {
        const isActive = item.href === pathname;
        return (
          <NavItem.Desktop
            key={idx.toString()}
            active={isActive}
            icon={item.icon}
            label={item.label}
            href={item.href}
          />
        );
      })}
    </HStack>
  );
};

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
};
