/////////////////////
// Use Auth Form
/////////////////////

// Basic Imports
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

// Design Imports
import { useBreakpointValue } from "@chakra-ui/react";

import { changeEmail } from "app/userReducer";
import { useDispatch } from "react-redux";

// Layout and Section Imports

// Data Imports
import { fetchProjectsService } from "services/projectsService";
import { ROUTES } from "settings/constants/routes";

// Custom Hooks and Services
import useAuth from "hooks/useAuth";

import {
  forgotPassword,
  getUserAccountDetails,
  resetPassword,
  sendVerificationEmail,
  signin,
  signup,
  storeCustomerEmailIntoLocalStorage,
  storeCustomerTokenIntoLocalStorage,
  verification,
} from "services/authService";

import useCustomToast from "hooks/useCustomToast";
import { logoutUserServices } from "services/userProfileService";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";
import { WrapIntoTryCatch } from "utils/wrapIntoTryCatch";

// Interfaces
export type SignUpUserTypes = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  isLoading: boolean;
  error: string;
};
interface OptionTypes {
  hideToast?: boolean;
}

// Functions
const useAuthForm = () => {
  // @ts-ignore
  const { setAuth, user: userDetails, auth } = useAuth();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { errorToast, successToast } = useCustomToast();
  const navigate = useNavigate();
  const [hasUserSignedUp, setHasUserSignedUp] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isEmailSentForReset, setIsEmailSentForReset] = useState(false);

  // State management (component level)
  const [user, setUser] = useState<SignUpUserTypes>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isLoading: false,
    error: null,
  });

  /**
   * Utility handlers
   */
  const resetUser = () => {
    setUser({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
      error: null,
    });
    setHasUserSignedUp(false);
    setIsUserVerified(false);
    setIsEmailSentForReset(false);
  };
  const setEmail = (email: string) => setUser({ ...user, email });
  const dispatch = useDispatch();
  const setPassword = (password: string) => setUser({ ...user, password });
  const setLoading = (isLoading: true | false) =>
    setUser({ ...user, isLoading });
  const setFirstName = (firstName: string) => setUser({ ...user, firstName });
  const setLastName = (lastName: string) => setUser({ ...user, lastName });
  const setConfirmPassword = (confirmPassword: string) =>
    setUser({ ...user, confirmPassword });
  const setError = (error: string) => setUser({ ...user, error });
  const resetError = () => setUser({ ...user, error: "" });

  const callWaiter = async (fn: Function, options?: OptionTypes) => {
    setLoading(true);
    const { response, error } = await fn();
    setLoading(false);

    // 2.2 Error (show error message)
    if (!!error) {
      if (!options?.hideToast) {
        errorToast({ title: error.message });
      }

      return { error };
    }

    return { response };
  };
  const onBoardingUser = async ({ accessToken }) => {
    // Fetch user account details using logged in auth token
    const { data, error } = await WrapIntoTryCatch(() =>
      getUserAccountDetails(accessToken)
    );
    if (!!error) {
      // toast({ title: "Something went wrong...", status: "error" });
      errorToast({ title: ERRORS.GENERIC });
      return;
    }

    // Check if user data available otherwise show doesn't let it go ahead
    const userAccount = data?.data?.user || null;
    if (!userAccount) return errorToast({ title: ERRORS.ACCOUNT });
    if (data?.data?.user.role != "staff")
      return errorToast({ title: ERRORS.USER });

    successToast({ title: SUCCESS.LOGGED_IN });

    // After login user should be navigated to the projects, if there is no project then just hit wizard page
    await fetchProjectsService(accessToken, userAccount?._id);
    // const { data: projects, error: projectsError } = await fetchProjectsService(accessToken, userAccount?._id);
    // const projectsLength = (!projectsError && projects.data.projects.length) || 0;

    //Setting user email in redux store
    dispatch(changeEmail(userAccount.email));
    storeCustomerEmailIntoLocalStorage(userAccount.email);
    setEmail(userAccount.email);
    setAuth((prev) => ({ ...prev, accessToken }));
    storeCustomerTokenIntoLocalStorage(accessToken);
    resetUser();
    // @ts-ignore
    // fetchUser();

    //Not required for staff accounts
    // if (userAccount.subscription.plan === "none") {
    //   navigate(ROUTES.ACCOUNT_BILLING, { replace: true });
    //   return;
    // }

    //const link = projectsLength > 0 ? ROUTES.PROJECTS_ACTIVE : ROUTES.PROJECTS_NEW
    const link = ROUTES.PROJECTS_ACTIVE;
    navigate(link, { replace: true });
  };

  /***
   * Form handlers
   */
  const handleSignin = async (e: FormEvent) => {
    e.preventDefault();

    const { response, error } = await callWaiter(() =>
      signin({
        email: user.email,
        password: user.password,
      })
    );

    if (!!error) return;

    // It will remove all old values in localstorage
    logoutUserServices();
    onBoardingUser({ accessToken: response?.data?.token });
  };

  const handleSignup = async (e: FormEvent) => {
    e.preventDefault();
    // If password doesn't match with confirm password.
    if (user.password !== user.confirmPassword) {
      setError("Confirm password doesn't match!");
      return;
    }
    // If password matches with confirm password then let it go next
    if (user.password === user.confirmPassword) resetError();

    const { error } = await callWaiter(() =>
      signup({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
        confirmPassword: user.confirmPassword,
      })
    );

    if (!!error) return;

    setHasUserSignedUp(true);
  };

  const handleVerifyAccount = async (token: string) => {
    const { response, error } = await verification({ token });

    // Show error toast and alert
    if (!!error && !response) {
      return false;
    }

    return true;
  };

  const handleSendVerificationEmail = async () => {
    const { response, error } = await sendVerificationEmail({
      email: userDetails.email,
      authToken: auth.accessToken,
    });

    // Show error toast and alert
    if (!!error && !response) {
      return { status: error.statusCode };
    }

    return { status: 200 };
  };

  const handleResetPassword = async (token: string) => {
    if (user.password !== user.confirmPassword) {
      setError("Confirm password doesn't match!");
      return;
    }

    const { response, error } = await callWaiter(
      () =>
        resetPassword({
          token,
          newPassword: user.password,
          confirmPassword: user.confirmPassword,
        }),
      { hideToast: true }
    );

    // Show error toast and alert
    if (!!error && !response) {
      errorToast({ title: ERRORS.INVALID_TOKEN });

      return;
    }

    successToast({
      title: SUCCESS.PASSWORD_RESET,
      description: "Login using newly created password.",
    });
    navigate(ROUTES.LOGIN_IN, { replace: true });
  };

  const handleForgotPassword = async (e: FormEvent) => {
    e.preventDefault();

    const { error } = await callWaiter(() =>
      forgotPassword({
        email: user.email,
      })
    );

    if (!!error) {
      return;
    }

    setIsEmailSentForReset(true);
  };

  return {
    isMobile,
    hasUserSignedUp,
    setHasUserSignedUp,
    isEmailSentForReset,
    setIsEmailSentForReset,
    handleSignup,
    handleSignin,
    handleForgotPassword,
    handleVerifyAccount,
    handleSendVerificationEmail,
    handleResetPassword,
    isUserVerified,
    setFirstName,
    setLastName,
    setConfirmPassword,
    setEmail,
    setPassword,
    setError,
    resetError,
    user,
  };
};

export default useAuthForm;
