/////////////////////
// Security Page
/////////////////////

// Basic Imports
import React, { useEffect } from "react";
import { useState } from "react";

// Design Imports
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  StackDivider,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";
import { handleLogout } from "services/authService";

// Interfaces

// Functions
const BillingPage = () => {
  //@ts-ignore
  const { user, token } = useAuth();
  const axios = useAxiosPrivate();
  const { successToast, errorToast } = useCustomToast();
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailPassword, setEmailPassword] = useState("");
  const [passwordFields, setPasswordFields] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const colorMode = mode("sm", "sm-dark");

  useEffect(() => {
    if (!user) return;

    setEmail(user.email);
  }, [user]);

  // Used for email change password
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  // Used for password change current password
  const [showCurrent, setShowCurrent] = React.useState(false);
  const handleClickCurrent = () => setShowCurrent(!showCurrent);

  // Used for password change new password
  const [showNew, setShowNew] = React.useState(false);
  const handleClickNew = () => setShowNew(!showNew);

  const resetPasswordState = () => {
    setPasswordFields({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  const handlePasswordChange = (e) =>
    setPasswordFields((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleChangeEmail = async (e) => {
    e.preventDefault();
    if (email === "" || emailPassword === "") {
      return errorToast({ title: ERRORS.FIELDS_REQUIRED });
    }
    setIsEmailLoading(true);
    try {
      await axios.post(
        "/users/email/change",
        {
          password: emailPassword,
          currentEmail: user.email,
          newEmail: email,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      successToast({ title: SUCCESS.EMAIL_CHANGED });

      handleLogout();
    } catch (err) {
      console.log({ err });
      errorToast({ title: ERRORS.GENERIC });
    } finally {
      setIsEmailLoading(false);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setIsPasswordLoading(true);
    try {
      await axios.post("/users/password/change", passwordFields, {
        headers: { Authorization: `Bearer ${token}` },
      });

      successToast({ title: SUCCESS.PASSWORD_CHANGED });
      resetPasswordState();

      handleLogout();
    } catch (err) {
      errorToast({ title: err.response.data.message });
    } finally {
      setIsPasswordLoading(false);
    }
  };

  if (!user) return null;
  return (
    <Box as="section" p="6" border="1px solid" borderColor="#e2e8f0">
      <Box
        bg="bg-surface"
        boxShadow={colorMode}
        padding="10"
        borderRadius="lg"
        border="1px solid"
        borderColor="#e2e8f0"
        p={{ base: "4", md: "6" }}
      >
        <Stack
          spacing="5"
          divider={<StackDivider />}
          as="form"
          onSubmit={handleChangeEmail}
        >
          <Stack
            justify="space-between"
            direction={{ base: "column", sm: "row" }}
            spacing="5"
          >
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Change e-mail address?
              </Text>
            </Stack>
          </Stack>

          <Stack justify="space-between" direction="row" spacing="4">
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
              />
            </FormControl>
            <FormControl id="email_password">
              <FormLabel>Password</FormLabel>
              <InputGroup size="md">
                <Input
                  id="email_password"
                  name="password"
                  type={show ? "text" : "password"}
                  placeholder="Enter password for email change"
                  value={user.password}
                  onChange={(e) => setEmailPassword(e.currentTarget.value)}
                  pr="4.5rem"
                  required
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Stack>

          <Stack
            justify="space-between"
            direction={{ base: "column", sm: "row" }}
            spacing="5"
          >
            <Stack spacing="1">
              <Button
                type="submit"
                variant="primary"
                bg="blue.500"
                color="white"
                isLoading={isEmailLoading}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box
        bg="bg-surface"
        boxShadow={mode("sm", "sm-dark")}
        padding="10"
        borderRadius="lg"
        border="1px solid"
        borderColor="#e2e8f0"
        p={{ base: "4", md: "6" }}
        mt={6}
      >
        <Stack
          spacing="5"
          as="form"
          onSubmit={handleChangePassword}
          divider={<StackDivider />}
        >
          <Stack
            justify="space-between"
            direction={{ base: "column", sm: "row" }}
            spacing="5"
          >
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Change password?
              </Text>
            </Stack>
          </Stack>

          <Stack spacing="4">
            <FormControl id="currentPassword">
              <FormLabel>Current password</FormLabel>

              <InputGroup size="md">
                <Input
                  id="currentPassword"
                  name="password"
                  type={showCurrent ? "text" : "password"}
                  placeholder="Current password"
                  value={passwordFields.password}
                  onChange={handlePasswordChange}
                  pr="4.5rem"
                  required
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClickCurrent}>
                    {showCurrent ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="newPassword">
              <FormLabel>New password</FormLabel>
              <InputGroup size="md">
                <Input
                  id="newPassword"
                  name="newPassword"
                  type={showNew ? "text" : "password"}
                  placeholder="New password"
                  value={passwordFields.newPassword}
                  onChange={handlePasswordChange}
                  minLength={6}
                  maxLength={20}
                  pr="4.5rem"
                  required
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClickNew}>
                    {showNew ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="confirmPassword">
              <FormLabel>Confirm new password</FormLabel>
              <InputGroup size="md">
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showNew ? "text" : "password"}
                  placeholder="Confirm new password"
                  value={passwordFields.confirmPassword}
                  onChange={handlePasswordChange}
                  minLength={6}
                  maxLength={20}
                  pr="4.5rem"
                  required
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClickNew}>
                    {showNew ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Stack>

          <Stack
            justify="space-between"
            direction={{ base: "column", sm: "row" }}
            spacing="5"
          >
            <Stack spacing="1">
              <Button
                variant="primary"
                bg="blue.500"
                color="white"
                type="submit"
                isLoading={isPasswordLoading}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default BillingPage;
