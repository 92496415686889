/////////////////////
// Use Selected Assets Hook
/////////////////////

// Basic Imports


// Design Imports


// Layout and Section Imports


// Data Imports
import { 
  SET_NEW_SELECTED_ASSETS, 
  SET_SELECTED_ASSETS 
} from 'context/project/ProjectTypes';

// Custom Hooks and Services


// Interfaces


// Functions
const useSelectedAssets = (state, dispatch) => {
  
  const handleAddSelectedAssets = (selectedAssets) => {
    
    dispatch({ type: SET_SELECTED_ASSETS, payload: selectedAssets });

  }

  const handleAddSelectedAssetsToDb = () => {
    // TODO: handleAddSelectedAssetsToDb should do something
  }

  const handleUpdateNewSelectedAssets = (newSelectedAssets) => {
    dispatch({ type: SET_NEW_SELECTED_ASSETS, payload: newSelectedAssets })
  };
  
  return {
    selectedAssets: state.projectWizard.selectedAssets,
    handleAddSelectedAssets,
    handleAddSelectedAssetsToDb,
    handleUpdateNewSelectedAssets,
  }
}

export default useSelectedAssets