/////////////////////
// Site Credenital Settings
/////////////////////

// Handlers
const isPaymentLive = (isLive: true | false) => isLive;

// Credentials
export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_API_URL = (URL: string) => URL.replace("v1", ""); // This logic is just to remove "v1" from url
export const LIVE_PAYMENT = isPaymentLive(
  "TRUE" === process.env.REACT_APP_LIVE_STRIPE ? true : false
);
export const CUSTOMER_TOKEN_NAME = "hr_customer_token";
export const CUSTOMER_EMAIL = "hr_customer_email";
export const CUSTOMER_HC = "hr_customer_hc";
export const PROJECT_WIZARD_DATA = "project_wizard";
export const PERSIST_ROOT = "persist:root";
export const AUTHORIZED_TOKEN = JSON.parse(
  localStorage.getItem(CUSTOMER_TOKEN_NAME)
);
export const AUTHORIZED_EMAIL = JSON.parse(
  localStorage.getItem(CUSTOMER_EMAIL)
);
export const AUTHORIZED_HC = JSON.parse(localStorage.getItem(CUSTOMER_HC));
export const STARTER_PLAN_PRICE_ID_USD =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID_USD;
export const STARTER_PLAN_PRICE_ID =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID;
export const PROFESSIONAL_PLAN_PRICE_ID_USD =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID_USD;
export const PROFESSIONAL_PLAN_PRICE_ID =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID;
export const BUSINESS_PLAN_PRICE_ID_USD =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID_USD;
export const BUSINESS_PLAN_PRICE_ID =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID;
export const STARTER_PLAN_PRICE_ID_CAD =
  process.env.REACT_APP_STARTER_PLAN_PRICE_ID_CAD;
export const PROFESSIONAL_PLAN_PRICE_ID_CAD =
  process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID_CAD;
export const BUSINESS_PLAN_PRICE_ID_CAD =
  process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID_CAD;
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;

//Cloudflare and Security
export const CLOUDFLARE_SITEKEY = process.env.REACT_APP_CLOUDFLARE_SITE_KEY;
