import axios from "api/axios";
import { API_URL } from "settings/site-credentials";

export async function fetchAllNotifications({ authToken }) {
  try {
    const { data } = await axios.get(`${API_URL}/notifications`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return { data };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
}

export async function markAsReadNotification({ notificationId, authToken }) {
  try {
    const { data } = await axios.patch(
      `${API_URL}/notifications/markAsRead`,
      {
        notificationIds: [notificationId],
      },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
    return { data };
  } catch (error) {
    console.log({ error });
    return { error: "Something went wrong!" };
  }
}

export async function markNotificationsAsRead(ids: any[], token: string) {
  try {
    const { data } = await axios.patch(
      `${API_URL}/notifications/markAsRead`,
      {
        notificationIds: ids,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data };
  } catch (error) {
    console.log({ error });
    return { error: "Something went wrong!" };
  }
}
export async function deleteNotifications(ids: string[], token: string) {
  try {
    const { data } = await axios.delete(
      `${API_URL}/notifications?ids=${JSON.stringify(ids)}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data };
  } catch (error) {
    console.log({ error });
    return { error: "Something went wrong!" };
  }
}