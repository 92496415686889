/////////////////////
// Reset Password Page
/////////////////////

// Layout and Section Imports
import AuthLayout from "components/layouts/AuthLayout";
import { ResetForm } from "components/forms/AuthForm/ResetForm";

const ResetPasswordPage = () => (
  <AuthLayout>
    <ResetForm
      px={{ base: "4", md: "8" }}
      py={{ base: "12", md: "48" }}
      width="full"
      maxW="md"
    />
  </AuthLayout>
);

export default ResetPasswordPage;
