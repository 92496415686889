import { Button, useDisclosure } from "@chakra-ui/react";
import { Dropzone } from "hr-design-system";
import { useState } from "react";

const Practice = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isOpen && (
        <Dropzone
          onClose={onClose}
          isLoading={isLoading}
          onFileUploaded={(file) => {
            console.log({ file });

            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              // onClose();
            }, 3000);
          } } onUpload={function (files: File | File[]): void {
            throw new Error("Function not implemented.");
          } }        />
      )}
      <Button m="5" onClick={onOpen}>
        Upload Media ?
      </Button>
    </>
  );
};

export default Practice;
