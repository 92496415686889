import { OrderedList, UnorderedList, ListItem, Box } from "@chakra-ui/react";
import parse, {
  HTMLReactParserOptions,
  Element,
  domToReact,
} from "html-react-parser";
import { FC } from "react";

interface Props {
  html: string;
}

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.attribs) {
      if (domNode.name === "ul") {
        return (
          <UnorderedList marginLeft="20px">
            {domToReact(domNode.children, options)}
          </UnorderedList>
        );
      }

      // Ordered list
      if (domNode.name === "ol") {
        return (
          <OrderedList marginLeft="20px">
            {domToReact(domNode.children, options)}
          </OrderedList>
        );
      }

      // List item
      if (domNode.name === "li") {
        return (
          <ListItem paddingLeft="5px">
            {domToReact(domNode.children, options)}
          </ListItem>
        );
      }
    }
  },
};

const HtmlReactParser: FC<Props> = ({ html }) => {
  return <Box>{parse(html, options)}</Box>;
};

export default HtmlReactParser;
