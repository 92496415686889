/////////////////////
// Output Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { BoxProps, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";

// Layout and Section Imports
import moment from "moment";
import _ from "lodash";

// Data Imports

// Interfaces
interface Props {
  activity: any;
  styles: BoxProps;
}

// Functions

const Activity: FC<Props> = ({ activity, styles }) => {
  return (
    <VStack mt={5} alignItems="left" {...styles} maxW="full">
      {activity && activity.length > 0 &&
        activity.map((activity: any, i) => 
           <HStack key={i}>
                <Text fontSize="sm" as="b">
                  {_.capitalize(activity.edited.property)}
                </Text>
              <Text fontSize="sm">has been changed</Text>
              {activity.edited.property !== 'assignee' && 
              <>
                  <Text>from</Text>
                  <Text fontSize="sm" color={'red.400'}>{activity.edited.oldValue}</Text>
                  <Text fontSize="sm">to</Text>
                  <Text fontSize="sm" color={'blue.500'}>{activity.edited.newValue}</Text>
                </>
              }
                <Text fontSize="sm">at</Text>
                <Text fontWeight="bold" fontSize="xs" noOfLines={1} >
                  {moment(activity.updatedAt).format("MMM Do, YYYY, hh:mm:ss a")}
                </Text>
              </HStack>

        )}
    </VStack>
  );
};

export default Activity;
