/////////////////////

// Design Imports
import { Box, BoxProps } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
export const CardContent = (props: BoxProps) => <Box width="full" {...props} />;
