/////////////////////
// Use Project Wizard Hook
/////////////////////

// Basic Imports
import useAuth from "hooks/useAuth";

// Design Imports

// Layout and Section Imports

// Data Imports
import {
  //  SET_DRAFT_PROJECTS,
  SET_HAS_PROJECT_INITIALIZED,
  SET_PROJECT_ID,
  SET_PROJECT_STATUS,
  SET_PROJECT_WIZARD,
} from "context/project/ProjectTypes";

// Custom Hooks and Services
import {
  deleteProjectService,
  initializeProjectService,
  submitProjectService,
} from "services/projectsService";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";
import { ProjectStatusesProps } from "types/project";

// Interfaces

// Functions

// Schemas
const useProjectWizard = (INITIAL_STATE, state, dispatch) => {
  const { errorToast, successToast } = useCustomToast();
  const { auth, user }: any = useAuth();

  const resetProjectWizard = () => {
    dispatch({
      type: SET_PROJECT_WIZARD,
      payload: {
        ...INITIAL_STATE.projectWizard,
        supportingDocuments: [],
      },
    });
  };

  const updateProjectWizard = (projectWizard) => {
    dispatch({ type: SET_PROJECT_WIZARD, payload: projectWizard });
  };

  const initializeProject = async () => {
    resetProjectWizard();
    dispatch({ type: SET_HAS_PROJECT_INITIALIZED, payload: true });
  };

  // Remove project from frontend (localStorage and project wizard) but not from DB
  const closeProjectWizard = async () => {
    // Removing project from project wizard
    localStorage.removeItem("project_wizard");

    // Reset the state
    resetProjectWizard();
  };

  // Remove project draft from DB and frontEnd as well
  const cancelProjectWizard = async () => {
    const projectWizard = state.projectWizard;

    // If there is no id in project wizard, that means project is not has been uploaded to the DB yet. So just remove from the localStorage
    if (!projectWizard.id) {
      // Removing project draft from project wizard
      localStorage.removeItem("project_wizard");

      // Reset the state
      resetProjectWizard();

      // Show Toast on UI
      successToast({ title: SUCCESS.PROJECT_DELETED });

      return;
    }

    // Delete the project draft from the DB
    const { data, error } = await deleteProjectService(
      state.projectWizard.id,
      auth.accessToken
    );

    if (data?.success === true && !error) {
      // Removing project draft from project wizard
      localStorage.removeItem("project_wizard");

      // Reset the state
      resetProjectWizard();

      // Show Toast on UI
      successToast({ title: SUCCESS.PROJECT_DELETED });

      return { data };
    } else {
      errorToast({ title: ERRORS.GENERIC });

      return { error };
    }
  };

  // Setting id to the project wizard state
  const setProjectWizardId = (id) => {
    dispatch({ type: SET_PROJECT_ID, payload: id });
  };

  const setProjectStatus = (status: string) => {
    dispatch({ type: SET_PROJECT_STATUS, payload: status });
  };

  // Fetching project from local storage
  const loadProjectWizardFromLocalStorage = () => {
    const projectWizard = JSON.parse(localStorage.getItem("project_wizard"));

    if (!projectWizard) return;

    dispatch({ type: SET_PROJECT_WIZARD, payload: projectWizard });
  };

  // Adding project into local storage
  function addProjectIntoLocalStorage(data: any) {
    localStorage.setItem("project_wizard", JSON.stringify(data));
  }

  interface SubmitProjectWizardProps {
    projectStatus: ProjectStatusesProps;
    shouldTrackActivity: boolean;
  }
  const submitProjectWizard = async (props?: SubmitProjectWizardProps) => {
    const projectStatus = props?.projectStatus || null;
    const shouldTrackActivity = props?.shouldTrackActivity || false;

    const projectWizard = {
      ...state.projectWizard,
      ...(!!projectStatus ? { projectStatus } : {}), // Conditional field
    };

    // 1. Update database
    const { data, error } = await submitProjectService(
      projectWizard,
      auth.accessToken,
      user,
      shouldTrackActivity
    );

    // 4. Success or error on UI
    if (data?.success === true && !error) {
      const id = data?.data?.project?._id || "";

      // 4.1. Update projectId of project wizard state (for updating project later)
      setProjectWizardId(id);

      // 4.2. First add project wizard into local storage with id (to avoid duplication when user will click on draft project)
      addProjectIntoLocalStorage({ ...projectWizard, id });

      // 4.3. Show Toast on UI
      successToast({ title: SUCCESS.PROJECT_SUBMITTED });

      // 4.4. Reset project wizard
      closeProjectWizard(); // It removed because it was creating problem (it was removing wizard immediately before redirect so it is being used close to redirect in other component)
    } else {
      // Add project wizard into local host without id
      addProjectIntoLocalStorage(projectWizard);

      errorToast({ title: ERRORS.GENERIC });
    }

    return { data, error };
  };

  type SaveUpdatedProjectWizardProps = { shouldTrackActivity: boolean };
  const saveUpdatedProjectWizard = async (
    params: SaveUpdatedProjectWizardProps
  ) => {
    const shouldTrackActivity = params?.shouldTrackActivity || false;
    const projectWizard = {
      ...state.projectWizard,
      completionStage: state.completionStage,
    };

    // 1. Update database
    const { data, error, statusCode } = await initializeProjectService(
      projectWizard,
      auth.accessToken,
      state.projectWizard.projectStatus,
      shouldTrackActivity
    );
    // 4. Success or error on UI
    if (data?.success === true && !error) {
      const id = data?.data?.project?._id || "";
      if (!id) {
        errorToast({ title: ERRORS.GENERIC });
        return { error: ERRORS.GENERIC };
      }

      // 4.1. Update projectId of project wizard state (for updating project later)
      setProjectWizardId(id);

      // 4.2. Show Toast on UI
      successToast({ title: SUCCESS.PROJECT_UPDATED });

      return { data, statusCode };
    } else {
      errorToast({ title: ERRORS.SCHEMA_ERROR });
    }

    return { error };
  };

  // It will save the current state of project wizard as a draft project
  const saveProjectWizardAsDraft = async (
    params: SaveUpdatedProjectWizardProps
  ) => {
    const shouldTrackActivity = params?.shouldTrackActivity || false;

    const projectWizard = {
      ...state.projectWizard,
      completionStage: state.completionStage,
    };

    // 1. Update database
    // const { data, error, statusCode } = await initializeProjectService(projectWizard, auth.accessToken);
    const { data, error, statusCode } = await submitProjectService(
      projectWizard,
      auth.accessToken,
      user,
      shouldTrackActivity
    );

    // 4. Success or error on UI
    if (data?.success === true && !error) {
      const id = data?.data?.project?._id || "";
      if (!id) {
        errorToast({ title: ERRORS.GENERIC });
        return { error: ERRORS.GENERIC };
      }

      // 4.1. Update projectId of project wizard state (for updating project later)
      setProjectWizardId(id);

      // 4.2. First add project wizard into local host with id (to avoid duplication when user will click on draft project)
      // addProjectIntoLocalStorage({ ...projectWizard, id });

      // 4.3. Show Toast on UI
      successToast({ title: SUCCESS.SAVED_AS_DRAFT });

      return { data, statusCode };
    } else {
      errorToast({ title: ERRORS.SCHEMA_ERROR });
    }

    /**
     * TODO: Remove next 2 lines if you don't see any big issues.
     * WHY DELETE: Because we should only add project into localstorage if it successfully updates in backend. For example if schema is not correct and it will still store into localstorage. So it should be only stored on successful response after updating the DB
     */
    // Add project wizard into local host without id
    // addProjectIntoLocalStorage(projectWizard);

    return { error };
  };

  return {
    projectWizardId: state?.projectWizard?.id || "",
    projectWizard: state.projectWizard,
    updateProjectWizard,
    resetProjectWizard,
    submitProjectWizard,
    initializeProject,
    cancelProjectWizard,
    closeProjectWizard,
    setProjectWizardId,
    setProjectStatus,
    saveProjectWizardAsDraft,
    saveUpdatedProjectWizard,
    loadProjectWizardFromLocalStorage,
    addProjectIntoLocalStorage,
  };
};

export default useProjectWizard;
