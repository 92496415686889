/////////////////////
// Output Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { SimpleGrid, Text } from "@chakra-ui/react";

// Layout and Section Imports
import { OutputCardForSteps } from "components/ui/common/OutputCardForSteps";
import { AssetProps } from "data/projectWizardData";
import { useProject } from "context/project/ProjectProvider";

// Data Imports

// Interfaces
interface Props {
  assets?: any[];
  prevStep?: () => any;
  nextStep?: () => any;
  onSelected?: (selectedItems: any) => void;
  onStepClick?: () => void;
}

// Functions

const OutputStep: FC<Props> = ({ prevStep, nextStep, onStepClick }) => {
  const { selectedAssets, selectedOutputs } = useProject();
  if (selectedAssets.length === 0 || selectedOutputs.length === 0)
    return <Text>Oops! Nothing to show...</Text>;
  return (
    <SimpleGrid maxW="7xl" columns={{ base: 1, md: 2 }} spacing={5} pb={5}>
      {selectedAssets.map((currentValue: AssetProps) => {
        const { title, id, actionData } = currentValue;

        return (
          <OutputCardForSteps
            readOnly={false}
            key={currentValue.id}
            id={id}
            title={title}
            data={actionData}
            selectedData={selectedOutputs.find(
              (selected) => selected.title === title
            )}
          />
        );
      })}
    </SimpleGrid>
  );
};

export default OutputStep;