/////////////////////
// Plan Page
/////////////////////

// Basic Imports
import useAuth from "hooks/useAuth";
// import { loadStripe } from "@stripe/stripe-js";

// Design Imports
import {
  Box,
  Flex,
  SimpleGrid,
  //  useColorModeValue as mode,
} from "@chakra-ui/react";

import { PricingCard } from "./PricingCards";

// Layout and Section Imports

// Data Imports
import { RootStateOrAny, useSelector } from "react-redux";

// Interfaces

// Functions

// let stripePromise;

// const getStripe = () => {
//   if (!stripePromise) {
//     stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
//   }

//   return stripePromise;
// };

const PlanPage = () => {
  // @ts-ignore
  const { user } = useAuth();
  const planDuration = useSelector(
    (state: RootStateOrAny) => state.plan.planDuration
  );

  // const redirectToCheckout = async (plan) => {
  //   const checkoutOptions = {
  //     lineItems: [
  //       {
  //         price: plan,
  //         quantity: 1,
  //       },
  //     ],
  //     mode: "subscription",
  //     customerEmail: user?.email,
  //     successUrl: `${window.location.origin}`,
  //     // TODO: Create a cancel page
  //     cancelUrl: `${window.location.origin}/cancel`,
  //   };

  //   const stripe = await getStripe();
  //   const { error } = await stripe.redirectToCheckout(checkoutOptions);
  //   console.log(error, "checkout error");
  // };

  return (
    <Box as="section" py="0">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "2", md: "2" }}
      >
        {/* <Box textAlign={{ base: "center", lg: "start" }}>
          <Heading
            size="sm"
            lineHeight="normal"
            color={mode("blue.600", "blue.400")}
            letterSpacing="tight"
            fontWeight="extrabold"
          >
            Select your Plan to get started
          </Heading>
        </Box> */}
        <Flex
          align="center"
          justify={{ base: "center", lg: "flex-start" }}
          my="10"
        >
          {/* <DurationSwitch defaultValue="yearly" /> */}
        </Flex>
        <SimpleGrid mt="10" columns={{ base: 1, lg: 3 }} spacing="10">
          <PricingCard
            bg="gray.700"
            color="white"
            // button={
            //   <Button
            //     w="full"

            //     size="lg"
            //     h="12"
            //     color="revert"
            //     // colorScheme="blue"
            //     onClick={() =>
            //       redirectToCheckout(
            //         process.env.REACT_APP_STARTER_PLAN_PRICE_ID
            //       )
            //     }
            //   >
            //     Select Plan
            //   </Button>
            // }
            data={{
              name: "Starter",
              // TODO: Change description
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
              price: 499,
              duration: planDuration,
              benefits: [
                "2D Floor Plans",
                "Blueprint Edits",
                "Drafting Services",
                "Landscaping Plans",
                "Brochures",
                "Feature Sheets",
              ],
            }}
          />
          <PricingCard
            bg="blue.600"
            color="white"
            // button={
            //   <Button
            //     w="full"
            //     h="12"
            //     color="revert"
            //     size="lg"
            //     onClick={() =>
            //       redirectToCheckout(
            //         process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID
            //       )
            //     }
            //   >
            //     Select plan
            //   </Button>
            // }
            data={{
              name: "Professional",
              // TODO: Change description
              description:
                "At erat pellentesque adipiscing commodo elit at imperdiet.",
              price: 999,
              duration: planDuration,
              benefits: [
                "Everything from Starter Plan",
                "3D Floor Plans",
                "3D Interior Renderings",
                "3D Exterior Renderings",
                "3D Modelling",
                "Virtual Staging",
              ],
            }}
          />
          <PricingCard
            bg="gray.700"
            color="white"
            // button={
            //   <Button
            //     w="full"
            //     color="revert"
            //     h="12"
            //     size="lg"
            //     onClick={() =>
            //       redirectToCheckout(
            //         process.env.REACT_APP_BUSINESS_PLAN_PRICE_ID
            //       )
            //     }
            //   >
            //     Select plan
            //   </Button>
            // }
            data={{
              name: "Business",
              // TODO: Change description
              description:
                "Proin sagittis nisl rhoncus mattis rhoncus urna neque.",
              price: 1999,
              duration: planDuration,
              benefits: [
                "Everything from Pro Plan",
                "Multiple Designers",
                "Separate Work Queue",
                "Prioritized Support",
              ],
            }}
          />
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default PlanPage;
