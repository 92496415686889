/////////////////////
// Signup Form
/////////////////////

// Basic Imports

// Design Imports
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackProps,
} from "@chakra-ui/react";

import { GoogleIcon } from "../../../ui/common/ProviderIcons";

import Alert from "components/features/Alert";

// Layout and Section Imports
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";
import FormAlert from "../FormAlert";

// Data Imports
import { ROUTES } from "settings/constants/routes";

// Custom Hooks and Services
import useAuthForm from "../useAuthForm";

// Interfaces

// Functions
export const SignupForm = (props: StackProps) => {
  const {
    hasUserSignedUp,
    handleSignup,
    user,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    setConfirmPassword,
    setError,
  } = useAuthForm();

  if (!!hasUserSignedUp)
    return (
      <FormAlert
        title="Verify your account"
        description="Check your email, after clicking on link you will be redirected to verification page."
      />
    );
  return (
    <Stack spacing="6" {...props}>
      <FormHeader
        title="Create an account"
        description="Already have an account?"
        link={{
          label: "Sign in",
          to: ROUTES.LOGIN_IN,
        }}
      />
      {!!user.error && (
        <Alert status="error" onClose={() => setError("")} title={user.error} />
      )}
      <FormWrapper onSubmit={handleSignup}>
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="email">First name</FormLabel>
            <Input
              required
              id="firstName"
              placeholder="Enter your first name"
              type="text"
              name="firstName"
              value={user?.firstName || ""}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Last name</FormLabel>
            <Input
              required
              id="lastName"
              placeholder="Enter your last name"
              type="text"
              name="lastName"
              value={user?.lastName || ""}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              required
              id="email"
              placeholder="Enter your email"
              type="email"
              name="email"
              value={user.email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              required
              id="password"
              placeholder="********"
              type="password"
              name="password"
              value={user.password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Confirm password</FormLabel>
            <Input
              required
              id="confirmPassword"
              placeholder="********"
              type="password"
              name="confirmPassword"
              value={user.confirmPassword}
              onChange={(e) => setConfirmPassword(e.currentTarget.value)}
            />
          </FormControl>
        </Stack>
        <Stack spacing="4">
          <Button
            variant="primary"
            color="white"
            bg="blue.500"
            _hover={{ backgroundColor: "blue.600" }}
            type="submit"
            isLoading={user.isLoading}
          >
            Sign up
          </Button>
          <Button
            variant="secondary"
            border="1px"
            borderColor="gray.200"
            leftIcon={<GoogleIcon boxSize="5" />}
            iconSpacing="3"
            onClick={() => {
              window.open("https://dev.homerender.io/v1/auth/google", "_self");
            }}
          >
            Sign up with Google
          </Button>
        </Stack>
      </FormWrapper>
    </Stack>
  );
};
