/////////////////////
// Profile/Form Page
/////////////////////

// Basic Imports
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postcodeValidator } from "postcode-validator";

// Design Imports
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Layout and Section Imports

// Hooks
import useProfilePage, { FormDataTypes } from "./useProfilePage";
import { Dropzone } from "hr-design-system";
import { createAttachmentService } from "services/attachmentsService";
import useCustomToast from "hooks/useCustomToast";
import useAuth from "hooks/useAuth";

// Validation Schema
const schema = yup.object().shape({
  firstName: yup.string().required().label("First name"),
  lastName: yup.string().required().label("Last name"),
  company: yup.string().label("Company"),
  addressLine1: yup.string().label("Address Line 1"),
  addressLine2: yup.string().label("Address Line 2"),
  country: yup.string().required().label("Country"),
  city: yup.string().label("City"),
  province: yup.string().label("Province or State"),
  avatar: yup.string(),
  postalCode: yup
    .string()
    .label("Postal Code or ZIP Code")
    .when(["country"], {
      // Conditional validation: It will run validation if country is available otherwise not
      is: (country: string) => !!country,
      then: yup.string().test({
        name: "postalCodeValidation",
        exclusive: false,
        message: "Postal Code or Zip Code must be valid.",
        test: function (value) {
          if (value === "") return true; // No need validation because zipCode is not required

          // You can access the country field with `this.parent` e.g., `this.parent.country`.
          const country = this.parent.country;
          let isValid = false;
          try {
            isValid = postcodeValidator(value.trim(), country);
            return isValid;
          } catch (error) {
            return false;
          }
        },
      }),
    }),
});

interface Props {
  styleProps?: BoxProps;
  defaultValues: Omit<FormDataTypes, "avatar"> & { avatar: string };
}
interface ProfilePreview {
  file: File | null;
  url: string;
}
const Form: FC<Props> = ({ styleProps, defaultValues }) => {
  const boxShadow = useColorModeValue("sm", "sm-dark");
  const { prevFormState, handleFormSubmit, formSubmitLoading } =
    useProfilePage();
  const { token }: any = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<FormDataTypes>({ defaultValues, resolver: yupResolver(schema) });
  const [updateEmailData, setUpdateEmailData] = useState({
    currEmail: "",
    newEmail: "",
    password: "",
  });
  const [profilePreview, setProfilePreview] = useState<ProfilePreview>({
    file: null,
    url: "",
  });
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const { errorToast } = useCustomToast();

  useEffect(() => {
    setValue("firstName", defaultValues.firstName, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("lastName", defaultValues.lastName, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("company", defaultValues.company, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("addressLine1", defaultValues.addressLine1, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("addressLine2", defaultValues.addressLine2, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("country", defaultValues.country || "US", {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("city", defaultValues.city, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("province", defaultValues.province, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("postalCode", defaultValues.postalCode, {
      shouldDirty: true,
      shouldValidate: true,
    });

    setUpdateEmailData({ ...updateEmailData, currEmail: defaultValues.email });

    setProfilePreview({
      file: null,
      url: defaultValues.avatar,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Box
      as="form"
      bg="bg-surface"
      boxShadow={boxShadow}
      border="1px solid"
      borderColor="#e2e8f0"
      {...styleProps}
      onSubmit={handleSubmit(async (formData) => {
        let profileImageUrl = defaultValues.avatar;
        if (profilePreview.file) {
          setImageLoading(true);

          const attachmentResponse = await createAttachmentService({
            mediaResponse: profilePreview.file,
            mediaDescription: "",
            projectId: "",
            userToken: token,
          });

          setImageLoading(false);

          if (attachmentResponse.error) {
            errorToast({ title: "Something while uploading profile image" });
            return;
          }

          profileImageUrl = attachmentResponse.data.data.attachment.cdnUrl;
        }

        // Set the file to the avatar field in the form.
        setValue("avatar", profileImageUrl, {
          shouldDirty: true,
          shouldValidate: true,
        });

        handleFormSubmit({ ...formData, avatar: profileImageUrl });
      })}
    >
      <Stack
        spacing="5"
        px={{ base: "4", md: "6" }}
        py={{ base: "5", md: "6" }}
      >
        <FormControl id="picture">
          <FormLabel>
            Picture{" "}
            <Text as="span" color="dimgrey" fontSize="sm" fontStyle="italic">
              (You have to press "save" button to reflect changes in backend)
            </Text>
          </FormLabel>
          <Stack
            spacing={{ base: "3", md: "5" }}
            direction={{ base: "column", sm: "row" }}
          >
            <Avatar size="xl" src={profilePreview.url} />
            <Box w="full">
              <Dropzone
                showPreviews={false}
                wrapInModal={false}
                allowMultiple={false}
                onUpload={(file) => {
                  const profileImage = Array.isArray(file) ? file[0] : file;

                  const url = URL.createObjectURL(profileImage);
                  setProfilePreview({ file: profileImage, url });
                }}
              />
            </Box>
          </Stack>
        </FormControl>
        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
          <FormControl id="firstName">
            <FormLabel>First Name</FormLabel>
            <Input {...register("firstName")} />
            {errors?.firstName && (
              <Text color="red" mt="1">
                {errors.firstName.message}
              </Text>
            )}
          </FormControl>
          <FormControl id="lastName">
            <FormLabel>Last Name</FormLabel>
            <Input {...register("lastName")} />
            {errors?.lastName && (
              <Text color="red" mt="1">
                {errors.lastName.message}
              </Text>
            )}
          </FormControl>
        </Stack>
        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
          <FormControl id="company">
            <FormLabel>Company</FormLabel>
            <Input {...register("company")} />
            {errors?.company && (
              <Text color="red" mt="1">
                {errors.company.message}
              </Text>
            )}
          </FormControl>
          <FormControl id="addressLine1">
            <FormLabel>Street address 1</FormLabel>
            <Input {...register("addressLine1")} />
            {errors?.addressLine1 && (
              <Text color="red" mt="1">
                {errors.addressLine1.message}
              </Text>
            )}
          </FormControl>
          <FormControl id="addressLine2">
            <FormLabel>Street address 2</FormLabel>
            <Input {...register("addressLine2")} />
            {errors?.addressLine2 && (
              <Text color="red" mt="1">
                {errors.addressLine2.message}
              </Text>
            )}
          </FormControl>
        </Stack>

        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
          <FormControl id="Country">
            <FormLabel>Country</FormLabel>
            <Select
              bg={useColorModeValue("white", "gray.700")}
              value={getValues().country}
              onChange={(e) =>
                setValue("country", e.currentTarget.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
            >
              <option value="CA">Canada</option>
              <option value="US">USA</option>
            </Select>
            {errors?.country && (
              <Text color="red" mt="1">
                {errors.country.message}
              </Text>
            )}
          </FormControl>
          <FormControl id="city">
            <FormLabel>City</FormLabel>
            <Input {...register("city")} />
            {errors?.city && (
              <Text color="red" mt="1">
                {errors.city.message}
              </Text>
            )}
          </FormControl>
        </Stack>
        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
          <FormControl id="state">
            <FormLabel>State / Province</FormLabel>
            <Input {...register("province")} />
            {errors?.province && (
              <Text color="red" mt="1">
                {errors.province.message}
              </Text>
            )}
          </FormControl>
          <FormControl id="zip">
            <FormLabel>ZIP / Postal Code</FormLabel>
            <Input {...register("postalCode")} />
            {errors?.postalCode && (
              <Text color="red" mt="1">
                {errors.postalCode.message}
              </Text>
            )}
          </FormControl>
        </Stack>
      </Stack>
      <Divider />
      <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
        <Button
          type="submit"
          variant="primary"
          bg="blue.500"
          color="white"
          isLoading={imageLoading || formSubmitLoading}
          isDisabled={prevFormState ? false : true}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default Form;
