import {
  CloseButton,
  Divider,
  HStack,
  Icon,
  Image,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useColorModeValue as mode } from "@chakra-ui/system";
import { AiFillFileImage, AiFillFilePdf } from "react-icons/ai";
import { BsFillFileEarmarkArrowDownFill } from "react-icons/bs";

interface WizardPreviewFile {
  name: string;
  size?: number;
  type: string;
  url: string;
}
interface Props {
  file: WizardPreviewFile;
  description?: "";
  onDelete?: () => Promise<void>;
  showDeleteButton?: boolean;
}
const WizardAttachmentPreview = ({
  file,
  description,
  onDelete,
  showDeleteButton = true,
}: Props) => {
  return (
    <Stack
      px="2"
      rounded="md"
      bg={description ? mode("gray.200", "gray.800") : null}
      spacing="0"
      gap={1}
    >
      <HStack pt="2">
        <HStack
          w="90%"
          onClick={() => window.open(file.url, "_blank")}
          cursor="pointer"
          userSelect="none"
        >
          <RenderPreview url={file.url} format={file.type} />
          <Stack spacing={0}>
            <Text>{file.name}</Text>
            {file.size && (
              <Text fontSize="xs" fontStyle="italic" color="GrayText">
                Size: {Math.trunc(file.size / 1000)} kb
              </Text>
            )}
          </Stack>
          <Spacer />
        </HStack>
        {!!showDeleteButton && (
          <HStack w="10%" justify="flex-end">
            <CloseButton onClick={onDelete} />
          </HStack>
        )}
      </HStack>
      {description && (
        <>
          <Divider borderColor="gray.400" />
          <Text px="2" py="2" noOfLines={4}>
            {description}
          </Text>
        </>
      )}
    </Stack>
  );
};

function RenderPreview({ format, url }) {
  if (format === "dwg")
    return (
      <Icon
        fontSize="1.5rem"
        as={BsFillFileEarmarkArrowDownFill}
        onClick={() => window.open(url, "_blank")}
        cursor={"pointer"}
        userSelect="none"
      />
    );
  if (format === "pdf")
    return (
      <Icon
        fontSize="1.5rem"
        as={AiFillFilePdf}
        onClick={() => window.open(url, "_blank")}
        cursor={"pointer"}
        userSelect="none"
      />
    );

  if (format === "heic")
    return (
      <Icon
        fontSize="1.5rem"
        as={AiFillFileImage}
        onClick={() => window.open(url, "_blank")}
        cursor={"pointer"}
        userSelect="none"
      />
    );
  return (
    <Image
      w="10"
      minH="1rem"
      border="1px"
      borderColor="gray.400"
      rounded="sm"
      objectFit="cover"
      src={url}
    />
  );
}

export default WizardAttachmentPreview;
