import { ScaleFade, Stack } from "@chakra-ui/react";
import Alert from "components/features/Alert";
import { FC } from "react";

interface Props {
  title: string;
  description: string;
}
const FormAlert: FC<Props> = ({ title, description }) => {
  return (
    <Stack pr="3">
      <ScaleFade initialScale={0.9} in={true}>
        <Alert
          w="full"
          title={title}
          description={description}
          status="success"
          showCloseIcon={false}
        />
      </ScaleFade>
    </Stack>
  );
};

export default FormAlert;
