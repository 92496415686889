import { FC, ReactNode } from "react";
import { Step } from "components/ui/steps/Step";
import { StepContent } from "components/ui/steps/StepContent";
import { Button, HStack, Stack } from "@chakra-ui/react";
import ButtonTooltip from "../common/Tooltips/ButtonTooltip";

interface StepProps {
  title: string;
  prevStep?: () => any;
  nextStep?: () => any;
  onSubmit?: () => void;
  submitLoading?: boolean;
  onStepClick?: () => void;
  titleTooltip?: string;
  children: ReactNode;
  stepLoading?: boolean;
  nextStepLoading?: boolean;
  disableNextButton?: boolean;
  disableNextButtonReason?: string;
}
const WrapStep: FC<StepProps> = ({
  title,
  onStepClick,
  children,
  prevStep,
  nextStep,
  onSubmit,
  submitLoading,
  stepLoading,
  titleTooltip,
  nextStepLoading = false,
  disableNextButton = false,
  disableNextButtonReason,
}) => {
  return (
    <Step
      tooltip={titleTooltip}
      title={title}
      isLoading={stepLoading}
      onClickStepHeader={onStepClick}
    >
      <StepContent>
        <Stack shouldWrapChildren spacing="4">
          {children}
          <HStack>
            {!!prevStep && (
              <Button onClick={prevStep} variant="ghost">
                Back
              </Button>
            )}
            {!!nextStep &&
              (disableNextButton && disableNextButtonReason ? (
                <ButtonTooltip label={disableNextButtonReason}>
                  <RenderNextStepButton
                    isDisabled={disableNextButton}
                    isLoading={nextStepLoading}
                    onClick={nextStep}
                  />
                </ButtonTooltip>
              ) : (
                <RenderNextStepButton
                  isDisabled={disableNextButton}
                  isLoading={nextStepLoading}
                  onClick={nextStep}
                />
              ))}
            {!!onSubmit && (
              <Button isLoading={submitLoading} onClick={onSubmit}>
                Submit
              </Button>
            )}
          </HStack>
        </Stack>
      </StepContent>
    </Step>
  );
};

interface RenderNextStepButtonProps {
  isDisabled: boolean;
  isLoading: boolean;
  onClick: () => any;
}
function RenderNextStepButton({
  isDisabled = false,
  isLoading = false,
  onClick,
}: RenderNextStepButtonProps) {
  return (
    <Button isDisabled={isDisabled} isLoading={isLoading} onClick={onClick}>
      Next
    </Button>
  );
}

export default WrapStep;
