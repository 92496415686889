export default function getFileFormat({
  fileType,
}: {
  fileType: File["type"];
}) {
  if (!fileType) return "";

  const totalBricks = fileType.split("/");
  const format = totalBricks[totalBricks.length - 1];

  return format;
}
