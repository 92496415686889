import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { createComment, fetchAllCommentsByProjectId } from "services/comments";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";
import useAuth from "./useAuth";
import useCustomToast from "./useCustomToast";

type Comment = {
  text: string;
  html: string;
};

const useFetchComments = () => {
  const [{ comments, uploadLoading }, setState] = useState({
    comments: [],
    uploadLoading: false,
  });
  const [comment, setComment] = useState<Comment>({ html: "", text: "" });
  const { id: projectId } = useParams();
  const { auth }: any = useAuth();
  const { data, isLoading: fetchCommentsLoading } = useQuery(
    [projectId, "comments"],
    async () => await fetchAllCommentsByProjectId(projectId, auth.accessToken),
    { enabled: !!projectId, refetchInterval: 20 * 1000 }
  );
  const { successToast, errorToast } = useCustomToast();

  const generateParentWithChild = (arr) => {
    const parents = arr.filter((val) => !val.parentId);
    const parentsWithChild = parents.map((parent) => {
      return {
        ...parent,
        //p.parentId and parent.id if both were undefined they will also get in children added check
        children: arr.filter(
          (p) => p.parentId && parent.id && p.parentId === parent.id
        ),
      };
    });
    return parentsWithChild;
  };

  /*
    UseEffects
  */
  useEffect(() => {
    const comments = data?.data?.data?.comments || [];
    const commentsWithParentAndChild = generateParentWithChild(comments);
    if (comments.length > 0)
      setState((state) => ({ ...state, comments: commentsWithParentAndChild }));
  }, [data]);

  /*
    Handlers
  */
  const setComments = (comments: any[]) =>
    setState((state) => ({ ...state, comments }));
  const setUploadLoading = (loading: boolean) =>
    setState((state) => ({ ...state, uploadLoading: loading }));

  const handleUploadComment = async ({ comment: string, attachments }) => {
    setUploadLoading(true);

    try {
      const { data, error } = await createComment(
        {
          text: comment.text,
          html: comment.html,
          projectId: projectId,
          attachments,
        },
        auth.accessToken
      );

      if (!!error) throw new Error("Something wrong while creating comment");
      const commentObj = data.comment;
      setComments([...comments, commentObj]);

      successToast({ title: SUCCESS.COMMENT_UPLOADED });

      return { commentObj };
    } catch (error) {
      errorToast({ title: ERRORS.GENERIC });
      return { error: "Something went wrong..." };
    } finally {
      setUploadLoading(false);
    }
  };

  return {
    comments,
    onUploadComment: handleUploadComment,
    setComments,
    fetchCommentsLoading,
    uploadLoading,
    setComment,
    comment,
  };
};

export default useFetchComments;
