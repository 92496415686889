/////////////////////
// Notification (appPages) UI
/////////////////////

// Basic Imports
import React, { useEffect } from "react";

// Design Imports
import { Stack, BoxProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";

// Layout and Section Imports

// Data Imports
import { useState } from "react";
import { getCommentById } from "services/comments";
import useAuth from "hooks/useAuth";
import { ROUTES } from "settings/constants/routes";

interface RenderLinkProps extends BoxProps {
  children: any;
  data: any;
}

export const RenderLink = ({ children, data }: RenderLinkProps) => {
  const { token }: any = useAuth();
  const [link, setLink] = useState("");
  useEffect(() => {
    async function fetchLink() {
      if (data.resourceType === "project") {
        setLink(`${ROUTES.PROJECTS_PAGE}/${data.resourceId}`);
      } else if (data.resourceType === "comment") {
        setLink(`${ROUTES.PROJECTS_PAGE}/${data.projectId}?isComment=true`);
      } else {
        setLink(`${ROUTES.PROJECTS_PAGE}/${data.projectId}`);
      }
    }
    fetchLink();
  }, []);
  return (
    <Stack as={Link} to={link} p="2">
      {children}
    </Stack>
  );
};
