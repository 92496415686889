/////////////////////
// useMobileMenuState (appMenu) Page
/////////////////////

// Basic Imports
import * as React from "react";
import { useBoolean, useBreakpointValue } from "@chakra-ui/react";

// Design Imports

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
export const useMobileMenuState = () => {
  const [isMenuOpen, actions] = useBoolean();
  /**
   * Scenario: Menu is open on mobile, and user resizes to desktop/tablet viewport.
   * Result: We'll close the menu
   */
  const isMobileBreakpoint = useBreakpointValue({ base: true, lg: false });

  React.useEffect(() => {
    if (isMobileBreakpoint === false) {
      actions.off();
    }
  }, [isMobileBreakpoint, actions]);

  return {
    isMenuOpen,
    onCloseModal: actions.off,
    ...actions,
  };
};
