/////////////////////
// Checkbox UI
/////////////////////

// Basic Imports
import { FC } from 'react'

// Design Imports
import {
  Box,
  Text,
  useCheckbox,
  UseCheckboxProps,
  useColorModeValue as mode,
  useId,
} from '@chakra-ui/react'

// Layout and Section Imports


// Data Imports


// Custom Hooks and Services


// Interfaces
interface ButtonCheckboxProps extends UseCheckboxProps {
  // value: string
  size?: "xs" | "sm" | "md" | "lg";
  children: JSX.Element;
  readOnly?: boolean
}

// Functions
export const ButtonCheckbox:FC<ButtonCheckboxProps> = (props) => {
  const { children, readOnly, ...rest } = props
  const { getInputProps, getLabelProps, state } = useCheckbox({ ...rest })
  const id = useId()

  function getSize(size) {
    if (size === "xs") return { px: "2", py: "1", fontSize: "12" };
    if (size === "sm") return { px: "2", py: "2", fontSize: "12" };
    if (size === "md") return { px: "3", py: "2", fontSize: "14" };
    return { px: "2", py: "1", fontSize: "12" };
  }

  const getBgColor = (isDisabled: boolean, isChecked: boolean) => {
    if (!!isDisabled) {
      return isChecked ? mode('blue.100', 'blue.100') : mode('gray.100', 'gray.600')
    }
    return isChecked ? mode('blue.400', 'blue.300') : mode('gray.100', 'gray.600')
  }
  const getTextColor = (isDisabled: boolean, isChecked: boolean) => {
    if (!!isDisabled) {
      return isChecked ? mode("white", "whiteAlpha.900") : mode("blackAlpha.500", "whiteAlpha.900")
    } 
    return isChecked ? mode("white", "whiteAlpha.900") : mode("blackAlpha.900", "whiteAlpha.900")
  }

  return (
    <label {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <Box 
        bg={getBgColor(readOnly, state.isChecked)}
        w="max"
        px="2"
        py="1"
        rounded="lg"
        fontWeight="bold"
        fontSize="12"
        color={getTextColor(readOnly, state.isChecked)}
        {...getSize("xs")}
      > 
        <Text
          fontSize="inherit"
          color="inherit"
        >{children}</Text>
      </Box>
    </label>
  )
}
