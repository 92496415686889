/////////////////////
// Use Fetch Projects Hook
/////////////////////

// Basic Imports
import { useEffect, useState } from "react";

// Design Imports
import useCustomToast from "./useCustomToast";

// Layout and Section Imports

// Data Imports
import { useQuery, UseQueryOptions } from "react-query";

// Custom Hooks and Services
import { updateProjectsOrder } from "services/projectsService";

// Interfaces
import { project_table_schema } from "schemas/project_table";
import { ERRORS } from "settings/constants/toastMessages";

// Functions

const useFetchProjects = (
  queryKey: any,
  axiosReqFn: any,
  queryOptions?: UseQueryOptions
) => {
  const [projects, setProjects] = useState([]);
  const { isLoading, error, data, isFetching }: any = useQuery(
    queryKey,
    axiosReqFn,
    { refetchOnWindowFocus: false, ...queryOptions }
  );
  const { errorToast } = useCustomToast();

  useEffect(() => {
    const projects = data?.data?.data?.projects || null;
    if (!projects) return;

    // TEMP: Below piece of code was forcing project to sort date wise but we were loosing drag and drop functionality so I have commented it out and will remove later
    // Sorting in descending order (oldest projects must be on top)
    const clonedProjectsForSorting = [...projects];
    const sortedProjects = clonedProjectsForSorting.sort((p1, p2) => {
      const a = new Date(p1.updatedAt).getTime();
      const b = new Date(p2.updatedAt).getTime();

      return a - b;
    });

    setProjects(sortedProjects.map((project) => project_table_schema(project)));
  }, [data]);

  const handleChangePriorities = async (data) => {
    // Order before sorting/order
    const prevProjects = projects;

    // Update the project with new order
    setProjects(data);

    // Update backend with new order
    const projectIDs = data.map((project) => project.id);
    const { error } = await updateProjectsOrder(projectIDs);

    // If there is error then revert the new changes to the previous changes
    if (!!error) {
      setProjects(prevProjects);
      errorToast({ title: ERRORS.GENERIC });
    }
  };

  return {
    projects,
    isLoading: isLoading || isFetching,
    error,
    setProjects,
    onChangePriorities: handleChangePriorities,
  };
};

export default useFetchProjects;
