import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import useAuth from "hooks/useAuth";
import useCustomToast from "hooks/useCustomToast";
import useSocket from "hooks/useSocket";
import { FiMenu } from "react-icons/fi";
import { markNotificationsAsRead } from "services/notificationService";
import { Notification } from "./Notification";
import { NotificationBody } from "./NotificationBody";

const NotificationMenu = ({
  notificationData,
  isConnected,
  onDeleteNotification,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { token }: any = useAuth();
  const { errorToast } = useCustomToast();

  const handleClearAllNotifications = async () => {
    onClose();

    if (!notificationData || notificationData.notifications.length === 0)
      return;

    const allIds = notificationData?.notifications.map((not) => not._id) || [];

    if (allIds.length === 0) return;

    const { error } = await markNotificationsAsRead(allIds, token);
    if (!!error) {
      errorToast({ title: "Something went wrong in notifications" });
      return;
    }

    onDeleteNotification(allIds);
  };

  if (!isConnected) return null;
  return (
    <Box>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur>
        <PopoverTrigger>
          <Box>
            {/* FOR SOCKET IO */}
            <Notification count={notificationData?.unread || 0} />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          outline="none"
          _focus={{
            outline: "none",
          }}
          w={{ base: "17rem", md: "30rem" }}
        >
          <PopoverArrow />
          <PopoverHeader>
            <Flex justify="space-between" alignItems="center">
              <Box>
                <PopoverCloseButton size="md" left="1" top="2" color="black" />
              </Box>
              <Heading flex="1" justifySelf="flex-start" as="h4" size="xs">
                <Center h="32px" color="black">
                  Notification
                </Center>
              </Heading>
              {notificationData.notifications.length > 0 ? (
                <Menu>
                  <MenuButton color="blue.600">
                    <FiMenu size="24px" />
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color="black"
                      onClick={handleClearAllNotifications}
                    >
                      Clear All
                    </MenuItem>
                    {/* <MenuItem color="black">Clear current</MenuItem> */}
                  </MenuList>
                </Menu>
              ) : null}
            </Flex>
          </PopoverHeader>
          <PopoverBody overflowY="auto" maxH="80vh" color="black" p={0}>
            {notificationData.notifications.length === 0 ? (
              <Alert status="success">
                <AlertIcon />
                All notifications are caught up. 🎉
              </Alert>
            ) : (
              <>
                <NotificationBody
                  data={notificationData.notifications}
                  onDeleteNotification={onDeleteNotification}
                  onClickNotification={async ({ notificationId, isRead }) => {
                    if (!!isRead) {
                      onClose();
                      return;
                    }

                    onClose();
                    await markNotificationsAsRead([notificationId], token);
                  }}
                />

                <Box
                  boxShadow="rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset"
                  position="absolute"
                  bottom="0"
                  w="full"
                  bg="white"
                  py={{ base: "1", md: "2" }}
                >
                  <Button
                    size={{ base: "sm", md: "md" }}
                    variant="link"
                    colorScheme="blue"
                    w="full"
                    onClick={handleClearAllNotifications}
                  >
                    Mark all as read
                  </Button>
                </Box>
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default NotificationMenu;
