/////////////////////
// Require Auth Layout
// Anything with this layout require login.
/////////////////////

// Basic Imports
import { Outlet } from "react-router-dom";

// Design Imports

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
const RequireAuthLayout = () => {
  return <Outlet />;
};

export default RequireAuthLayout;
