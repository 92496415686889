import { Alert, AlertIcon, SimpleGrid } from "@chakra-ui/react";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { fetchAllStaffMembers } from "services/taskService";
import { WrapIntoTryCatch } from "utils/wrapIntoTryCatch";
import { TaskBox } from "./TaskBox";

export const TaskSectionCard = (props: any) => {
  const { task, auth, fetchFlag } = props;
  const { token }: any = useAuth();
  const [staffMemebrs, setStaffMembers] = useState([]);

  const fetchStaff = async () => {
    const { data } = await WrapIntoTryCatch(() => fetchAllStaffMembers(token));
    setStaffMembers(data.data.staffs);
  };
  useEffect(() => {
    fetchStaff();
  }, []);

  if (task.length == 0)
    return (
      <Alert status="info">
        <AlertIcon />
        Nothing to show
      </Alert>
    );
  return (
    <SimpleGrid columns={{ base: 1, md: 1 }} gap={{ base: "5", md: "6" }}>
      {task.map((task) => (
        <TaskBox
          key={task._id}
          task={task}
          auth={auth}
          fetchFlag={fetchFlag}
          staffMemebrs={staffMemebrs}
        />
      ))}
    </SimpleGrid>
  );
};
