/////////////////////
// Active Projects Page
/////////////////////

// Basic Imports
import { FC, Fragment } from "react";

// Design Imports
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Layout and Section Imports
import { columns } from "components/sections/ProjectsSection/Table/Body";
import DragDropTable from "components/sections/ProjectsSection/Table/DragDropTable";
import { REACT_QUERY_KEYS } from "settings/constants/ReactQuery";

// Data Imports
import useFetchProjects from "hooks/useFetchProjects";
import {
  fetchActiveProjectsAxiosRequest,
  ProjectPage,
} from "services/projectsService";

// Interfaces

// Custom Hooks and Services
import useAuth from "hooks/useAuth";
import { TABLE_NAME } from "settings/constants/QueryTable";

// Functions
const ActiveProjects = ({ notificationData, isConnected }: ProjectPage) => {
  const { auth, user }: any = useAuth();
  const { projects, isLoading, setProjects, onChangePriorities } =
    useFetchProjects(
      [REACT_QUERY_KEYS.ACTIVE_PROJECTS, user?._id, auth?.accessToken],
      () =>
        fetchActiveProjectsAxiosRequest(
          auth?.accessToken || "",
          user?._id || ""
        ),
      { enabled: !!user?._id }
    );

  if (!!isLoading) return <SkeletonLoading />;
  return (
    <Fragment>
      <DragDropTable
        notificationData={notificationData}
        columns={columns({ tableName: TABLE_NAME.ACTIVE_TABLE })}
        data={projects.filter((p) => p.state === "active")}
        setData={setProjects}
        updateDataOnPriority={onChangePriorities}
      />
    </Fragment>
  );
};

export default ActiveProjects;
