import {
  Box,
  Container,
  Icon,
  IconButton,
  List,
  Stack,
  Wrap,
} from "@chakra-ui/react";
import { ListItem } from "components/ui/ListWithVerticalLine/ListItem";
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";
import React, { FC, useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import VisibilitySensor from "react-visibility-sensor";
import { FaReply } from "react-icons/fa";
import useAuth from "hooks/useAuth";
import { getCommentSender } from "utils/getCommentSender";
import { formatDate } from "utils/date";
import { CardHeader } from "components/ui/common/CardHeader";
import { BsChatSquareText } from "react-icons/bs";
import HtmlReactParser from "components/ui/HtmlReactParser";
import { FilePreview } from "hr-design-system";
import _ from "lodash";

interface Props {
  isLoading: boolean;
  comments: any[];
  onEdit: (file: any) => void;
}
const CommentsSection: FC<Props> = ({ isLoading, comments, onEdit }) => {
  const endMessageDiv = useRef<HTMLDivElement>();
  const [atLastComment, setAtLastComment] = useState(false);

  const handleScrollToBottom = () => {
    if (!endMessageDiv.current) return;
    endMessageDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };
  useEffect(handleScrollToBottom, [comments]);

  if (!!isLoading) return <SkeletonLoading mt="6" />;
  if (comments.length === 0) return null;
  return (
    <Stack mt="14">
      <CardHeader title="Project Comments" action="" />
      <Box
        position="relative"
        border="1px"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        {!atLastComment && (
          <IconButton
            aria-label="scrollToBottom"
            icon={<IoIosArrowDown />}
            position="absolute"
            bottom="5"
            right="5"
            rounded="full"
            size="lg"
            shadow="lg"
            colorScheme="blue"
            onClick={handleScrollToBottom}
          />
        )}
        <Container
          mt="10"
          as="section"
          maxH="70rem"
          maxW="full"
          overflowY="scroll"
        >
          <Box mx="auto" p={{ base: "4", md: "6" }}>
            {comments.map((comment) => {
              const hasReplies = comment?.children?.length > 0;
              return (
                <List key={comment._id} spacing="12">
                  {/* Main Comment */}
                  <Comment
                    onEdit={onEdit}
                    comment={comment}
                    showLine={hasReplies}
                  />

                  {/* Show Replies */}
                  {comment?.children?.map((childComment, i) => {
                    const isLast = comment.children.length === i + 1;

                    return (
                      <Comment
                        key={childComment._id}
                        comment={childComment}
                        showLine={!isLast}
                        isItReply={true}
                        onEdit={onEdit}
                      />
                    );
                  })}
                  <VisibilitySensor
                    onChange={(value: boolean) => setAtLastComment(value)}
                  >
                    <Box h=".2rem" ref={endMessageDiv} />
                  </VisibilitySensor>
                </List>
              );
            })}
          </Box>
        </Container>
      </Box>
    </Stack>
  );
};

interface CommentProps {
  comment: any;
  showLine?: boolean;
  hasReplies?: boolean;
  isItReply?: boolean;
  onEdit?: (file: any) => void;
}
const Comment: FC<CommentProps> = React.memo(
  ({ comment, showLine = false, isItReply = false, onEdit }) => {
    const { user }: any = useAuth();

    const createdAt = formatDate(new Date(comment.createdAt));
    const chatPerson = getCommentSender(user._id, comment);

    return (
      <ListItem
        title={`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
        titleTag={chatPerson}
        subTitle={createdAt}
        isLastItem={!showLine}
        circleStyle={{
          bg: chatPerson === "me" ? "gray.300" : "blue.500",
          color: chatPerson === "me" ? "black" : "white",
        }}
        iconVsImg={chatPerson === "me" ? "img" : "icon"}
        icon={<Icon as={isItReply ? FaReply : BsChatSquareText} boxSize="5" />}
        imgUrl={comment?.createdBy?.profile?.avatar}
      >
        <Stack spacing={6} p="4" bg="gray.100" rounded="md" w="full">
          <HtmlReactParser html={comment.htmlText || comment.text} />
          <Wrap>
            {comment.attachments.map((att) => {
              const file = {
                thumbnailUrl: att.thumbnailUrl || att.cdnUrl,
                secure_url: _.startsWith(att.cdnUrl, "http")
                  ? att.cdnUrl
                  : "https://homerender." + att.cdnUrl,
                format: att.format,
                name: att.name,
              };
              return (
                <FilePreview
                  size="md"
                  fileURL={file.secure_url}
                  fileType={file.format || ""}
                  fileName={file.name}
                  allowClickToEnlarge
                  allowDownload
                  allowEdit={
                    file.format === "png" ||
                    file.format === "jpeg" ||
                    file.format === "jpg" ||
                    file.format === "heic" ||
                    file.format === "webp"
                  }
                  onEdit={() => onEdit(file)}
                />
              );
            })}
          </Wrap>
        </Stack>
      </ListItem>
    );
  }
);

export default CommentsSection;
