/////////////////////
// Output Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Box, Button, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { ROUTES } from "settings/constants/routes";
import { Link, useParams } from "react-router-dom";
import { FaRegImage, FaTasks } from "react-icons/fa";
import { HiHashtag } from "react-icons/hi";
import { GiResize } from "react-icons/gi";
import { MdDriveFolderUpload, MdWrapText } from "react-icons/md";
import { GoZap } from "react-icons/go";
import useCustomToast from "hooks/useCustomToast";
import useAuth from "hooks/useAuth";
import useFetchAssets from "hooks/useFetchAssets";
import _ from "lodash";
import { renderAssetTypeAsDB } from "schemas/assets_schema";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props {
  task: any;
  getAsyncData: () => void;
}

// Functions

const Task: FC<Props> = ({ task, getAsyncData }) => {
  const { addBatchTaskMutationQuery, cameraCaptureLocations } =
    useFetchAssets();
  const { id: projectId } = useParams();
  // @ts-ignore
  const { user } = useAuth();
  const { errorToast, successToast } = useCustomToast();

  const handleAutoCreatedTask = (asset: any) => {
    if (!projectId) {
      errorToast({
        title: "Something wrong, may be project is not initialized.",
      });
      return;
    }

    const defaultAssignee = user._id;

    const autoCreatedTasks: any[] = [];
    for (let i = 0; i < asset.quantity; i++) {
      autoCreatedTasks.push({
        output: "",
        name: `${asset.title} ${i + 1}`,
        description:
          _.find(cameraCaptureLocations as any[], {
            value: asset.cameraCaptureLocations[i],
          })?.label || "",
        projectId: projectId,
        assignee: defaultAssignee,
        stage: "Information Gathering",
      });
    }

    if (!user?._id) {
      errorToast({
        title: "Something wrong while verifying user.",
      });
      return;
    }

    addBatchTaskMutationQuery.mutate(
      autoCreatedTasks.map((t) => ({
        assetType: renderAssetTypeAsDB[asset.title.replaceAll(" ", "")],
        outputId: asset._id,
        assignee: t.assignee,
        createdBy: user?._id || "",
        description: t.description,
        name: t.name,
        projectId: projectId,
        stage: t.stage,
      })),
      {
        onSuccess: (data) => {
          successToast({
            title: `Created ${data.length} tasks successfully for ${asset.title}`,
          });
          getAsyncData();
        },
      }
    );
  };

  return (
    <VStack alignItems="left" w="full">
      <HStack spacing="2" w="full">
        <VStack
          w="full"
          spacing="4"
          p={4}
          borderRadius="xl"
          borderColor={"gray.200"}
          borderWidth="2px"
        >
          <HStack w="full" justifyContent="space-between">
            <HStack spacing="2">
              <Box>
                <FaRegImage size="20" color="green" />
              </Box>
              <Text noOfLines={1}>{task?.title}</Text>
            </HStack>
            <HStack spacing="2">
              <Box>
                <HiHashtag size="20" color="#526bd8" />
              </Box>
              <Text>{task?.quantity}</Text>
            </HStack>
            <HStack spacing="2">
              <Box>
                <GiResize size="18" color="#6a30de" />
              </Box>
              <Text>{task?.imageSize}</Text>
            </HStack>
            <HStack spacing="2">
              <Box>
                <MdDriveFolderUpload size="20" color="#3182ce" />
              </Box>
              <Text>{task?.sourceFile}</Text>
            </HStack>
          </HStack>
          <VStack alignItems="start" w="full">
            {task.tasks && task.tasks.length ? (
              task.tasks.map((t) => (
                <HStack key={t._id} alignItems="start" w="full" spacing={10}>
                  <HStack spacing="2">
                    <Box>
                      <FaTasks size="18" color="green" />
                    </Box>
                    <Text noOfLines={1}>{t?.name}</Text>
                  </HStack>
                  <HStack spacing="2">
                    <Box>
                      <MdWrapText size="20" color="#526bd8" />
                    </Box>
                    <Tooltip label={t.description ? t.description : t.stage}>
                      <Text noOfLines={1}>
                        {t.description ? t.description : t.stage}
                      </Text>
                    </Tooltip>
                  </HStack>
                </HStack>
              ))
            ) : (
              <Button
                color="blue.500"
                type="submit"
                w="full"
                variant="outline"
                colorScheme="blue"
                isLoading={addBatchTaskMutationQuery.isLoading}
                onClick={() => handleAutoCreatedTask(task.output)}
                leftIcon={<GoZap />}
              >
                Auto create tasks
              </Button>
            )}
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default Task;
