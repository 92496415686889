import {
  Box,
  Circle,
  HStack,
  Image,
  Text,
  Avatar,
  Select,
  Progress,
  Button,
  Tooltip,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack,
  Spinner,
  Icon,
  useDisclosure,
  AspectRatio,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdOutlineSubtitles, MdTimeline, MdViewQuilt } from "react-icons/md";
import {
  getSelectedAsset,
  getSelectedTask,
  getStageProgress,
} from "services/assetService";
import {
  AiFillEdit,
  AiFillProject,
  AiFillQuestionCircle,
} from "react-icons/ai";
import { BsCardImage, BsFillCheckCircleFill } from "react-icons/bs";
import { ImStopwatch } from "react-icons/im";
import moment from "moment";
import { updateTaskStatus, updateTaskStage } from "services/taskService";
import { Link } from "react-router-dom";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";
import { useState } from "react";
import { FcParallelTasks } from "react-icons/fc";
import { GrImage, GrProjects, GrTasks } from "react-icons/gr";
import { RiProjectorFill } from "react-icons/ri";
import { TiImage } from "react-icons/ti";
import { FaRegImage, FaWalking } from "react-icons/fa";
import TaskEditModal from "./TaskEdit";
import { styleCardsData } from "data/projectWizardData/styleCardData";
import { assets } from "data/projectWizardData/index";
import { GiProgression } from "react-icons/gi";
import { TbTextResize } from "react-icons/tb";
interface Props {
  task: any;
  auth: any;
  staffMemebrs: any[];
  fetchFlag?: () => void;
}
export const TaskBox = (props: Props) => {
  const { auth, task, fetchFlag, staffMemebrs, ...boxProps } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const project = task.projectId;
  const [loadingStage, setLoadingStage] = useState(false);
  const [loading, setLoading] = useState({
    todo: false,
    "in-progress": false,
    completed: false,
  });
  const { errorToast, successToast } = useCustomToast();
  const selectedAsset = getSelectedAsset(task.assetType);
  const selectedTask = getSelectedTask(task, assets, styleCardsData);
  const changeTaskStatus = async (status: any) => {
    setLoading({ ...loading, [status]: true });
    const { data } = await updateTaskStatus(
      auth?.accessToken,
      task._id,
      status
    );
    if (data.success) {
      fetchFlag();
      successToast({ title: SUCCESS.TASK_UPDATED });
    } else {
      errorToast({ title: ERRORS.GENERIC });
    }
    setLoading({ ...loading, [status]: false });
  };
  const changeTaskStage = async (stage: any) => {
    setLoadingStage(true);
    const { data } = await updateTaskStage(auth?.accessToken, task._id, stage);
    if (data.success) {
      fetchFlag();
      successToast({ title: SUCCESS.TASK_UPDATED });
    } else {
      errorToast({ title: ERRORS.GENERIC });
    }
    setLoadingStage(false);
  };
  return (
    <Box
      px={{ base: "4", md: "4" }}
      py={{ base: "5", md: "4" }}
      bg="bg-surface"
      w="full"
      borderRadius="lg"
      border="1px solid #7070705c"
      {...boxProps}
      position="relative"
    >
      {/* <Popover trigger="hover">
        <PopoverTrigger>
          <Box position="absolute" right="2" top="1">
            <AiFillQuestionCircle />
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>
            <HStack>
              <Text
                fontSize="md"
                color="muted"
                fontWeight={"bold"}
                textColor="black"
              >
                Description
              </Text>
              <Text fontSize="md" color="muted" textColor="black">
                {task?.description}
              </Text>
            </HStack>
          </PopoverHeader>
        </PopoverContent>
      </Popover> */}
      <Icon
        as={AiFillEdit}
        position="absolute"
        right="2"
        top="1"
        cursor="pointer"
        onClick={onOpen}
      />
      <TaskEditModal
        isOpen={isOpen}
        assetType={task?.assetType}
        onClose={onClose}
        allAssets={project?.selectedOutputs}
        taskId={task?._id}
        token={auth?.accessToken}
        defaultValues={{
          name: task.name,
          description: task.description,
          assignee: task.assignee,
          stage: task.stage,
        }}
        //selectedAsset={selectedAsset}
        fetchFlag={fetchFlag}
        staffMemebrs={staffMemebrs}
      />
      <HStack
        opacity={task?.status === "completed" ? 0.5 : 1}
        w="full"
        spacing={4}
      >
        <Box
          bgImage={`url(${selectedTask.thumbnail})`}
          bgPosition="center"
          bgSize="cover"
          bgRepeat="no-repeat"
          boxSize={20}
          borderRadius={"md"}
        />
        <VStack align="start" spacing={4} w="full">
          <HStack w="full" spacing={10}>
            {project ? (
              <HStack pb={2} gap={2} as={Link} to={"/projects/" + project?._id}>
                <GrProjects />
                <Text
                  fontSize="sm"
                  color="muted"
                  fontWeight={"bold"}
                  textColor="black"
                >
                  Project
                </Text>
                <Tooltip hasArrow={true} label={project && project.name}>
                  <Text
                    noOfLines={1}
                    as={Link}
                    to={"/projects/" + project._id}
                    fontSize="md"
                    color="muted"
                    textColor="black"
                    cursor="pointer"
                  >
                    {project && project.name}
                  </Text>
                </Tooltip>
              </HStack>
            ) : null}

            <HStack pb={2} gap={2} as={Link} to={"/projects/" + project?._id}>
              <Tooltip
                hasArrow={true}
                label={
                  selectedTask.assetType
                    ? selectedTask.asset.title
                    : selectedTask.name
                }
              >
                <Box mt={2}>
                  <Icon as={selectedTask.asset.icon} fontSize={20} />
                </Box>
              </Tooltip>
              <Text
                fontSize="sm"
                color="muted"
                fontWeight={"bold"}
                textColor="black"
              >
                Desired asset
              </Text>
              <Tooltip hasArrow={true} label={project && project.name}>
                <Text
                  noOfLines={1}
                  as={Link}
                  to={"/projects/" + project._id}
                  fontSize="md"
                  color="muted"
                  textColor="black"
                  cursor="pointer"
                >
                  {selectedTask.assetType
                    ? selectedTask.asset.title
                    : selectedTask.name}
                </Text>
              </Tooltip>
            </HStack>
            <HStack>
              {(task.status === "in-progress" ||
                task.status === "assigned") && (
                <>
                  <Button
                    isLoading={loading["todo"]}
                    onClick={() => changeTaskStatus("todo")}
                    w="full"
                    colorScheme="blue"
                    variant="solid"
                  >
                    Mark todo
                  </Button>

                  <Button
                    isLoading={loading["completed"]}
                    onClick={() => changeTaskStatus("completed")}
                    w="full"
                    colorScheme="blue"
                    variant="solid"
                  >
                    Mark complete
                  </Button>
                </>
              )}
              {task.status === "completed" && (
                <>
                  <Button
                    isLoading={loading["in-progress"]}
                    onClick={() => changeTaskStatus("in-progress")}
                    w="full"
                    colorScheme="blue"
                    variant="solid"
                  >
                    Mark in progress
                  </Button>
                  <Button
                    isLoading={loading["todo"]}
                    onClick={() => changeTaskStatus("todo")}
                    w="full"
                    colorScheme="blue"
                    variant="solid"
                  >
                    Mark todo
                  </Button>
                </>
              )}
              {task.status === "todo" && (
                <>
                  <Button
                    isLoading={loading["completed"]}
                    onClick={() => changeTaskStatus("completed")}
                    w="full"
                    colorScheme="blue"
                    variant="solid"
                  >
                    Mark completed
                  </Button>
                  <Button
                    isLoading={loading["in-progress"]}
                    onClick={() => changeTaskStatus("in-progress")}
                    w="full"
                    colorScheme="blue"
                    variant="solid"
                  >
                    Mark in progress
                  </Button>
                </>
              )}
            </HStack>
          </HStack>
          <HStack w="full" spacing={10}>
            <HStack justifyContent="center">
              {loadingStage ? (
                <Spinner />
              ) : (
                <HStack>
                  <HStack
                    pb={2}
                    gap={2}
                    as={Link}
                    to={"/projects/" + project?._id}
                  >
                    <Tooltip
                      label={`${task?.createdBy?.firstName} ${task?.createdBy?.lastName}`}
                      placement="top"
                      hasArrow
                    >
                      <Avatar
                        name={`${task?.createdBy?.firstName} ${task?.createdBy?.lastName}`}
                        src={task?.createdBy?.profile?.avatar}
                        size="sm"
                        fontSize="10"
                      />
                    </Tooltip>
                    <Text
                      fontSize="sm"
                      color="muted"
                      fontWeight={"bold"}
                      textColor="black"
                    >
                      Stage
                    </Text>
                  </HStack>
                  <Select
                    placeholder="Select stage"
                    variant="filled"
                    onChange={(e) => changeTaskStage(e.target.value)}
                    value={task?.stage}
                  >
                    {selectedAsset &&
                      selectedAsset?.map((stage, index) => (
                        <option value={stage?.display} key={index}>
                          {" "}
                          {stage?.display}{" "}
                        </option>
                      ))}
                  </Select>
                </HStack>
              )}
            </HStack>
            <HStack justifyContent="center">
              <HStack>
                <HStack
                  pb={2}
                  gap={2}
                  as={Link}
                  to={"/projects/" + project?._id}
                >
                  <TbTextResize />
                  <Text
                    fontSize="sm"
                    color="muted"
                    fontWeight={"bold"}
                    textColor="black"
                  >
                    Title
                  </Text>
                  <Tooltip hasArrow={true} label={selectedTask.name}>
                    <Text
                      noOfLines={1}
                      fontSize="md"
                      color="muted"
                      textColor="black"
                      cursor="pointer"
                    >
                      {selectedTask.name}
                    </Text>
                  </Tooltip>
                </HStack>
              </HStack>
            </HStack>
            {selectedTask.description && (
              <HStack justifyContent="center">
                <HStack>
                  <HStack
                    pb={2}
                    gap={2}
                    as={Link}
                    to={"/projects/" + project?._id}
                  >
                    <MdOutlineSubtitles fontSize={20} />
                    {/* <Text
                    fontSize="sm"
                    color="muted"
                    fontWeight={"bold"}
                    textColor="black"
                  >
                    Title
                  </Text> */}
                    <Tooltip hasArrow={true} label={selectedTask.description}>
                      <Text
                        noOfLines={1}
                        fontSize="md"
                        color="muted"
                        textColor="black"
                        cursor="pointer"
                      >
                        {selectedTask.description}
                      </Text>
                    </Tooltip>
                  </HStack>
                </HStack>
              </HStack>
            )}
          </HStack>
        </VStack>
        {/* <HStack pb={2}>
          {task?.status === "completed" ? (
            <BsFillCheckCircleFill color="green" size="20" />
          ) : (
            <Circle bg="#E74F4F" color="#FFFFFF" size="6" fontSize="12" ml={-1}>
              <RiProjectorFill />
            </Circle>
          )}
          <Tooltip hasArrow={true} label={task?.name}>
            <Text
              noOfLines={1}
              fontSize="md"
              color="muted"
              fontWeight="bold"
              textColor="black"
            >
              {task?.name}
            </Text>
          </Tooltip>
        </HStack> */}
        {/* <HStack gap={1} pb={2} minW="180px">
          <MdTimeline fontSize="25" color="black" />
          <Text
            fontSize="sm"
            fontWeight={"bold"}
            color="muted"
            textColor="black"
          >
            Stage
          </Text>
          <Progress
            value={getStageProgress(
              task?.stage,
              task.assetId,
              project?.selectedOutputs
            )}
            size="xs"
            color="#3181CE"
            w="full"
            borderRadius="20px"
            h="8px"
          />
        </HStack>
        <HStack gap={1} alignItems="start" pb={2}>
          <ImStopwatch fontSize="20" color="black" />
          <Text fontSize="md" color="muted" textColor="black" noOfLines={1}>
            {moment(task?.updatedAt).format("MMM Do YYYY")}
          </Text>
        </HStack>
        <HStack justifyContent="center">
          {loadingStage ? (
            <Spinner />
          ) : (
            <Select
              placeholder="Select stage"
              variant="filled"
              onChange={(e) => changeTaskStage(e.target.value)}
              value={task?.stage}
            >
              {selectedAsset &&
                selectedAsset?.map((stage, index) => (
                  <option value={stage?.display} key={index}>
                    {" "}
                    {stage?.display}{" "}
                  </option>
                ))}
            </Select>
          )}
        </HStack>
        <HStack alignItems="start">
          {(task.status === "in-progress" || task.status === "assigned") && (
            <>
              <Button
                isLoading={loading["todo"]}
                onClick={() => changeTaskStatus("todo")}
                w="full"
                colorScheme="blue"
                variant="solid"
              >
                Mark todo
              </Button>

              <Button
                isLoading={loading["completed"]}
                onClick={() => changeTaskStatus("completed")}
                w="full"
                colorScheme="blue"
                variant="solid"
              >
                Mark complete
              </Button>
            </>
          )}
          {task.status === "completed" && (
            <>
              <Button
                isLoading={loading["in-progress"]}
                onClick={() => changeTaskStatus("in-progress")}
                w="full"
                colorScheme="blue"
                variant="solid"
              >
                Mark in progress
              </Button>
              <Button
                isLoading={loading["todo"]}
                onClick={() => changeTaskStatus("todo")}
                w="full"
                colorScheme="blue"
                variant="solid"
              >
                Mark todo
              </Button>
            </>
          )}
          {task.status === "todo" && (
            <>
              <Button
                isLoading={loading["completed"]}
                onClick={() => changeTaskStatus("completed")}
                w="full"
                colorScheme="blue"
                variant="solid"
              >
                Mark completed
              </Button>
              <Button
                isLoading={loading["in-progress"]}
                onClick={() => changeTaskStatus("in-progress")}
                w="full"
                colorScheme="blue"
                variant="solid"
              >
                Mark in progress
              </Button>
            </>
          )}
        </HStack> */}
      </HStack>
    </Box>
  );
};
