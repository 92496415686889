import {
  Box,
  Container,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { ProfilePage } from "pages";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "../../../constants";

// TODO: Find a cleaner way, not very clean
function mapRouteToTabIndex(activeRoute) {
  if (activeRoute === ROUTES.ACCOUNT) return 0;
  // if (activeRoute === ROUTES.ACCOUNT_BILLING) return 1;
  if (activeRoute === ROUTES.ACCOUNT_SECURITY) return 1;
  // if (activeRoute === ROUTES.ACCOUNT_COMMUNICATIONS) return 3;

  return 0;
}

const AccountLayout = () => {
  const { pathname } = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [tabIndex, setTabIndex] = useState(mapRouteToTabIndex(pathname));

  useEffect(() => {
    setTabIndex(mapRouteToTabIndex(pathname));
  }, [pathname]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };
  return (
    <Box
      as="section"
      bg="bg-surface"
      pt={{ base: "4", md: "8" }}
      pb={{ base: "12", md: "24" }}
    >
      <Container
        bg={mode("white", "blackAlpha.100")}
        maxW="container.xl"
        pl="10"
        pr="10"
        rounded="md"
      >
        <Stack spacing="5">
          <Stack
            spacing="4"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <Box>
              <Text fontSize="xl" fontWeight="medium">
                Account settings
              </Text>
              {/* <Text color="muted" fontSize="sm">
                Edit your profile
              </Text> */}
            </Box>
          </Stack>
          {/* <ProfilePage /> */}
          <Tabs
            position="relative"
            variant="unstyled"
            index={tabIndex}
            onChange={handleTabsChange}
            overflowX={isMobile ? "scroll" : "hidden"}
            overflowY="hidden"
          >
            <TabList
              sx={{
                "& a:focus": {
                  boxShadow: "none",
                },
              }}
            >
              <Tab as={Link} to={ROUTES.ACCOUNT}>
                Profile
              </Tab>
              <Tab as={Link} to={ROUTES.ACCOUNT_SECURITY}>
                Security
              </Tab>
              {/* <Tab as={Link} to={ROUTES.ACCOUNT_BILLING}>
                Billing
              </Tab>
              
              <Tab as={Link} to={ROUTES.ACCOUNT_COMMUNICATIONS}>
                Communications
              </Tab> */}
            </TabList>
            <TabIndicator
              mt="-2px"
              height="2px"
              bg="blue.500"
              borderRadius="1px"
            />
          </Tabs>
        </Stack>
        <Box>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
};

export default AccountLayout;
