/////////////////////
// Form / Auth Layout Page
/////////////////////

// Basic Imports
import { FC } from "react";
import { Link } from "react-router-dom";

// Design Imports
import {
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Logo } from "components/ui/logo/Logo2";

// Layout and Section Imports

// Data Imports

// Functions

// Hooks

// Interfaces
interface Props {
  title: string;
  description?: string;
  link?: {
    label: string;
    to: string;
  };
}
const FormHeader: FC<Props> = ({ title, description, link }) => {
  return (
    <Stack spacing="6">
      <Logo height={20} />
      <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
        <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
          {title}
        </Heading>
        <HStack spacing="1" justify="center">
          {!!description && <Text color="muted">{description}</Text>}
          {!!link?.label && (
            <Button as={Link} to={link.to} variant="link" colorScheme="blue">
              {link.label}
            </Button>
          )}
        </HStack>
      </Stack>
    </Stack>
  );
};

export default FormHeader;
