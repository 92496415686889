export const insertIntoArray = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index + 1)]
export const updateArray = (prevArr: any[], index: number, newItem: any) => {
  if (index === -1) return [...prevArr, newItem];

  return [...prevArr.slice(0, index), newItem, ...prevArr.slice(index + 1)]
  // return [...prevArr, newItem]
}
export const reverseArray = (originalArray: any[]) => {
  const reversedArray = [];

  // If there is something wrong with originalArray then return it as it is
  if (!originalArray || originalArray.length === 0) return originalArray;

  // Traverse each element and add into reversedArray
  for (const el of originalArray) {
    /**
     * Append to front of an array to reverse the array
     * i.e., [1, 2, 3] --> [3, 2, 1]
     */
    reversedArray.unshift(el);
  }

  return reversedArray
}