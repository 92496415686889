interface IReactQueryKeys {
  ALL_PROJECTS: string
  DRAFT_PROJECTS: string
  ACTIVE_PROJECTS: string
  COMPLETED_PROJECTS: string
  DELETED_PROJECTS: string
  PAUSED_PROJECTS: string
}
export const REACT_QUERY_KEYS:IReactQueryKeys = {
  ALL_PROJECTS: "allProjects",
  DRAFT_PROJECTS: "draftProjects",
  ACTIVE_PROJECTS: "activeProjects",
  COMPLETED_PROJECTS: "completedProjects",
  DELETED_PROJECTS: "deletedProjects",
  PAUSED_PROJECTS: "pausedProjects"
}