import {
  FormControl,
  FormLabel,
  Input,
  InputProps,
  Select,
  SelectProps,
  Stack,
  Text,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

interface InputFieldProps extends InputProps {
  variant?: "input";
  styles?: InputProps;
  list?: never;
}

interface TextareaFieldProps extends TextareaProps {
  variant?: "textarea";
  styles?: TextareaProps;
  list?: never;
}

interface SelectFieldProps extends SelectProps {
  variant?: "select";
  styles?: SelectProps;
  list: { label: string; value: string }[];
}
type VariantProps = InputFieldProps | TextareaFieldProps | SelectFieldProps;

interface FormInputFieldProps {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
}

const FormInputField = forwardRef<
  HTMLDivElement,
  FormInputFieldProps & VariantProps
>(
  (
    {
      name,
      label,
      variant = "input",
      list,
      value,
      styles,
      placeholder,
      ...restProps
    },
    ref
  ) => {
    const {
      register,
      formState: { errors },
    } = useFormContext<any>();

    const errorMessage = (_.get(errors, name)?.message || "") as string;

    return (
      <FormControl ref={ref} id={name}>
        {!!label ? <FormLabel>{label}</FormLabel> : null}
        <Stack spacing={0}>
          {variant === "input" ? (
            <Input
              placeholder={placeholder}
              variant={errorMessage ? "error" : "outline"}
              {...register(name)}
              {...(styles as InputProps)}
              {...(restProps as InputProps)}
            />
          ) : null}
          {variant === "textarea" ? (
            <Textarea
              placeholder={placeholder}
              variant={errorMessage ? "error" : "outline"}
              {...register(name)}
              {...(styles as TextareaProps)}
              {...(restProps as TextareaProps)}
            />
          ) : null}
          {variant === "select" ? (
            <Select
              bg="white"
              borderColor={errorMessage ? "red" : "gray.300"}
              _focus={{
                borderColor: errorMessage ? "red" : "gray.300",
              }}
              placeholder={placeholder}
              {...register(name)}
              {...(restProps as SelectProps)}
            >
              {list &&
                list.map((item: any, index: number) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </Select>
          ) : null}

          {!!errorMessage ? (
            <Text color="red" fontSize="sm" fontStyle="italic">
              {errorMessage}
            </Text>
          ) : null}
        </Stack>
      </FormControl>
    );
  }
);

export default FormInputField;
