import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import planReducer from "./planReducer";
import currencyReducer from "./currencyReducer";
import userReducer from "./userReducer";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from 'redux';


const persistConfig = {
  key: 'root',
  storage,
}


const combinedReducer = combineReducers({
  plan: planReducer,
  currency: currencyReducer,
  user: userReducer,
});


const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined
  }
  return combinedReducer(state, action)
}


const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,RootState, unknown, Action<string>>;