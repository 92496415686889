/////////////////////
// [Replace me] Page
/////////////////////

// Basic Imports
import { FC, useEffect, useState } from "react";

// Design imports
import {
  Badge,
  Icon,
  SimpleGrid,
  Stack,
  useCheckboxGroup,
} from "@chakra-ui/react";

import { ButtonlessCheckbox } from "components/ui/common/Checkbox";

// Data imports
import { AssetProps } from "data/projectWizardData";
import {
  BUSINESS_PLAN,
  NONE_PLAN,
  PROFESSIONAL_PLAN,
  STARTER_PLAN,
} from "settings/constants/billingPlans";
import useAuth from "hooks/useAuth";
// Custom Hooks and Services

// Interface imports
interface Props {
  assets: AssetProps[];
  defaultValues?: string[];
  values?: string[];
  onSelectedAssets?: (assets: any) => void;
}
const AssetsCheckBoxes: FC<Props> = ({
  assets,
  defaultValues,
  onSelectedAssets,
}) => {
  // @ts-ignore
  const { user } = useAuth();
  const [checkboxValues, setCheckboxValues] = useState<string[]>(defaultValues);
  const { getCheckboxProps } = useCheckboxGroup({
    value: checkboxValues,
    onChange: (values: string[]) => setCheckboxValues(values),
  });

  useEffect(() => {
    setCheckboxValues(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    const selectedIn = assets.filter((asset: AssetProps) =>
      checkboxValues.some((value) => value === asset.value)
    );
    onSelectedAssets(selectedIn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxValues]);

  return (
    <Stack spacing={6}>
      {/* For starter plans */}
      <Stack align="center" spacing={6}>
        <Badge w="max" py="1" px="3" variant="solid" colorScheme="blue">
          {STARTER_PLAN}
        </Badge>
        <SimpleGrid maxW="7xl" columns={{ base: 1, md: 2 }} spacing={5} pb={5}>
          {assets
            .filter((a) => a.plan === STARTER_PLAN)
            .map((asset: AssetProps, idx: number) => (
              <ButtonlessCheckbox
                key={idx.toString()}
                icon={<Icon as={asset.icon} />}
                title={asset.title}
                description={asset.description}
                isDisabled={
                  false
                  //user.subscription.plan === NONE_PLAN
                }
                disableText="You should have at least starter plan"
                {...getCheckboxProps({ value: asset.value })}
              />
            ))}
        </SimpleGrid>
      </Stack>

      {/* For professional plans */}
      <Stack cursor="not-allowed" align="center" spacing={6}>
        <Badge w="max" py="1" px="3" variant="solid" colorScheme="blue">
          {PROFESSIONAL_PLAN}
        </Badge>
        <SimpleGrid maxW="7xl" columns={{ base: 1, md: 2 }} spacing={5} pb={5}>
          {assets
            .filter((a) => a.plan === PROFESSIONAL_PLAN)
            .map((asset: AssetProps, idx: number) => (
              <ButtonlessCheckbox
                key={idx.toString()}
                icon={<Icon as={asset.icon} />}
                title={asset.title}
                description={asset.description}
                isDisabled={
                  false
                  // user.subscription.plan === STARTER_PLAN ||
                  // user.subscription.plan === NONE_PLAN
                }
                disableText="Only allowed in Professional plan, update your plan by going to Account Settings > Billing > Plan Details"
                {...getCheckboxProps({ value: asset.value })}
              />
            ))}
        </SimpleGrid>
      </Stack>

      {/* For business plans */}
      <Stack align="center" spacing={6}>
        <Badge w="max" py="1" px="3" variant="solid" colorScheme="blue">
          {BUSINESS_PLAN}
        </Badge>
        <SimpleGrid maxW="7xl" columns={{ base: 1, md: 2 }} spacing={5} pb={5}>
          {assets
            .filter((a) => a.plan === BUSINESS_PLAN)
            .map((asset: AssetProps, idx: number) => (
              <ButtonlessCheckbox
                key={idx.toString()}
                icon={<Icon as={asset.icon} />}
                title={asset.title}
                description={asset.description}
                isDisabled={
                  false
                  // asset.actionData === null ||
                  // asset.stylesData.length === 0 ||
                  // user.subscription.plan === STARTER_PLAN ||
                  // user.subscription.plan === PROFESSIONAL_PLAN ||
                  // user.subscription.plan === NONE_PLAN
                }
                disableText="Only allowed in Business plan, update your plan by going to Account Settings > Billing > Plan Details"
                {...getCheckboxProps({ value: asset.value })}
              />
            ))}
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};

export default AssetsCheckBoxes;
