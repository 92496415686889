import axios from "api/axios";
import _ from "lodash";
import { renderAssetTypeAsDB } from "schemas/assets_schema";
import { API_URL } from "settings/site-credentials";
import { taskThumnail } from "./taskService";

export const stage3D = [
  { display: "Information Gathering", completion: 7.14 },
  { display: "General Layout", completion: 14.32 },
  { display: "General Area Considerations", completion: 21.46 },
  { display: "Drafting", completion: 28.6 },
  { display: "Modeling", completion: 35.74 },
  { display: "Detailing", completion: 42.88 },
  { display: "Material Selection", completion: 50 },
  { display: "Texturing", completion: 57.16 },
  { display: "Lighting", completion: 64.3 },
  { display: "Camera Placements", completion: 71.44 },
  { display: "Rendering", completion: 78.58 },
  { display: "Final Touches", completion: 85.72 },
  { display: "Waiting Customer Approval", completion: 92.86 },
  { display: "Finished Current Draft", completion: 100 },
];
export const stage2D = [
  { display: "Information Gathering", completion: 7.14 },
  { display: "General Layout", completion: 14.32 },
  { display: "General Area Considerations", completion: 21.46 },
  { display: "Walls", completion: 28.6 },
  { display: "Windows and Doors", completion: 35.74 },
  { display: "Kitchens", completion: 42.88 },
  { display: "Bathrooms and Laundry", completion: 50 },
  { display: "Bedrooms", completion: 57.16 },
  { display: "Other types of rooms", completion: 64.3 },
  { display: "Exterior Spaces", completion: 71.44 },
  { display: "Changes to Representation", completion: 78.58 },
  { display: "Publishing", completion: 85.72 },
  { display: "Waiting Customer Approval", completion: 92.86 },
  { display: "Finished Current Draft", completion: 100 },
];
export const stageOther = [
  { display: "Information Gathering", completion: 11 },
  { display: "Layout Creation or Template", completion: 22 },
  { display: "Color Scheme Adjustments", completion: 33 },
  { display: "Images", completion: 44 },
  { display: "Content", completion: 55 },
  { display: "Grammar/Spell Checking", completion: 66 },
  { display: "Final Touches", completion: 77 },
  { display: "Customer Approval", completion: 88 },
  { display: "Publishing", completion: 100 },
];

export async function fetchAssetsByProjectId(projectId, token) {
  try {
    const res = await axios.get(`${API_URL}/projects/${projectId}/assets`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const assets = res.data.data.assets as any[];
    return { assets, res };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
}

export async function deleteAttachmentById(
  attachmentId: string,
  token: string
) {
  try {
    const res = await axios.delete(`${API_URL}/attachments/${attachmentId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return true;
  } catch (error) {
    return false;
  }
}

export async function deleteAssetById(assetId: string, token: string) {
  try {
    const res = await axios.delete(`${API_URL}/assets/${assetId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return true;
  } catch (error) {
    return false;
  }
}

export function getStageProgress(
  stage: any,
  assetId: string,
  assetInProject: any
) {
  //1 to show that it is progress bar and nothing has started if we put 0 it will not show as a progress bar
  if (!stage || !assetInProject || !assetId) return 1;

  let stageProgress = 1;
  const asset = assetInProject.filter((a) => a._id === assetId);
  if (_.toLower(asset[0]?.title).replace(/[ ]+/g, "").includes("2d")) {
    const stage2dfiltered = stage2D.filter(
      (s) =>
        _.toLower(s.display).replace(/[ ]+/g, "") ===
        _.toLower(stage).replace(/[ ]+/g, "")
    );
    if (stage2dfiltered.length) {
      stageProgress = stage2dfiltered[0].completion;
    }
  } else if (_.toLower(asset[0]?.title).replace(/[ ]+/g, "").includes("3d")) {
    const stage3dfiltered = stage3D.filter(
      (s) =>
        _.toLower(s.display).replace(/[ ]+/g, "") ===
        _.toLower(stage).replace(/[ ]+/g, "")
    );
    if (stage3dfiltered.length) {
      stageProgress = stage3dfiltered[0].completion;
    }
  } else {
    const stageOtherfiltered = stageOther.filter(
      (s) =>
        _.toLower(s.display).replace(/[ ]+/g, "") ===
        _.toLower(stage).replace(/[ ]+/g, "")
    );
    if (stageOtherfiltered.length) {
      stageProgress = stageOtherfiltered[0].completion;
    }
  }
  return stageProgress;
}
export function getSelectedAsset(assetId: string) {
  //TODO: will ermove code after full testing
  // let assetAndStage: any = {};
  // if (assetId && assetInProject) {
  //   const asset = assetInProject.filter((a) => a._id === assetId);
  //   if (_.toLower(asset[0]?.title).replace(/[ ]+/g, "").includes("2d")) {
  //     assetAndStage.stage = stage2D;
  //   } else if (_.toLower(asset[0]?.title).replace(/[ ]+/g, "").includes("3d")) {
  //     assetAndStage.stage = stage3D;
  //   } else {
  //     assetAndStage.stage = stageOther;
  //   }
  //   assetAndStage.asset = asset[0];
  // } else {
  //   assetAndStage.stage = stageOther;
  // }
  // return assetAndStage;
  if (assetId) {
    if (assetId.includes("2D")) return stage2D;
    if (assetId.includes("3D")) return stage3D;
  } else {
    return stageOther;
  }
}

export function getSelectedTask(
  selectedTask: any,
  assets: any,
  styleCard: any
) {
  let task = selectedTask;
  let style = {};
  let asset = {};
  let thumbnail: string = "";
  if (!selectedTask.assetType) {
    asset = assets.find((a) => a.value === "Other");
    style = styleCard.find((s) => s.title === "Other");
    thumbnail = taskThumnail.other;
  } else {
    asset = assets.find(
      (a) => renderAssetTypeAsDB[a.value] === selectedTask.assetType
    );
    style = styleCard.find(
      (s) =>
        renderAssetTypeAsDB[s.title.replaceAll(" ", "")] ===
        selectedTask.assetType
    );
    thumbnail = taskThumnail[selectedTask.assetType];
  }

  task.asset = { ...asset };
  task.style = { ...style };
  task.thumbnail = thumbnail;
  return task;
}

export const getCommentFile = (uploadedFiles: any, uploadedAsset: any) => {
  if (!uploadedFiles || !uploadedAsset) return null;

  let exactFileById = uploadedFiles.filter(
    (file) => file.asset_id === uploadedAsset.attachment.cloudinaryId
  );
  if (exactFileById.length > 0) {
    exactFileById[0].original_filename = uploadedAsset.attachment.name;
    return exactFileById;
  } else {
    return null;
  }
};
