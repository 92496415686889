///////////////////////////////
// Project Table: NextStep Column
///////////////////////////////

// Basic Imports

// Design Imports
import {
  Alert,
  AlertIcon,
  Avatar,
  AvatarGroup,
  HStack,
  Icon,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import { MdDirectionsWalk } from "react-icons/md";
import TooltipAvatar from "../TooltipAvatar";

const Header = () => {
  return (
    <HStack>
      <Icon as={MdDirectionsWalk} />
      <Text>Next Step</Text>
    </HStack>
  );
};

const Cell = ({ value: next_step }) => {
  return (
    // <Alert status={next_step.icon_status} bg="white" >
    //   <AlertIcon />
    //   <Tooltip label={next_step.text}>
    //     <Text noOfLines={1}>
    //       {next_step.text}
    //     </Text>
    //   </Tooltip>
    // </Alert>
    <HStack spacing={20} w="full">
      <HStack w="80%">
        {next_step?.client && (
          <Tooltip
            label={`${next_step?.client?.firstName} ${next_step?.client?.lastName}`}
            placement="top"
            hasArrow
          >
            <Avatar
              name={`${next_step?.client?.firstName} ${next_step?.client?.lastName}`}
              src={next_step?.client?.profile?.avatar}
              size="sm"
              fontSize="10"
            />
          </Tooltip>
        )}
        <Text fontWeight="bold" noOfLines={1}>
          {`${next_step?.client?.firstName || "None"} ${
            next_step?.client?.lastName || ""
          }`}
        </Text>
      </HStack>

      <HStack justifyContent={"left"} w="20%">
        {next_step?.assignee ? (
          <>
            <AvatarGroup size="sm" fontSize="10">
              {next_step?.assignee?.map((staffMember: any) => (
                <TooltipAvatar
                  key={staffMember?._id}
                  name={`${staffMember?.firstName || "None"} ${
                    staffMember?.lastName || ""
                  }`}
                  src={staffMember?.profile?.avatar}
                />
              ))}
            </AvatarGroup>
          </>
        ) : (
          <Text fontWeight={"bold"} noOfLines={1}>
            No Users assigned
          </Text>
        )}
      </HStack>
    </HStack>
  );
};

export default {
  Header,
  Cell,
};
