/////////////////////
// Alert Feature
/////////////////////

// Basic Imports
import { FC } from "react";
import { Link } from "react-router-dom";

// Design Imports
import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  CloseButton,
  Text,
  Wrap,
} from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services

// Interfaces
interface Props extends AlertProps {
  title: string;
  description?: string;
  status: "error" | "warning" | "success";
  onClose?: () => void;
  showCloseIcon?: boolean;
  link?: {
    label: string;
    href?: string;
    onClick?: () => void;
  };
  descriptionLink?: {
    label: string;
    href?: string;
    onClick?: () => void;
  };
}

// Functions
const Alert: FC<Props> = ({
  status,
  title,
  link,
  onClose,
  description,
  descriptionLink,
  showCloseIcon = true,
  ...restAlertProps
}) => {
  return (
    <ChakraAlert status={status || "error"} my="6" {...restAlertProps}>
      <AlertIcon />
      <Box>
        <Wrap>
          {title && <AlertTitle>{title}</AlertTitle>}
          {link?.href ? (
            <Text variant="textAsLink">
              <Link to={link.href}>{link.label}</Link>
            </Text>
          ) : (
            <Text onClick={link.onClick} variant="textAsLink" pr="2">
              {link.label}
            </Text>
          )}
        </Wrap>
        <Wrap>
          {description && <AlertDescription>{description}</AlertDescription>}

          {/* For operations that no need any link but only onClick (e.g., show navbar etc) */}
          {!!descriptionLink?.onClick && (
            <Text variant="textAsLink" onClick={descriptionLink.onClick}>
              {descriptionLink.label}
            </Text>
          )}
          {/* For operations that need to navigate any other page through "href" (e.g., show login page etc) */}
          {!!descriptionLink?.href && (
            <Text variant="textAsLink">
              <Link to={descriptionLink.href}>{descriptionLink.label}</Link>
            </Text>
          )}
        </Wrap>
      </Box>
      {!!showCloseIcon && (
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-2}
          top={0}
          onClick={onClose}
        />
      )}
    </ChakraAlert>
  );
};

export default Alert;