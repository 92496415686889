import { FC, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import { saveAs } from "file-saver";
import {
  Text,
  Box,
  Image,
  Skeleton,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { BoxProps } from "@chakra-ui/layout";
import { SwiperPropsCustom, SwiperSlidePropsCustom } from "./types";
import { FaDownload } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { deleteAssetById, deleteAttachmentById } from "services/assetService";
import useAuth from "hooks/useAuth";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";
import PDFViewer from "./PDFViewer/PDFViewer";
import useFetchAssets from "hooks/useFetchAssets";

const SwiperApp: FC<SwiperPropsCustom> = ({
  slides,
  isLoading = false,
  styles,
}) => {
  const { token }: any = useAuth();
  const { errorToast, successToast } = useCustomToast();
  const { refetch } = useFetchAssets();
  const [deleteIconLoading, setDeleteIconLoading] = useState(false);
  const boxStyles: BoxProps = {
    my: 4,
    mx: 1,
    height: "fit-content",
    ...styles,
  };
  const deleteAsset = async (id: any, slide: any) => {
    setDeleteIconLoading(true);
    let deleted = false;
    if (slide.count > 1) {
      deleted = await deleteAttachmentById(id, token);
    } else {
      deleted = await deleteAssetById(slide.assetId, token);
    }
    setDeleteIconLoading(false);

    if (deleted) {
      successToast({ title: SUCCESS.ASSET_DELETED });
      refetch();
    } else if (!deleted) {
      errorToast({ title: ERRORS.GENERIC });
    }
  };

  if (isLoading)
    return <Skeleton rounded="md" height={boxStyles.height} {...boxStyles} />;
  return (
    <Box
      as={Swiper}
      navigation={true}
      modules={[Navigation]}
      userSelect="none"
      style={{
        // @ts-ignore
        "--swiper-navigation-color": "black",
        "--swiper-pagination-color": "#fff",
      }}
      {...boxStyles}
    >
      {slides.map((slide: SwiperSlidePropsCustom, index: number) => {
        if (!slide.imgUrl) return null;
        return (
          <SwiperSlide key={index}>
            <Text
              fontSize="lg"
              color="muted"
              fontWeight="bold"
              textColor="black"
            >
              {slide.alt}
            </Text>
            {slide.format != "pdf" ? (
              <Image
                src={slide.imgUrl}
                height="100%"
                width="100%"
                border="2px"
                rounded="md"
                objectFit="contain"
              />
            ) : (
              <PDFViewer url={slide.imgUrl} width={""} />
            )}

            <HStack position="absolute" top="10" right="5">
              <IconButton
                onClick={() => saveAs(slide.imgUrl, slide.name)}
                aria-label="download"
                colorScheme="gray"
                shadow="base"
                icon={<FaDownload size="16" />}
                size="sm"
              />
              <IconButton
                onClick={() => deleteAsset(slide.attchementId, slide)}
                aria-label="delete"
                colorScheme="red"
                shadow="base"
                isLoading={deleteIconLoading}
                icon={<MdDelete size="16" />}
                size="sm"
              />
            </HStack>
          </SwiperSlide>
        );
      })}
    </Box>
  );
};

export default SwiperApp;
