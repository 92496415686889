import axios from "api/axios";
import { render_cloudinary_asset_type_db } from "schemas/asset_schema";
import { API_URL } from "settings/site-credentials";

export async function addAttchementtoAsset(data: any, userToken: string) {
  const formData = new FormData();
  formData.append("name", data.formData.name);
  formData.append("description", data.formData.description);
  formData.append("notes", data.formData.notes);
  formData.append("asssetId", data.formData.assetId);
  formData.append("projectId", data.projectId);
  formData.append("files", data.attachments[0].file);

  try {
    const { data } = await axios.post(`${API_URL}/attachments`, formData, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return { data: data.data };
  } catch (error) {
    console.log("services/assets/createAsset :: ", { error });
    return { error: "Something went wrong!" };
  }
}

export async function createAsset(data: any, userToken: string) {
  const body = {
    name: data.formData.name,
    description: data.formData.description,
    version: data.formData.version,
    notes: data.formData.notes,
    fileSize: data.formData.fileSize,
    title: data.formData.title,
    assetType: data.formData.assetType,
    quantity: JSON.stringify(data.assetQuantity),
    projectId: data.projectId,
    fileType: data.attachments
      .map((att) => render_cloudinary_asset_type_db(att))
      .toString(),
    attachments: data.attachments,
  };
  try {
    const { data } = await axios.post(`${API_URL}/assets`, body, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return { data: data.data };
  } catch (error) {
    console.log("services/assets/createAsset :: ", { error });
    return { error: "Something went wrong!" };
  }
}

export async function updateAssetinDB(
  data: any,
  userToken: string,
  assetId: string
) {
  const body = {
    attachments: data.attachments,
  };
  try {
    const { data } = await axios.patch(`${API_URL}/assets/${assetId}`, body, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return { data: data.data };
  } catch (error) {
    console.log("services/assets/createAsset :: ", { error });
    return { error: "Something went wrong!" };
  }
}

export async function fetchAllAssets(projectId: string, userToken: string) {
  try {
    let { data } = await axios.get(`${API_URL}/projects/${projectId}/assets`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return { data: data?.data?.assets };
  } catch (error) {
    console.log("services/assets/fetchAllAssets :: ", { error });
    return { error: "Something went wrong!" };
  }
}
