import axios from "api/axios";
import { API_URL } from "settings/site-credentials";

export interface MediaResponse {
  fileName: string;
  type: string;
  url: string;
  size: number;
}
interface CreateAttachmentService {
  mediaResponse: File;
  mediaDescription: string;
  projectId: string;
  userToken: string;
  folderName?: string;
}
export async function createAttachmentService({
  mediaResponse,
  mediaDescription,
  projectId,
  userToken,
  folderName,
}: CreateAttachmentService) {
  const formData = new FormData();
  if (mediaDescription) {
    formData.append("description", mediaDescription);
  }
  if (projectId) {
    formData.append("projectId", projectId);
  }
  if (folderName) {
    formData.append("folderName", folderName);
  }
  formData.append("files", mediaResponse);

  try {
    const { data } = await axios.post(`${API_URL}/attachments`, formData, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function fetchAttachmentByIdService(attachmentId, userToken) {
  try {
    const { data } = await axios.get(`${API_URL}/attachments/${attachmentId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function deleteAttachmentByIdService(attachmentId, userToken) {
  try {
    const { data } = await axios.delete(
      `${API_URL}/attachments/${attachmentId}`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}
