/////////////////////
// App Page
/////////////////////

// Basic Imports

// Design Imports
import { Routes, Route, Navigate } from "react-router-dom";

// Layout and Section Imports
import {
  LoginPage,
  SignUpPage,
  //  DashboardPage,
  ProjectPage,
  NewProjectPage,
  ProfilePage,
  BillingPage,
  SecurityPage,
  CommunicationsPage,
  PlanPage,
  HelpPage,
  ForgotPage,
  SuccessPage,
} from "./pages";
import {
  AllProjects,
  CompletedProjects,
  DraftProjects,
  PausedProjects,
  ActiveProjects,
  DeletedProjects,
} from "./pages/ProjectsPage";
import {
  AppLayout,
  DashboardLayout,
  AccountLayout,
  RequireAuthLayout,
  RequirePlanLayout,
} from "./components/layouts";
import { NewProjectPageById } from "pages/NewProjectPage";
import Custom404 from "pages/404";
import VerifyPage from "pages/AuthPage/VerifyPage";

// Data Imports
import { ROUTES } from "./constants";

// Interfaces

// Functions

// Custom Hooks and Services
import ResetPasswordPage from "pages/AuthPage/ResetPasswordPage";
import SendVerificationEmail from "pages/AuthPage/SendVerificationEmail";
import AuthPagesLayout from "components/layouts/AuthPagesLayout";
import Practice from "pages/practice";
import AllTask from "pages/TaskPage/AllTask";
import CompletedTask from "pages/TaskPage/CompletedTask";
import InProgressTask from "pages/TaskPage/InProgressTask";
import TodoTask from "pages/TaskPage/TodoTask";
//CSS
import "tui-image-editor/dist/tui-image-editor.css";
import BacklogTask from "pages/TaskPage/BacklogTask";
import useSocket from "hooks/useSocket";
// Interfaces
interface NavItemProps {
  label: string;
  href: string;
}

// Data
const DASHBOARD_NAV_ITEMS: NavItemProps[] = [{ label: "Overview", href: "/" }];

const PROJECT_NAV_ITEMS: NavItemProps[] = [
  { label: "Active", href: "/projects" },
  { label: "Completed", href: "/projects/completed" },
  { label: "Draft", href: "/projects/draft" },
  { label: "Paused", href: "/projects/paused" },
  { label: "All", href: "/projects/all" },
  { label: "Deleted", href: "/projects/deleted" },
];

const TASK_NAV_ITEMS: NavItemProps[] = [
  { label: "Todo", href: "/mytask/todo" },
  { label: "In Progress", href: "/mytask/in-progress" },
  { label: "Completed", href: "/mytask/complete" },
  { label: "Backlog", href: "/mytask/backlog" },
  { label: "All", href: "/mytask" },
];

function App() {
  const { notificationData, isConnected, onDeleteNotification } = useSocket();
  return (
    <Routes>
      <Route path="/practice" element={<Practice />} />
      <Route element={<AuthPagesLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignUpPage />} />
      </Route>
      <Route path="success/:token" element={<SuccessPage />} />
      <Route path="forgot" element={<ForgotPage />} />
      <Route path="account/verify/:token" element={<VerifyPage />} />
      <Route path="account/verify" element={<SendVerificationEmail />} />
      <Route
        path="account/password/reset/:token"
        element={<ResetPasswordPage />}
      />
      {/* Everything that inside the RequireAuth route requires an Authentication */}
      <Route element={<RequireAuthLayout />}>
        {/* Select Plan Page */}
        <Route path={ROUTES.SELECT_PLAN} element={<PlanPage />} />

        <Route element={<RequirePlanLayout />}>
          {/* <Route path="/" element={ <Navigate to="/projects" replace />}> */}
          <Route
            path="/"
            element={
              <AppLayout
                onDeleteNotification={onDeleteNotification}
                notificationData={notificationData}
                isConnected={isConnected}
              />
            }
          >
            {/* Dashboard Layout */}
            <Route
              element={
                <DashboardLayout
                  title="Client Dashboard"
                  navItems={DASHBOARD_NAV_ITEMS}
                />
              }
            >
              {/* TODO: Since dashboard isn't complete, send them directly to the project page.
              <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
               */}
              <Route
                path={ROUTES.DASHBOARD}
                element={<Navigate to="/projects" replace />}
              />
            </Route>
            {/* Projects Page Layout */}
            <Route
              element={
                <DashboardLayout
                  title="Projects"
                  navItems={PROJECT_NAV_ITEMS}
                  isProjectContainer
                />
              }
            >
              <Route
                path={ROUTES.PROJECTS}
                element={
                  <AllProjects
                    notificationData={notificationData}
                    isConnected={isConnected}
                  />
                }
              />
              <Route
                path={ROUTES.PROJECTS_COMPLETED}
                element={
                  <CompletedProjects
                    notificationData={notificationData}
                    isConnected={isConnected}
                  />
                }
              />
              <Route
                path={ROUTES.PROJECTS_DRAFT}
                element={
                  <DraftProjects
                    notificationData={notificationData}
                    isConnected={isConnected}
                  />
                }
              />
              <Route
                path={ROUTES.PROJECTS_PAUSED}
                element={
                  <PausedProjects
                    notificationData={notificationData}
                    isConnected={isConnected}
                  />
                }
              />
              <Route
                path={ROUTES.PROJECTS_ACTIVE}
                element={
                  <ActiveProjects
                    notificationData={notificationData}
                    isConnected={isConnected}
                  />
                }
              />
              <Route
                path={ROUTES.PROJECTS_DELETED}
                element={
                  <DeletedProjects
                    notificationData={notificationData}
                    isConnected={isConnected}
                  />
                }
              />
            </Route>

            <Route
              element={
                <DashboardLayout
                  title="Tasks"
                  navItems={TASK_NAV_ITEMS}
                  isProjectContainer
                />
              }
            >
              <Route path={ROUTES.TASK_TODO} element={<TodoTask />} />
              <Route path={ROUTES.TASK_ALL} element={<AllTask />} />
              <Route path={ROUTES.TASK_COMPLETE} element={<CompletedTask />} />
              <Route
                path={ROUTES.TASK_IN_PROGRESS}
                element={<InProgressTask />}
              />
              <Route path={ROUTES.TASK_BACKLOG} element={<BacklogTask />} />
            </Route>
            {/* Single Project Page */}
            <Route element={<DashboardLayout showNavigationHeader={false} />}>
              <Route path={ROUTES.PROJECT} element={<ProjectPage />} />
              <Route
                path={ROUTES.PROJECTS_WIZARD_ID}
                element={<NewProjectPageById />}
              />
            </Route>

            {/* New Project Page */}
            <Route
              element={
                <DashboardLayout
                  showNavigationHeader={false}
                  isProjectContainer
                />
              }
            >
              <Route
                path={ROUTES.PROJECTS_NEW}
                element={
                  <NewProjectPage
                    notificationData={notificationData}
                    isConnected={isConnected}
                  />
                }
              />
            </Route>

            {/* Account Page */}
            {/* <Route path="account" element={<AccountPage />} /> */}
            {/* New Account Page */}
            <Route element={<AccountLayout />}>
              <Route path={ROUTES.ACCOUNT} element={<ProfilePage />} />
              <Route
                path={ROUTES.ACCOUNT_SECURITY}
                element={<SecurityPage />}
              />
              {/* <Route path={ROUTES.ACCOUNT_BILLING} element={<BillingPage />} />
             
              <Route
                path={ROUTES.ACCOUNT_COMMUNICATIONS}
                element={<CommunicationsPage />}
              /> */}
            </Route>

            {/* Help and Support Page */}
            <Route
              element={
                <DashboardLayout
                  showNavigationHeader={false}
                  isProjectContainer
                />
              }
            >
              <Route path={ROUTES.HELP} element={<HelpPage />} />
            </Route>

            <Route path="*" element={<Custom404 />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
