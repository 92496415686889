/////////////////////
// Summary Step, 
/////////////////////

// Basic Imports


// Design Imports
import {
  Box,
  HStack,
  Text,
  useCheckbox,
  UseCheckboxProps,
  useColorModeValue as mode,
  useId,
} from "@chakra-ui/react";

import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { CheckboxBox } from "./CheckboxBox";
import ButtonTooltip from "./Tooltips/ButtonTooltip";

// Layout and Section Imports

// Data Imports

// Interfaces
interface ButtonCheckboxProps extends UseCheckboxProps {
  icon: React.ReactElement;
  title: string;
  description: string;
  button: string;
  children?: React.ReactNode;
}

interface ButtonlessCheckboxProps extends UseCheckboxProps {
  icon: React.ReactElement;
  title: string;
  description: string;
  children?: React.ReactNode;
  isDisabled?: boolean
  disableText?: string
}

// Functions
export const ButtonCheckbox = (props: ButtonCheckboxProps) => {
  const { icon, title, description, button, ...rest } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } =
    useCheckbox(rest);
  const id = useId();

  return (
    <label {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <CheckboxBox
        {...getCheckboxProps()}
        icon={icon}
        description={description}
        id={id}
      >
        <HStack spacing="4">
          <Box
            data-checked={state.isChecked ? "" : undefined}
            fontSize="2xl"
            _checked={{
              color: mode("blue.500", "blue.300"),
            }}
            color={mode("gray.300", "whiteAlpha.400")}
          >
            {state.isChecked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
          </Box>
          <Box fontSize="3xl">{icon}</Box>
          <Box flex="1">
            <Text fontWeight="bold">{title}</Text>
            <Text fontSize="sm">{description}</Text>
          </Box>
          <Box fontWeight="bold" color={mode("blue.600", "blue.400")}>
            {button}
          </Box>
        </HStack>
      </CheckboxBox>
    </label>
  );
};

export const ButtonlessCheckbox = (props: ButtonlessCheckboxProps) => {
  const { icon, title, description, isDisabled, disableText, ...rest } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } = useCheckbox(
    { ...rest },
  );
  const id = useId();

  if (!!isDisabled) return (
    <ButtonTooltip label={disableText}>
    <CheckboxBox
      icon={icon}
      description={description}
      id={id}
    >
      <HStack spacing="4" cursor="not-allowed">
        <Box
          data-checked={state.isChecked ? "" : undefined}
          fontSize="2xl"
          color={"gray.100"}
        >
          <MdCheckBoxOutlineBlank />
        </Box>
        <Box fontSize="3xl" color="gray.500">{icon}</Box>
        <Box flex="1">
          <Text color="gray.500" fontWeight="bold">{title}</Text>
          <Text color="gray.500" fontSize="sm">{description}</Text>
        </Box>
      </HStack>
    </CheckboxBox>
  </ButtonTooltip>
  )

  return (
    <label {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <CheckboxBox
        {...getCheckboxProps()}
        icon={icon}
        description={description}
        id={id}
        isDisable
      >
        <HStack spacing="4">
          <Box
            data-checked={state.isChecked ? "" : undefined}
            fontSize="2xl"
            _checked={{
              color: mode("blue.500", "blue.300"),
            }}
            color={mode("gray.300", "whiteAlpha.400")}
          >
            {state.isChecked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
          </Box>
          <Box fontSize="3xl">{icon}</Box>
          <Box flex="1">
            <Text fontWeight="bold">{title}</Text>
            <Text fontSize="sm">{description}</Text>
          </Box>
        </HStack>
      </CheckboxBox>
    </label>
  );
};

export const ButtonlessChecklessCheckbox = (props: ButtonlessCheckboxProps) => {
  const { icon, title, description, isDisabled, ...rest } = props;
  const id = useId();

  return (
    // <CheckboxBox color={mode(isDisabled ? "gray.400" : "gray.600", "whiteAlpha.400")} isDisable={isDisabled} id={id}  {...rest}>
    <CheckboxBox 
      cursor={isDisabled ? "not-allowed" : "pointer"} 
      color={mode(isDisabled ? "gray.400" : "gray.600", "whiteAlpha.400")} 
      isDisable={isDisabled} 
      id={id}  
      {...rest}
    >
      <HStack spacing="4">
        <Box
          data-checked=""
          fontSize="2xl"
          color="inherit"
        ></Box>
        <Box fontSize="3xl" color="inherit">{icon}</Box>
        <Box flex="1">
          <Text fontWeight="bold" color="inherit">{title}</Text>
          <Text fontSize="sm" color="inherit">{description}</Text>
        </Box>
      </HStack>
    </CheckboxBox>
  );
};
