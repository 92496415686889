// interface IRoutes {
//   [propName: string]: string;
// }

// export const ROUTES: IRoutes = {
export const ROUTES = {
  // Dashboard Routes
  DASHBOARD: "/",
  ANALYTICS: "/analytics",
  AUTOMATIONS: "/automations",
  // Project Routes
  PROJECTS: "/projects/all",
  PROJECTS_COMPLETED: "/projects/completed",
  PROJECTS_DRAFT: "/projects/draft",
  PROJECTS_PAUSED: "/projects/paused",
  PROJECTS_ACTIVE: "/projects",
  PROJECTS_PAGE: "/projects",
  PROJECTS_DELETED: "/projects/deleted",
  PROJECTS_NEW: "/projects/new-project",
  // Single Project Route
  PROJECT: "projects/:id",
  TASK_PAGE: "/mytask",
  PROJECTS_WIZARD_ID: "/projects/new-project/:id",
  // Account Routes
  ACCOUNT: "/account",
  ACCOUNT_BILLING: "/account/billing",
  ACCOUNT_SECURITY: "/account/security",
  ACCOUNT_COMMUNICATIONS: "/account/communications",
  // Plan
  SELECT_PLAN: "/select-plan",
  HELP: "/help",
  // Auth
  LOGIN_IN: "/login",
  SIGN_UP: "/signup",
  FORGOT: "/forgot",
  ACCOUNT_VERIFY: "/account/verify",
  PASSWORD_RESET: "/account/password/reset",
};
