/////////////////////
// ProjectsLayout
/////////////////////

// Basic Imports
import { FC } from "react";
import { useLocation, Outlet } from "react-router-dom";

// Custom Hook Imports

// Design Imports
import { Stack } from "@chakra-ui/react";

// Layout and Section Imports

import PageHeader from "../ui/appPages/PageHeader";
import { TabLink } from "../ui/appPages/TabLink";
import DashboardContainer from "../sections/DashboardSection/DashboardContainer";

// Style Imports (al CSS files)

// Data Imports

// Interfaces
interface Props {
  showNavigationHeader?: boolean;
  title?: string;
  isProjectContainer?: boolean;
  navItems?: NavItemProps[];
}
interface NavItemProps {
  label: string;
  href: string;
}

const DashboardLayout: FC<Props> = ({
  showNavigationHeader = true,
  title,
  navItems,
  isProjectContainer,
}) => {
  const { pathname } = useLocation();

  return (
    <>
      {showNavigationHeader && (
        <PageHeader title={title} overflow="hidden">
          {navItems.map((item: NavItemProps, idx: number) => {
            const currentItem = pathname === item.href;

            return (
              <TabLink
                key={idx.toString()}
                href={item.href}
                aria-current={currentItem ? "page" : "false"}
              >
                {item.label}
              </TabLink>
            );
          })}
        </PageHeader>
      )}
      <DashboardContainer isProjectContainer={isProjectContainer}>
        <Stack py="4" spacing="4" overflow="auto">
          <Outlet />
        </Stack>
      </DashboardContainer>
    </>
  );
};

export default DashboardLayout;
