/**
 * All custom global style overrides
 */

import colors from "../../settings/theme/colors";

const styles = {
  global: (props: any) => {
    const fontSizes = props.theme.fontSizes;

    return {
      "html, body": {
        // color: props.colorMode === "light" ? "defaultLight" : "gray.200", // TEMP: Just removed to test either it prevents the default color in Text or not
        color: props.colorMode === "light" ? colors.light : colors.dark, // TEMP: Just removed to test either it prevents the default color in Text or not
        fontSize: fontSizes,
        // rounded: "none"
        // lineHeight: "tall",
      },

      // Eric: only used in ListItem
      ".boxText": {
        textColor: props.colorMode === "light" ? colors.light : colors.dark,
        fontSize: "lg",
      },

      ".glassBackground": {
        bg: props.colorMode === "light" ? "whiteAlpha.400" : "whiteAlpha.000",
        p: { base: "3", md: "7" },
        maxW: "2xl",
      },

      // Spacers
      ".bottomSpacer10": {
        pb: "10",
      },
      ".topSpacer10": {
        pt: "10",
      },

      // Alignment
      ".horizontalCenter": {
        maxW: { base: "xl", md: "7xl" },
        mx: "auto",
      },

      ".horizontalLeft": {
        maxW: { base: "xl", md: "7xl" },
        justifyContent: "left",
      },

      ".horizontalRight": {
        maxW: { base: "xl", md: "7xl" },
        ml: "auto",
        justifyContent: "right",
      },

      // Image fills
      ".imgFill": {
        position: "absolute",
        objectFit: "cover",
        w: "full",
        h: "full",
        bg: props.colorMode === "light" ? "defaultLight" : "defaultDark",
      },
      ".imgFlexBox": {
        bg: props.colorMode === "light" ? "defaultLight" : "defaultDark",
        position: "relative",
        flex: "1",
      },

      ".imgOverlay": {
        position: "absolute",
        objectFit: "cover",
        w: "full",
        h: "full",
        bg: props.colorMode === "light" ? "whiteAlpha.000" : "blackAlpha.500",
      },

      // Modals
      ".modal": {
        size: "4xl",
      },

      ".modalHeader": {
        textColor: props.colorMode === "light" ? "defaultLight" : "blue.400",
        textTransform: "uppercase",
        textAlign: "center",
        mt: "2",
        mb: "5",
      },
      ".modalFooter": {
        mt: "5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    };
  },
};

export default styles;
