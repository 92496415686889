import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface Props {
  title: string;
  children: ReactNode;
  count?: number;
}
const AccordionItemCustom: FC<Props> = ({ title, children, count }) => {
  return (
    <AccordionItem border="none">
      <h2>
        <AccordionButton
          bg="gray.50"
          border="1px"
          borderColor="gray.300"
          rounded="md"
        >
          <Box flex="1" textAlign="left">
            <HStack>
              <Text>{title}</Text>
              {count ? (
                <Badge
                  size="sm"
                  rounded="full"
                  variant="solid"
                  colorScheme="red"
                >
                  {count}
                </Badge>
              ) : null}
            </HStack>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb="4" px="2" rounded="lg">
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordionItemCustom;
