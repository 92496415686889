/////////////////////
// Forgot (Password) Form
/////////////////////

// Basic Imports


// Design Imports
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackProps,
} from "@chakra-ui/react";

import FormAlert from "../FormAlert";

// Layout and Section Imports
import useFormState from "../useAuthForm";
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";

// Data Imports
import { ROUTES } from "settings/constants/routes";

// Interfaces


// Custom Hooks and Services


// Functions
export const ForgotForm = (props: StackProps) => {
  const { user, setEmail, handleForgotPassword, isEmailSentForReset } = useFormState();

  if (!!isEmailSentForReset) return (
    <FormAlert 
      title="Forgot password"
      description="Please check you email, you have to click on link to redirect for reset password"
    />
  )
  return (
    <Stack spacing="8" {...props}>
      <FormHeader 
        title="Lost your password?"
        description="Already have an account?"
        link={{
          label: "Sign in",
          to: ROUTES.LOGIN_IN
        }}
      />

      <FormWrapper onSubmit={handleForgotPassword}>
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              placeholder="Enter your email"
              type="email"
              name="email"
              value={user.email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </FormControl>
        </Stack>
        <Stack spacing="4">
          <Button
            variant="primary"
            color="white"
            bg="blue.500"
            _hover={{ backgroundColor: "blue.600" }}
            type="submit"
            isLoading={user.isLoading}
          >
            Reset Password
          </Button>
        </Stack>
      </FormWrapper>
    </Stack>
  );
};
