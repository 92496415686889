/////////////////////
// Output Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { BoxProps, SimpleGrid, Stack } from "@chakra-ui/react";

// Layout and Section Imports
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";

// Data Imports
import Task from "./TaskUI/Task";
import { taskService } from "services/taskService";

// Interfaces
interface Props {
  styles: BoxProps;
  selectedOutput: any[];
  taskData: any;
  projectId?: string;
  getAsyncData: () => void;
}

// Functions

const TaskCard: FC<Props> = ({
  taskData,
  styles,
  selectedOutput,
  projectId,
  getAsyncData,
}) => {
  const outputTasks = taskService(selectedOutput, taskData);
  return (
    <Card id={projectId} {...styles}>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "4", md: "10" }}
      >
        <CardContent>
          <CardHeader title="Project tasks" action={<></>} mb={5} />
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
            {outputTasks &&
              outputTasks.length &&
              outputTasks.map((task, index) => (
                <Task task={task} key={index} getAsyncData={getAsyncData} />
              ))}
          </SimpleGrid>
        </CardContent>
      </Stack>
    </Card>
  );
};

export default TaskCard;
