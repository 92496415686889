// Interfaces
export interface StyleCardOptionProps {
  label: string;
  description: string;
  image: string;
  value: string;
  prereq: string;
}

export interface StyleCardProps {
  id: string;
  type: string;
  title: string;
  description: string;
  input: string;
  items: StyleCardOptionProps[];
}

export interface StylesDataProps {
  id: string;
  title: string;
  options: StyleCardProps[];
}

// Data
export const floorplanOptions: StyleCardProps[] = [
  {
    id: "1",
    type: "Drafting Style",
    title: "Drafting Style",
    description: "Determine how the floor plan will be drafted",
    input: "radio",
    items: [
      {
        label: "Contrast between solid & void",
        description:
          "A solid black for the walls, the most common black lines plans used for marketing",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/1_-_Drafting_Style_-_Contrast_between_solid_void_arzq1m.jpg",
        value: "contrast-between-solid-and-void",
        prereq: "",
      },
      {
        label: "Contrast with context",
        description: "Having a solid black lines plan with a background color",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/2_-_Drafting_Style_-_Contrast_with_context_i2qacn.jpg",
        value: "contrast-with-context",
        prereq: "",
      },
      {
        label: "Inverted contrast",
        description:
          "Great for dark mode settings or dark websites or brochures",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/3_-_Drafting_Style_-_Inverted_contrast_emgopa.jpg",
        value: "inverted-contrast",
        prereq: "",
      },
      {
        label: "Same line weights",
        description:
          "Black lines plan with thin lines throughout where nothing is emphasized",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/4_-_Drafting_Style_-_Same_line_weights_e17ubv.jpg",
        value: "same-line-weight",
        prereq: "",
      },
      {
        label: "Different line weights",
        description: "Stronger lines to represent the outline of the walls",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/5_-_Drafting_Style_-_Different_line_weights_rxdfgj.jpg",
        value: "different-line-weight",
        prereq: "",
      },
      {
        label: "Emphasis on cut elements",
        description:
          "Filling the walls with a texture or pattern rather than solid black",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/6_-_Drafting_Style_-_Emphasis_on_cut_elements_nk18i4.jpg",
        value: "emphasis-on-cut-elements",
        prereq: "",
      },
    ],
  },
  {
    id: "2",
    type: "Drafting Additions",
    title: "Drafting Additions",
    description: "Additional information displayed on the floor plans",
    input: "checkbox",
    items: [
      {
        label: "Room labels",
        description: "The functionality of the space",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/1_-_Drafting_Additions_-_Room_Labels_vpbn3p.jpg",
        value: "room-labels",
        prereq: "",
      },
      {
        label: "Room dimensions",
        description: "Measurements of the room",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/2_-_Drafting_Additions_-_Room_Dimensions_fn4die.jpg",
        value: "room-dimensions",
        prereq: "",
      },
      {
        label: "Gross living floor area",
        description: "Conditioned area of the building",
        image:
          "https://homerender.sfo3.cdn.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/2023.05.22-Additional%20info-%20Gross%20Living%20Floor%20Area.png",
        value: "gross-floor-area",
        prereq: "",
      },
      {
        label: "Site plan",
        description: "Site overview plan of the building",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/4_-_Drafting_Additions_-_Site_plan_vsiuzv.jpg",
        value: "site-plan",
        prereq: "",
      },
      {
        label: "Furniture",
        description: "Placement of furniture",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/5_-_Drafting_Additions_-_Furniture_aaafk9.jpg",
        value: "furniture",
        prereq: "",
      },
      {
        label: "Floor texture",
        description: "Visual cues about the type of flooring",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/6_-_Drafting_Additions_-_Floor_texture_he2vum.jpg",
        value: "floor-texture",
        prereq: "",
      },
      {
        label: "Total under roof area",
        description: "Covered area of the building under the roof",
        image:
          "https://homerender.sfo3.cdn.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/2023.05.22-Additional%20info-%20Total%20Under%20Roof%20Area.png",
        value: "total-under-roof-area",
        prereq: "",
      },
      {
        label: "Legend",
        description: "Measurements and spaces marked using legends",
        image:
          "https://homerender.sfo3.cdn.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/2023.05.22-Additional%20info-%20Legends.png",
        value: "legend",
        prereq: "",
      },
      {
        label: "None",
        description: "No text on the floor plan",
        image:
          "https://homerender.sfo3.cdn.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/2023.05.22-Additional%20info-%20None.png",
        value: "none",
        prereq: "",
      },
    ],
  },
  {
    id: "3",
    type: "Font Selection",
    title: "Font Selection",
    description: "What do you want to use as the font for your document",
    input: "radio",
    items: [
      {
        label: "Arial",
        description: "AutoCAD's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/1_-_Font_Selection_-_Arial_laihgt.png",
        value: "arial",
        prereq: "",
      },
      {
        label: "Architect",
        description: "Simulate lettering of an architect",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/2_-_Font_Selection_-_Architect_vbedk2.png",
        value: "architect",
        prereq: "",
      },
      {
        label: "Avenir Next",
        description: "Modern looking font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/3_-_Font_Selection_-_Avenir_Next_h1ub95.png",
        value: "avenir-next",
        prereq: "",
      },
      {
        label: "Calibri",
        description: "Microsoft's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/4_-_Font_Selection_-_Calibri_tganly.png",
        value: "calibri",
        prereq: "",
      },
      {
        label: "Helvetica Neue",
        description: "Apple's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/5_-_Font_Selection_-_Helvetica_Neue_qox5cv.png",
        value: "helvetica-neue",
        prereq: "",
      },
      {
        label: "Marker Felt",
        description: "Used on whiteboards",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/6_-_Font_Selection_-_Marker_Felt_qc4u0d.png",
        value: "marker-felt",
        prereq: "",
      },
    ],
  },
];

export const render3DOptions: StyleCardProps[] = [
  {
    id: "1",
    type: "Interior Design Style",
    title: "Interior Design Style",
    description: `Here are a sample of the most common interior design options available. Other design styles are available as well, just select "None" and type in the style in the Additional Style Information textbox.`,
    input: "radio",
    items: [
      {
        label: "None",
        description: "An empty home, as minimalistic as it gets.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/1_-_Interior_Design_Style_-_Empty_spyygf.jpg",
        value: "none",
        prereq: "",
      },
      {
        label: "Classic",
        description:
          "A style manifested itself in all the arts of Europe in 17-19 centuries: art, literature, music, and architecture.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/2_-_Interior_Design_Style_-_Classic_wuqm0l.jpg",
        value: "classic",
        prereq: "",
      },
      {
        label: "Contemporary",
        description:
          "When post-modernism was introduced into the art world. Represented by cultural dogma, minimalist trends, and neutral colors.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/3_-_Interior_Design_Style_-_Contemporary_ixlweo.jpg",
        value: "contemporary",
        prereq: "",
      },
      {
        label: "Farmhouse",
        description:
          "Warm and inviting, modern farmhouse takes its cues from the rural life for the ultimate mix of natural materials.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/4_-_Interior_Design_Style_-_Farmhouse_u9401z.jpg",
        value: "farmhouse",
        prereq: "",
      },
      {
        label: "Modern",
        description:
          "Big city living that take full advantage of architectural drama realized with visionary furnishings and sumptuous decor.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/5_-_Interior_Design_Style_-_Modern_fa3rje.jpg",
        value: "modern",
        prereq: "",
      },
      {
        label: "Traditional",
        description:
          "Rooted in a very individual focused lens, the main part is having peices that speak to owner's personality and lifestyle.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/6_-_Interior_Design_Style_-_Traditional_alzlpw.jpg",
        value: "traditional",
        prereq: "",
      },
      {
        label: "Transitional",
        description:
          "Combinations of tried & true décor styling utilizing traditional furnishings mixed with modern elements.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/7_-_Interior_Design_Style_-_Transitional_nkxdma.jpg",
        value: "transitional",
        prereq: "",
      },
      {
        label: "Asian",
        description:
          "Discreet, natural, or exotic vibrant, vivacious color. This style evokes an range of emotion from celebratory to tranquil calm.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/8_-_Interior_Design_Style_-_Asian_Interior_gf5zvr.jpg",
        value: "asian",
        prereq: "",
      },
      {
        label: "Industrial",
        description:
          "An urban vibe with warehouse and factory elements thrown in the mix. Think reclaimed wood, moody hues, and exposed metals.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/9_-_Interior_Design_Style_-_Industrial_aqrov4.jpg",
        value: "industrial",
        prereq: "",
      },
    ],
  },
  {
    id: "2",
    type: "Lighting Option",
    title: "Lighting Option",
    description: "The type of lighting within the home",
    input: "radio",
    items: [
      {
        label: "Natural + Artificial Lighting",
        description:
          "Both kinds of light, natural and artificial, combined together",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DRender/Lighting/1_-_Lighting_Option_-_Natural_and_Artificial_ncc7xc.jpg",
        value: "natural-and-artificial-lighting",
        prereq: "",
      },
      {
        label: "Natural Lighting",
        description:
          "Lighting that comes in via penetrations in the wall, such as doors and windows",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DRender/Lighting/2_-_Lighting_Option_-_Natural_vylhci.jpg",
        value: "natural-lighting",
        prereq: "",
      },
      {
        label: "Artificial Lighting",
        description:
          "Lighting that comes from within the home, typically wall or ceiling mounted light fixtures",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DRender/Lighting/3_-_Lighting_Option_-_Artificial_vfmxsw.jpg",
        value: "Artificial-lighting",
        prereq: "",
      },
    ],
  },
  {
    id: "3",
    type: "Time of Day",
    title: "Time of Day",
    description:
      "While this mostly affects exterior renders, it also affect light that comes in via windows.",
    input: "radio",
    items: [
      {
        label: "Afternoon",
        description:
          "Direct sun light for the scene is used to show energy and emphasize contrast.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DRender/TimeOfDay/1_-_Time_Of_Day_-_Afternoon_o4xhjy.jpg",
        value: "afternoon",
        prereq: "",
      },
      {
        label: "Morning",
        description:
          "The ambient light of the morning sky will provide a soft glow with a bit of warmth.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DRender/TimeOfDay/2_-_Time_Of_Day_-_Morning_qjhoru.jpg",
        value: "morning",
        prereq: "",
      },
      {
        label: "Twilight",
        description:
          "Lighting at dusk, used to grab attention, by highlighting features like sunsets.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DRender/TimeOfDay/3_-_Time_Of_Day_-_Twilight_cva9qe.jpg",
        value: "twilight",
        prereq: "",
      },
    ],
  },
  {
    id: "4",
    type: "Exterior Design Style",
    title: "Exterior Design Style",
    description: `Here are a sample of the most common exterior design styles available for the structure specifically. If there are no exterior designs required, you can ignore this section.`,
    input: "radio",
    items: [
      // missing 4
      {
        label: "Country",
        description:
          "The feeling from warm woods, wide porches, and white picket fences surrounded by vast plains or mountains.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorStyle/1_-_Exterior_Style_-_Country_m3bssc.jpg",
        value: "country",
        prereq: "",
      },

      {
        label: "Mediterranean",
        description:
          "Inspired by ancient historic European villages and the various styles that represent these cultures.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorStyle/2_-_Exterior_Style_-_Mediterranean_t3szh2.jpg",
        value: "mediterranean",
        prereq: "",
      },
      {
        label: "Modern",
        description:
          "Often associated with sophisticated intellectuals, these exterior styles focus on certain periods of excess.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorStyle/3_-_Exterior_Style_-_Modern_djp2t2.jpg",
        value: "modern",
        prereq: "",
      },
      {
        label: "Traditional",
        description:
          "Utilizing the craftsmanship with wood and metals from time long past to make intricate exterior design details.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorStyle/4_-_Exterior_Style_-_Traditional_lcqsiq.jpg",
        value: "traditional",
        prereq: "",
      },
      {
        label: "Tropical",
        description:
          "Relaxing ocean side vacation or zen meditation reflected into the exterior of the home. ",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorStyle/5_-_Exterior_Style_-_Tropical_b0umet.jpg",
        value: "tropical",
        prereq: "",
      },
      {
        label: "Sustainable",
        description:
          "Diving into the eco friendly, green living to showcase current need for balance between ourselves and the environment.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorStyle/6_-_Exterior_Style_-_Substainable_mfzaei.jpg",
        value: "sustainable",
        prereq: "",
      },
    ],
  },
  {
    id: "5",
    type: "Exterior Landscaping",
    title: "Exterior Landscaping",
    description: `A collection of plants to represent the nature surrounding the home. If there are no exterior landscaping required, you can ignore this section.`,
    input: "radio",
    items: [
      //missing 3
      {
        label: "Country",
        description:
          "The joyous coloring of the flower gardens, the fragrance of lavender, or a cozy place for rest on a wooden bench make country landscaping a classic choice.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/1_-_Exterior_Landscaping_-_Country_wqqjrm.jpg",
        value: "country",
        prereq: "",
      },
      {
        label: "Mediterranean",
        description:
          "Mediterranean climate leads to a more dryer temperate weather and creates a scene with tall cypress or oak trees, terracotta planters, sculpted bushes, and low grass.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/2_-_Exterior_Landscaping_-_Mediterranean_dzia1s.jpg",
        value: "mediterranean",
        prereq: "",
      },
      {
        label: "Modern",
        description:
          "Wood and stone accents, minimalistic patios, streamlined walkways, and even metal implements all play a crucial part in modern landscaping",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/3_-_Exterior_Landscaping_-_Modern_zk1ing.jpg",
        value: "modern",
        prereq: "",
      },
      {
        label: "Traditional",
        description:
          "A North America favorite. A mixture of coniferous trees, red/yellow/green bushes, and decorative grasses that border a home and pathway with the base covered in mulch.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/4_-_Exterior_Landscaping_-_Traditional_mt1xlc.jpg",
        value: "traditional",
        prereq: "",
      },
      {
        label: "Tropical",
        description:
          "Tropical gardens are usually very colorful and lush with large leaf plants, palms, and brightly-hued flowers. Great for warm coastal locations.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/5_-_Exterior_Landscaping_-_Tropical_dpbthq.jpg",
        value: "tropical",
        prereq: "",
      },
      {
        label: "Sustainable",
        description:
          "Sustainable landscapes thrive in local temperatures, rainfall, and weather patterns. This landscaping works with nature and does well with little or no human help.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/6_-_Exterior_Landscaping_-_Substainable_gqoabe.jpg",
        value: "Sustainable",
        prereq: "",
      },
    ],
  },
  {
    id: "5b",
    type: "Landscaping Subcategory",
    title: "Landscaping Subcategory",
    description: `What landscape design subcategory best suits your needs?`,
    input: "radio",
    items: [
      {
        label: "Farmhouse",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/1_-_Country_Landscaping_-_Farmhouse_utmrze.jpg",
        value: "farmhouse",
        prereq: "country",
      },
      {
        label: "Rocky Mountain",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/2_-_Country_Landscaping_-_Rocky_Mountain_dv9i8h.jpg",
        value: "rocky-mountain",
        prereq: "country",
      },
      {
        label: "Ranch",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/3_-_Country_Landscaping_-_Ranch_qxh7d6.jpg",
        value: "ranch",
        prereq: "country",
      },
      {
        label: "Rustic",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/4_-_Country_Landscaping_-_Rustic_otmj60.jpg",
        value: "rustic",
        prereq: "country",
      },
      {
        label: "Victorian",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/5_-_Country_Landscaping_-_Victorian_tvjbm3.jpg",
        value: "victorian",
        prereq: "country",
      },
      {
        label: "French",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/6_-_Mediterranean_Subcategory_-_French_oc4uc8.jpg",
        value: "french",
        prereq: "mediterranean",
      },
      {
        label: "Southwest",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/7_-_Mediterranean_Subcategory_-_Southwest_bunr0t.jpg",
        value: "southwest",
        prereq: "mediterranean",
      },
      {
        label: "Spanish",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/8_-_Mediterranean_Landscaping_-_Spanish_kuwthw.jpg",
        value: "spanish",
        prereq: "mediterranean",
      },
      {
        label: "Tuscan",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/9_-_Mediterranean_Landscaping_-_Tuscan_lvbu0u.jpg",
        value: "tuscan",
        prereq: "mediterranean",
      },
      {
        label: "Cape Cod",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/10_-_Traditional_Subcategory_-_Cape_Cod_xiuz40.jpg",
        value: "cape-cod",
        prereq: "traditional",
      },
      {
        label: "Colonial",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/11_-_Traditional_Subcategory_-_Colonial_a4wer6.jpg",
        value: "colonial",
        prereq: "traditional",
      },
      {
        label: "Craftsman",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/12_-_Traditional_Landscaping_-_Craftsman_weytk1.jpg",
        value: "craftsman",
        prereq: "traditional",
      },
      {
        label: "English",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/13_-_Traditional_Landscaping_-_English_us6bcu.jpg",
        value: "english",
        prereq: "traditional",
      },
      {
        label: "Townhouse",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/14_-_Traditional_Landscaping_-_Townhouse_tzgfed.jpg",
        value: "townhouse",
        prereq: "traditional",
      },
      {
        label: "Asian (Japanese)",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/15_-_Tropical_Subcategory_-_Asian_sglhh7.jpg",
        value: "asian",
        prereq: "tropical",
      },
      {
        label: "Coastal",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/16_-_Tropical_Subcategory_-_Coastal_ths1mp.jpg",
        value: "coastal",
        prereq: "tropical",
      },
      {
        label: "South Pacific",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/17_-_Tropical_Landscaping_-_South_Pacific_kxpjyf.jpg",
        value: "south-pacific",
        prereq: "tropical",
      },
      {
        label: "Art Deco",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/18_-_Modern_Subcategory_-_Art_Deco_vlvi2i.jpg",
        value: "art-deco",
        prereq: "modern",
      },
      {
        label: "Mid Century",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/19_-_Modern_Subcategory_-_Mid_Century_rexrhm.jpg",
        value: "mid-century",
        prereq: "modern",
      },
      {
        label: "Modern",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/20_-_Modern_Subcategory_-_Modern_gdp5ny.jpg",
        value: "modern",
        prereq: "modern",
      },
    ],
  },
  {
    id: "6",
    type: "Color Style",
    title: "Color Style",
    description: `A collection of preset color palettes from Sherwin Williams, the largest paint and coating manufacturer in North America.`,
    input: "radio",
    items: [
      {
        label: "My Own Colors",
        description:
          "List your own colors in the ADDITIONAL STYLE INFORMATION section below.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ColorStyles/My_Own_Colors_gvidin.jpg",
        value: "my-own-colors",
        prereq: "",
      },
    ],
  },
];

export const landscapeOptions: StyleCardProps[] = [
  {
    id: "1",
    type: "Landscaping plan",
    title: "Landscaping plan",
    description: "Information needed through the landscape design",
    input: "checkbox",
    items: [
      {
        label: "Landscaping layout",
        description: "Site layout representing location and design of the lot",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandspacePlan/1_-_Landscaping_Plan_-_Landscape_Layout_j9tsao.jpg",
        value: "landscaping-layout",
        prereq: "",
      },
      {
        label: "Plant schedule",
        description:
          "Schedule of the plants showing Botanical name, type, size of the plants/trees",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandspacePlan/2_-_Landscaping_Plan_-_Plant_Schedule_yu0em7.jpg",
        value: "plant-schedule",
        prereq: "",
      },
      {
        label: "Plant palette",
        description:
          "Photo gallery or photo palette of the different plants used",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandspacePlan/3_-_Landscaping_Plan_-_Plant_palette_fqwtih.jpg",
        value: "plant-palette",
        prereq: "",
      },
      {
        label: "Form and average size",
        description: "The shape and size of a mature plant",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandspacePlan/4_-_Landscaping_Plan_-_Form_and_average_size_iwsusw.jpg",
        value: "form-average-size",
        prereq: "",
      },
      {
        label: "Species Information",
        description: "Biological description of the plant species",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandspacePlan/5_-_Landscaping_Plan_-_Species_Information_ylzza7.jpg",
        value: "species-information",
        prereq: "",
      },
      {
        label: "Planting details",
        description: "Detail description of the planting",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandspacePlan/6_-_Landscaping_Plan_-_Planting_details_d5gz4j.jpg",
        value: "planting-details",
        prereq: "",
      },
    ],
  },
  {
    id: "2",
    type: "Exterior Landscaping",
    title: "Exterior Landscaping",
    description: `A collection of plants to represent the nature surrounding the home.`,
    input: "radio",
    items: [
      //missing 3
      {
        label: "Country",
        description:
          "The joyous coloring of the flower gardens, the fragrance of lavender, or a cozy place for rest on a wooden bench make country landscaping a classic choice.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/1_-_Exterior_Landscaping_-_Country_wqqjrm.jpg",
        value: "country",
        prereq: "",
      },

      {
        label: "Mediterranean",
        description:
          "Mediterranean climate leads to a more dryer temperate weather and creates a scene with tall cypress or oak trees, terracotta planters, sculpted bushes, and low grass.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/2_-_Exterior_Landscaping_-_Mediterranean_dzia1s.jpg",
        value: "mediterranean",
        prereq: "",
      },
      {
        label: "Modern",
        description:
          "Wood and stone accents, minimalistic patios, streamlined walkways, and even metal implements all play a crucial part in modern landscaping",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/3_-_Exterior_Landscaping_-_Modern_zk1ing.jpg",
        value: "modern",
        prereq: "",
      },
      {
        label: "Traditional",
        description:
          "A North America favorite. A mixture of coniferous trees, red/yellow/green bushes, and decorative grasses that border a home and pathway covered in mulch.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/4_-_Exterior_Landscaping_-_Traditional_mt1xlc.jpg",
        value: "traditional",
        prereq: "",
      },
      {
        label: "Tropical",
        description:
          "Tropical gardens are usually very colorful and lush with large leaf plants, palms, and brightly-hued flowers. Great for warm coastal locations.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/5_-_Exterior_Landscaping_-_Tropical_dpbthq.jpg",
        value: "tropical",
        prereq: "",
      },
      {
        label: "Sustainable",
        description:
          "Sustainable landscapes thrive in local temperatures, rainfall, and weather patterns. This landscaping works with nature and does well with little or no human help.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ExteriorLandscape/6_-_Exterior_Landscaping_-_Substainable_gqoabe.jpg",
        value: "sustainable",
        prereq: "",
      },
    ],
  },
  {
    id: "3",
    type: "Landscaping Subcategory",
    title: "Landscaping Subcategory",
    description: `What landscape design subcategory best suits your needs?`,
    input: "radio",
    items: [
      {
        label: "Farmhouse",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/1_-_Country_Landscaping_-_Farmhouse_utmrze.jpg",
        value: "farmhouse",
        prereq: "country",
      },
      {
        label: "Rocky Mountain",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/2_-_Country_Landscaping_-_Rocky_Mountain_dv9i8h.jpg",
        value: "rocky-mountain",
        prereq: "country",
      },
      {
        label: "Ranch",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/3_-_Country_Landscaping_-_Ranch_qxh7d6.jpg",
        value: "ranch",
        prereq: "country",
      },
      {
        label: "Rustic",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/4_-_Country_Landscaping_-_Rustic_otmj60.jpg",
        value: "rustic",
        prereq: "country",
      },
      {
        label: "Victorian",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/5_-_Country_Landscaping_-_Victorian_tvjbm3.jpg",
        value: "victorian",
        prereq: "country",
      },
      {
        label: "French",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/6_-_Mediterranean_Subcategory_-_French_oc4uc8.jpg",
        value: "french",
        prereq: "mediterranean",
      },
      {
        label: "Southwest",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/7_-_Mediterranean_Subcategory_-_Southwest_bunr0t.jpg",
        value: "southwest",
        prereq: "mediterranean",
      },
      {
        label: "Spanish",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/8_-_Mediterranean_Landscaping_-_Spanish_kuwthw.jpg",
        value: "spanish",
        prereq: "mediterranean",
      },
      {
        label: "Tuscan",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/9_-_Mediterranean_Landscaping_-_Tuscan_lvbu0u.jpg",
        value: "tuscan",
        prereq: "mediterranean",
      },
      {
        label: "Cape Cod",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/10_-_Traditional_Subcategory_-_Cape_Cod_xiuz40.jpg",
        value: "cape-cod",
        prereq: "traditional",
      },
      {
        label: "Colonial",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/11_-_Traditional_Subcategory_-_Colonial_a4wer6.jpg",
        value: "colonial",
        prereq: "traditional",
      },
      {
        label: "Craftsman",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/12_-_Traditional_Landscaping_-_Craftsman_weytk1.jpg",
        value: "craftsman",
        prereq: "traditional",
      },
      {
        label: "English",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/13_-_Traditional_Landscaping_-_English_us6bcu.jpg",
        value: "english",
        prereq: "traditional",
      },
      {
        label: "Townhouse",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/14_-_Traditional_Landscaping_-_Townhouse_tzgfed.jpg",
        value: "townhouse",
        prereq: "traditional",
      },
      {
        label: "Asian (Japanese)",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/15_-_Tropical_Subcategory_-_Asian_sglhh7.jpg",
        value: "asian",
        prereq: "tropical",
      },
      {
        label: "Coastal",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/16_-_Tropical_Subcategory_-_Coastal_ths1mp.jpg",
        value: "coastal",
        prereq: "tropical",
      },
      {
        label: "South Pacific",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/17_-_Tropical_Landscaping_-_South_Pacific_kxpjyf.jpg",
        value: "south-pacific",
        prereq: "tropical",
      },
      {
        label: "Art Deco",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/18_-_Modern_Subcategory_-_Art_Deco_vlvi2i.jpg",
        value: "art-deco",
        prereq: "modern",
      },
      {
        label: "Mid Century",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/19_-_Modern_Subcategory_-_Mid_Century_rexrhm.jpg",
        value: "mid-century",
        prereq: "modern",
      },
      {
        label: "Modern",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandscapeSubcategory/20_-_Modern_Subcategory_-_Modern_gdp5ny.jpg",
        value: "modern",
        prereq: "modern",
      },
    ],
  },
  {
    id: "4",
    type: "Font Selection",
    title: "Font Selection",
    description: "What do you want to use as the font for your document",
    input: "radio",
    items: [
      {
        label: "Arial",
        description: "AutoCAD's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/1_-_Font_Selection_-_Arial_laihgt.png",
        value: "arial",
        prereq: "",
      },
      {
        label: "Architect",
        description: "Simulate lettering of an architect",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/2_-_Font_Selection_-_Architect_vbedk2.png",
        value: "architect",
        prereq: "",
      },
      {
        label: "Avenir Next",
        description: "Modern looking font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/3_-_Font_Selection_-_Avenir_Next_h1ub95.png",
        value: "avenir-next",
        prereq: "",
      },
      {
        label: "Calibri",
        description: "Microsoft's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/4_-_Font_Selection_-_Calibri_tganly.png",
        value: "calibri",
        prereq: "",
      },
      {
        label: "Helvetica Neue",
        description: "Apple's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/5_-_Font_Selection_-_Helvetica_Neue_qox5cv.png",
        value: "helvetica-neue",
        prereq: "",
      },
      {
        label: "Marker Felt",
        description: "Used on whiteboards",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/6_-_Font_Selection_-_Marker_Felt_qc4u0d.png",
        value: "marker-felt",
        prereq: "",
      },
    ],
  },
];

export const floorplan3DOptions: StyleCardProps[] = [
  {
    id: "1",
    type: "Interior Design Style",
    title: "Interior Design Style",
    description: `Here are a sample of the most common interior design options available. Other design styles are available as well, just select "None" and type in the style in the Additional Style Information textbox.`,
    input: "radio",
    items: [
      {
        label: "None",
        description: "An empty home, as minimalistic as it gets.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/1_-_Interior_Design_Style_-_Empty_spyygf.jpg",
        value: "none",
        prereq: "",
      },
      {
        label: "Classic",
        description:
          "A style manifested itself in all the arts of Europe in 17-19 centuries: art, literature, music, and architecture.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/2_-_Interior_Design_Style_-_Classic_wuqm0l.jpg",
        value: "classic",
        prereq: "",
      },
      {
        label: "Contemporary",
        description:
          "When post-modernism was introduced into the art world. Represented by cultural dogma, minimalist trends, and neutral colors.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/3_-_Interior_Design_Style_-_Contemporary_ixlweo.jpg",
        value: "contemporary",
        prereq: "",
      },
      {
        label: "Farmhouse",
        description:
          "Warm and inviting, modern farmhouse takes its cues from the rural life for the ultimate mix of natural materials.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/4_-_Interior_Design_Style_-_Farmhouse_u9401z.jpg",
        value: "farmhouse",
        prereq: "",
      },
      {
        label: "Modern",
        description:
          "Big city living that take full advantage of architectural drama realized with visionary furnishings and sumptuous decor.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/5_-_Interior_Design_Style_-_Modern_fa3rje.jpg",
        value: "modern",
        prereq: "",
      },
      {
        label: "Traditional",
        description:
          "Rooted in a very individual focused lens, the main part is having peices that speak to owner's personality and lifestyle.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/6_-_Interior_Design_Style_-_Traditional_alzlpw.jpg",
        value: "traditional",
        prereq: "",
      },
      {
        label: "Transitional",
        description:
          "Combinations of tried & true décor styling utilizing traditional furnishings mixed with modern elements.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/7_-_Interior_Design_Style_-_Transitional_nkxdma.jpg",
        value: "transitional",
        prereq: "",
      },
      {
        label: "Asian",
        description:
          "Discreet, natural, or exotic vibrant, vivacious color. This style evokes an range of emotion from celebratory to tranquil calm.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/8_-_Interior_Design_Style_-_Asian_Interior_gf5zvr.jpg",
        value: "asian",
        prereq: "",
      },
      {
        label: "Industrial",
        description:
          "An urban vibe with warehouse and factory elements thrown in the mix. Think reclaimed wood, moody hues, and exposed metals.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/9_-_Interior_Design_Style_-_Industrial_aqrov4.jpg",
        value: "industrial",
        prereq: "",
      },
    ],
  },
  {
    id: "5",
    type: "Color Style",
    title: "Color Style",
    description: `A collection of preset color palettes from Sherwin Williams, the largest paint and coating manufacturer in North America.`,
    input: "radio",
    items: [
      {
        label: "My Own Colors",
        description:
          "List your own colors in the ADDITIONAL STYLE INFORMATION section below.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/ColorStyles/My_Own_Colors_gvidin.jpg",
        value: "my-own-colors",
        prereq: "",
      },
    ],
  },
];

export const virtualStagingOptions: StyleCardProps[] = [
  {
    id: "1",
    type: "Interior Design Style",
    title: "Interior Design Style",
    description: `Here are a sample of the most common interior design options available. Other design styles are available as well, just select "None" and type in the style in the Additional Style Information textbox. Also, if there are checkbox designs let us know in the Additional Style Information as well.`,
    input: "radio",
    items: [
      {
        label: "Classic",
        description:
          "A style manifested itself in all the arts of Europe in 17-19 centuries: art, literature, music, and architecture.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/2_-_Interior_Design_Style_-_Classic_wuqm0l.jpg",
        value: "classic",
        prereq: "",
      },
      {
        label: "Contemporary",
        description:
          "When post-modernism was introduced into the art world. Represented by cultural dogma, minimalist trends, and neutral colors.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/3_-_Interior_Design_Style_-_Contemporary_ixlweo.jpg",
        value: "contemporary",
        prereq: "",
      },
      {
        label: "Farmhouse",
        description:
          "Warm and inviting, modern farmhouse takes its cues from the rural life for the ultimate mix of natural materials.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/4_-_Interior_Design_Style_-_Farmhouse_u9401z.jpg",
        value: "farmhouse",
        prereq: "",
      },
      {
        label: "Modern",
        description:
          "Big city living that take full advantage of architectural drama realized with visionary furnishings and sumptuous decor.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/5_-_Interior_Design_Style_-_Modern_fa3rje.jpg",
        value: "modern",
        prereq: "",
      },
      {
        label: "Traditional",
        description:
          "Rooted in a very individual focused lens, the main part is having peices that speak to owner's personality and lifestyle.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/6_-_Interior_Design_Style_-_Traditional_alzlpw.jpg",
        value: "traditional",
        prereq: "",
      },
      {
        label: "Transitional",
        description:
          "Combinations of tried & true décor styling utilizing traditional furnishings mixed with modern elements.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/7_-_Interior_Design_Style_-_Transitional_nkxdma.jpg",
        value: "transitional",
        prereq: "",
      },
      {
        label: "Asian",
        description:
          "Discreet, natural, or exotic vibrant, vivacious color. This style evokes an range of emotion from celebratory to tranquil calm.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/8_-_Interior_Design_Style_-_Asian_Interior_gf5zvr.jpg",
        value: "asian",
        prereq: "",
      },
      {
        label: "Industrial",
        description:
          "An urban vibe with warehouse and factory elements thrown in the mix. Think reclaimed wood, moody hues, and exposed metals.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/9_-_Interior_Design_Style_-_Industrial_aqrov4.jpg",
        value: "industrial",
        prereq: "",
      },
    ],
  },
];

export const model3DOptions: StyleCardProps[] = [
  {
    id: "1",
    type: "3D Model Description",
    title: "3D Model Description",
    description: `Please tell us all the details of what you want for your 3D Model.`,
    input: "radio",
    items: [
      //missing 3
      {
        label: "Product",
        description:
          "Create a product prototype to evaluate its design concept, details, manufacturing costs, etc",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/1_-_3D_Model_-_Product_kst7lp.jpg",
        value: "product",
        prereq: "",
      },
      {
        label: "Furniture",
        description:
          "Movable objects intended to support various human activities such as seating, eating, and sleeping",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/2_-_3D_Model_-_Furniture_xs9pef.jpg",
        value: "furniture",
        prereq: "",
      },
      {
        label: "Other",
        description:
          "Place additional information in the ADDITIONAL STYLE INFORMATION input field below",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/3_-_3D_Model_-_Other_n5vyvu.jpg",
        value: "other",
        prereq: "",
      },
    ],
  },
  {
    id: "2",
    type: "Glass Materials Style",
    title: "Glass Materials Style",
    description: `If there is glass (or even plastic) used within your model, please check off the glass material style.`,
    input: "checkbox",
    items: [
      {
        label: "None",
        description: "There is no glass material used in the model",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Glass/1_-_Glass_-_None_hye0nc.jpg",
        value: "none",
        prereq: "",
      },
      {
        label: "Clear Glass",
        description: "Non-tempered clear glass",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Glass/2_-_Glass_-_Clear_Glass_uqi2vs.jpg",
        value: "clear-glass",
        prereq: "",
      },
      {
        label: "Tinted Glass",
        description: "Clear glass with a blue tint to resemble tempered glass",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Glass/3_-_Glass_-_Tinted_Glass_yrsssq.jpg",
        value: "tinted-glass",
        prereq: "",
      },
      {
        label: "Frosted Glass",
        description: "An opaque glass that has a glipse of what is hidden",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Glass/4_-_Glass_-_Frosted_Glass_dbbrnm.jpg",
        value: "frosted-glass",
        prereq: "",
      },
      {
        label: "Glossy White",
        description: "Shiny white to akin to ceramic dishes",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Glass/5_-_Glass_-_Glossy_White_dbcine.jpg",
        value: "glossy-white",
        prereq: "",
      },
      {
        label: "Matte White",
        description: "A matte white glass that is commonly used for candles",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Glass/6_-_Glass_-_Matte_White_owovcb.jpg",
        value: "matte-white",
        prereq: "",
      },
      {
        label: "Customized Glass",
        description: "The glass could be multi-colored or non-standard design",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Glass/7_-_Glass_-_Customized_Glass_t4etee.jpg",
        value: "custom-glass",
        prereq: "",
      },
    ],
  },
  {
    id: "3",
    type: "Metal Materials Style",
    title: "Metal Materials Style",
    description: `If there is Metal used within your model, please check off the Metal material style.`,
    input: "checkbox",
    items: [
      {
        label: "None",
        description: "There is no metal material used in the model",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Metal/1_-_Metal_-_None_gjnpdv.jpg",
        value: "none",
        prereq: "",
      },
      {
        label: "Brushed Nickel",
        description:
          "A standard in construction for fixtures like doorknobs and faucets",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Metal/2_-_Metal_-_Brushed_Nickel_ykpnk5.jpg",
        value: "brushed-nickle",
        prereq: "",
      },
      {
        label: "Chrome",
        description: "Typically used for high end construction fixtures",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Metal/3_-_Metal_-_Chrome_zrqgzh.jpg",
        value: "chrome",
        prereq: "",
      },
      {
        label: "Stainless Steel",
        description:
          "Commonly used for appliances, sheet metal, and kitchen sinks",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Metal/4_-_Metal_-_Stainless_Steel_vy0kdh.jpg",
        value: "stainless-steel",
        prereq: "",
      },
      {
        label: "Matte Black",
        description: "A contemporary style that puts a muted black tone ",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Metal/5_-_Metal_-_Matte_Black_h94sdm.jpg",
        value: "matte-black",
        prereq: "",
      },
      {
        label: "Gold",
        description:
          "Luxurious precious metal used in very high end applications",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Metal/6_-_Metal_-_Gold_q3ta1p.jpg",
        value: "gold",
        prereq: "",
      },
      {
        label: "Antique Brass",
        description:
          "Associated with metal products that have an timeless feel to it",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Metal/7_-_Metal_-_Antique_Brass_reb4sn.jpg",
        value: "antique-brass",
        prereq: "",
      },
    ],
  },
  {
    id: "4",
    type: "Stone Materials Style",
    title: "Stone Materials Style",
    description: `If there is Stone used within your model, please check off the Stone material style.`,
    input: "checkbox",
    items: [
      {
        label: "None",
        description: "There is no stone material used in the model",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Stone/1_-_Stone_-_None_q3bgyk.jpg",
        value: "none",
        prereq: "",
      },
      {
        label: "White Marble",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Stone/2_-_Stone_-_White_Marble_wqflai.jpg",
        value: "white-marble",
        prereq: "",
      },
      {
        label: "White Granite",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Stone/3_-_Stone_-_White_Granite_q5n41l.jpg",
        value: "white-granite",
        prereq: "",
      },
      {
        label: "Terrazzo",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Stone/4_-_Stone_-_Terrazzo_wgof6m.jpg",
        value: "terrazzo",
        prereq: "",
      },
      {
        label: "Travertine",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Stone/5_-_Stone_-_Travertine_gxgo5q.jpg",
        value: "travertine",
        prereq: "",
      },
      {
        label: "Grey Marble",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Stone/6_-_Stone_-_Grey_Marble_qru1fi.jpg",
        value: "grey-marble",
        prereq: "",
      },
      {
        label: "Brown Granite",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Stone/7_-_Stone_-_Brown_Granite_jx83rv.jpg",
        value: "brown-granite",
        prereq: "",
      },
      {
        label: "Black Marble",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Stone/8_-_Stone_-_Black_Marble_cvaoes.jpg",
        value: "black-marble",
        prereq: "",
      },
      {
        label: "Black Granite",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Stone/9_-_Stone_-_Black_Granite_cn12cm.jpg",
        value: "black-granite",
        prereq: "",
      },
    ],
  },
  {
    id: "5",
    type: "Wood Materials Style",
    title: "Wood Materials Style",
    description: `If there is Wood used within your model, please check off the Wood material style.`,
    input: "checkbox",
    items: [
      {
        label: "None",
        description: "There is no wood material used in the model",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Wood/1_-_Wood_-_None_xvkdor.jpg",
        value: "none",
        prereq: "",
      },
      {
        label: "Birch",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Wood/2_-_Wood_-_Birch_z93moe.jpg",
        value: "birch",
        prereq: "",
      },
      {
        label: "Pine",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Wood/3_-_Wood_-_Pine_gwn15o.jpg",
        value: "pine",
        prereq: "",
      },
      {
        label: "Oak",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Wood/4_-_Wood_-_Oak_eccmej.jpg",
        value: "oak",
        prereq: "",
      },
      {
        label: "Dark Oak",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Wood/5_-_Wood_-_Dark_Oak_scarp7.jpg",
        value: "dark-oak",
        prereq: "",
      },
      {
        label: "Red Mahogany",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Wood/6_-_Wood_-_Red_Mahogany_ugts5l.jpg",
        value: "red-mahogany",
        prereq: "",
      },
      {
        label: "Wedge",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/Wood/7_-_Wood_-_Wedge_mrwkll.jpg",
        value: "Wedge",
        prereq: "",
      },
    ],
  },
];

export const brochureOptions: StyleCardProps[] = [
  {
    id: "1",
    type: "Brochure Type",
    title: "Brochure Type",
    description: "Determine what type of marketing brochure you want to create",
    input: "radio",
    items: [
      {
        label: "Single Sheet",
        description: "A single sided or double sided brochure",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/Brochure/1_-_Type_-_Single_Sheet_v0iegz.png",
        value: "single-sheet",
        prereq: "",
      },
      {
        label: "Booklet",
        description: "A multipage brochure",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/Brochure/2_-_Type_-_Booklet_f09r6s.png",
        value: "booklet",
        prereq: "",
      },
    ],
  },
  {
    id: "2",
    type: "Brochure Template",
    title: "Brochure Template",
    description: "Please select the template you want to use for your brochure",
    input: "radio",
    items: [
      {
        label: "Beautiful Home For Sale",
        description:
          "Home for sale marketing page with a spot for property features, offer price, and about us section. Uses 4 images.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/SingleSheet/1_-_Template_-_217325594_dpuekp.jpg",
        value: "template-217325594",
        prereq: "single-sheet",
      },
      {
        label: "Your Story Begins",
        description:
          "Home is where your story begins, with place for logo, discount, starting from price, about the property, and property features. Uses 6 images.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/SingleSheet/2_-_Template_-_253418563_cdtj5z.jpg",
        value: "template-253418563",
        prereq: "single-sheet",
      },
      {
        label: "Dream Home For Sale",
        description:
          "A classic marketing page that displays the starting price, about our property, and property features. Uses 4 images.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/SingleSheet/3_-_Template_-_264474701_nrvjm2.jpg",
        value: "template-264474701",
        prereq: "single-sheet",
      },
      {
        label: "Floor Plan",
        description:
          "A single-page brochure with your floor plan images. It can have a paragraph description or a legend or other information. You can also add your logo and give your floor plan a name.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/SingleSheet/10_-_Custom_-_Floor_Plan_vjtivc.jpg",
        value: "custom_floor_plan",
        prereq: "single-sheet",
      },
      {
        label: "New Homes",
        description:
          "A two page (front and back) brochure. Or use each page individiually with a description area and feature list. Uses 3 image on page 1, uses 4 images on page 2.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/SingleSheet/5_-_Template_-_321554620_t9qtbk.jpg",
        value: "template-321554620",
        prereq: "single-sheet",
      },
      {
        label: "Open House",
        description:
          "An open house sign showing hours, pricing, a brief description, and dimensions. Uses 7 images.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/SingleSheet/6_-_Template_-_365316666_ttnu6g.jpg",
        value: "template-365316666",
        prereq: "single-sheet",
      },
      {
        label: "For Sale",
        description:
          "Single page for sale marketing page that has a section for the property and property features. Uses 5 images.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/SingleSheet/7_-_Template_-_461336819_kbxqyv.jpg",
        value: "template-461336819",
        prereq: "single-sheet",
      },
      {
        label: "Mutliple Home Listing",
        description:
          "Another version of Home Is Where Your Story Begins, but showcasing three homes instead of one. Uses 8 images.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/SingleSheet/8_-_Template_-_269035320-2_cyaqzv.png",
        value: "template-269035320-2",
        prereq: "single-sheet",
      },
      {
        label: "Quarterly Market Report",
        description:
          "Provide a list of active/sold properties based on address, bed/bath, sq ft, and price. Uses 2 images.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/SingleSheet/9_-_Template_-_251420315_ybnazp.jpg",
        value: "template-251420315",
        prereq: "single-sheet",
      },
      {
        label: "Project Showcase Brochure",
        description:
          "Can be used as a close up on a certain project, a series of projects, or the company as a whole.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/Booklet/1_-_Booklet_-_224220830_zebqli.jpg",
        value: "booklet-224220830",
        prereq: "booklet",
      },
      {
        label: "Real Estate Brochure",
        description:
          "A clean crisp brochure to show off that portfolio, company, or product.",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/MarketingBrochure/Booklet/2_-_Booklet_-_350352118_wg7xgk.jpg",
        value: "booklet-350352118",
        prereq: "booklet",
      },
    ],
  },
  {
    id: "3",
    type: "Font Selection",
    title: "Font Selection",
    description: "What do you want to use as the font for your document",
    input: "radio",
    items: [
      {
        label: "Arial",
        description: "AutoCAD's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/1_-_Font_Selection_-_Arial_laihgt.png",
        value: "arial",
        prereq: "",
      },
      {
        label: "Architect",
        description: "Simulate lettering of an architect",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/2_-_Font_Selection_-_Architect_vbedk2.png",
        value: "architect",
        prereq: "",
      },
      {
        label: "Avenir Next",
        description: "Modern looking font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/3_-_Font_Selection_-_Avenir_Next_h1ub95.png",
        value: "avenir-next",
        prereq: "",
      },
      {
        label: "Calibri",
        description: "Microsoft's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/4_-_Font_Selection_-_Calibri_tganly.png",
        value: "calibri",
        prereq: "",
      },
      {
        label: "Helvetica Neue",
        description: "Apple's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/5_-_Font_Selection_-_Helvetica_Neue_qox5cv.png",
        value: "helvetica-neue",
        prereq: "",
      },
      {
        label: "Marker Felt",
        description: "Used on whiteboards",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/6_-_Font_Selection_-_Marker_Felt_qc4u0d.png",
        value: "marker-felt",
        prereq: "",
      },
    ],
  },
];

export const specSheetOptions: StyleCardProps[] = [
  {
    id: "1",
    type: "Feature Sheet Type",
    title: "Feature Sheet Type",
    description:
      "Determine what type of specification or feature sheet you want to create.",
    input: "radio",
    items: [
      {
        label: "Bulleted List",
        description:
          "A marketing page that has a bulleted list of features in one to three columns",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/SpecSheet/1_-_Type_-_Bulleted_List_ohft20.png",
        value: "bulleted-list",
        prereq: "",
      },
      {
        label: "Image Showcase",
        description:
          "A marketing page with sections with an image and image description",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/SpecSheet/2_-_Type_-_Image_Showcase_aej2q7.png",
        value: "image-showcase",
        prereq: "",
      },
    ],
  },
  {
    id: "2",
    type: "Number of Columns",
    title: "Number of Columns",
    description:
      "How many columns do you want on a page. If you have less content, use less columns. More content will use more columns.",
    input: "radio",
    items: [
      {
        label: "One",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/SpecSheet/1_-_Column_-_One_h909ys.png",
        value: "one-column",
        prereq: "",
      },
      {
        label: "Two",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/SpecSheet/2_-_Columns_-_Two_ypaol4.png",
        value: "two-column",
        prereq: "",
      },
      {
        label: "Three",
        description: "",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/SpecSheet/3_-_Columns_-_Three_tel6xv.png",
        value: "three-column",
        prereq: "",
      },
    ],
  },
  {
    id: "3",
    type: "Font Selection",
    title: "Font Selection",
    description: "What do you want to use as the font for your document",
    input: "radio",
    items: [
      {
        label: "Arial",
        description: "AutoCAD's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/1_-_Font_Selection_-_Arial_laihgt.png",
        value: "arial",
        prereq: "",
      },
      {
        label: "Architect",
        description: "Simulate lettering of an architect",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/2_-_Font_Selection_-_Architect_vbedk2.png",
        value: "architect",
        prereq: "",
      },
      {
        label: "Avenir Next",
        description: "Modern looking font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/3_-_Font_Selection_-_Avenir_Next_h1ub95.png",
        value: "avenir-next",
        prereq: "",
      },
      {
        label: "Calibri",
        description: "Microsoft's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/4_-_Font_Selection_-_Calibri_tganly.png",
        value: "calibri",
        prereq: "",
      },
      {
        label: "Helvetica Neue",
        description: "Apple's default font",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/5_-_Font_Selection_-_Helvetica_Neue_qox5cv.png",
        value: "helvetica-neue",
        prereq: "",
      },
      {
        label: "Marker Felt",
        description: "Used on whiteboards",
        image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/FontSelection/6_-_Font_Selection_-_Marker_Felt_qc4u0d.png",
        value: "marker-felt",
        prereq: "",
      },
    ],
  },
];

export const styleCardsData: StylesDataProps[] = [
  {
    id: "1",
    title: "2D Floor Plan",
    options: floorplanOptions,
  },
  {
    id: "2",
    title: "3D Floor Plan",
    options: floorplan3DOptions,
  },
  {
    id: "3",
    title: "3D Render",
    options: render3DOptions,
  },
  {
    id: "4",
    title: "3D Model",
    options: model3DOptions,
  },
  {
    id: "5",
    title: "Virtual Staging",
    options: virtualStagingOptions,
  },
  {
    id: "6",
    title: "Landscape Plan",
    options: landscapeOptions,
  },
  {
    id: "7",
    title: "Marketing Brochure",
    options: brochureOptions,
  },
  {
    id: "8",
    title: "Specification / Feature Sheet",
    options: specSheetOptions,
  },
  {
    id: "9",
    title: "Other",
    options: [],
  },
];


export const styleCardsForTask: any[] = [
  {
    id: "1",
    title: "2D Floor Plan",
     image:"https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/1_-_Drafting_Style_-_Contrast_between_solid_void_arzq1m.jpg",
  },
  {
    id: "2",
    title: "3D Floor Plan",
   image: "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/1_-_Interior_Design_Style_-_Empty_spyygf.jpg",
  },
  {
    id: "3",
    title: "3D Render",
   image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/1_-_Interior_Design_Style_-_Empty_spyygf.jpg",
  },
  {
    id: "4",
    title: "3D Model",
    image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/1_-_3D_Model_-_Product_kst7lp.jpg",
  },
  {
    id: "5",
    title: "Virtual Staging",
    image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/2_-_Interior_Design_Style_-_Classic_wuqm0l.jpg",
  },
  {
    id: "6",
    title: "Landscape Plan",
    image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandspacePlan/1_-_Landscaping_Plan_-_Landscape_Layout_j9tsao.jpg",
  },
  {
    id: "7",
    title: "Marketing Brochure",
    image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/Brochure/1_-_Type_-_Single_Sheet_v0iegz.png",
  },
  {
    id: "8",
    title: "Specification / Feature Sheet",
    image:
          "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/SpecSheet/1_-_Type_-_Bulleted_List_ohft20.png",
  },
  {
    id: "9",
    title: "Other",
    options: [],
  },
];
