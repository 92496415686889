/////////////////////
// Style Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC, useState, useEffect } from "react";

// Design Imports
import { SimpleGrid } from "@chakra-ui/react";

// Layout and Section Imports
import { StyleCardForSteps } from "components/ui/common/StyleCardForSteps";
import { useProject } from "context/project/ProjectProvider";

// Data Imports

// Custom Hooks and Services
import {
  updateProjectWizardAssetsByPrereq,
  update_selected_styles_by_selected_assets,
} from "utils/projectWizardUtils";

// Interfaces
interface Props {
  steps?: any[];
  prevStep?: () => any;
  nextStep?: () => any;
  showSelectedOnly?: boolean;
  onSelected?: (selectedItems: any[]) => void;
  onStepClick?: () => void;
  isReadOnly?: boolean;
}

// Functions
const StyleStep: FC<Props> = ({ showSelectedOnly, isReadOnly }) => {
  const [selectedAssets, setSelectedAssets] = useState([]);
  const { projectWizard, onAddSelectedStyles } = useProject();
  const selectedStyles = projectWizard.selectedStyles;

  useEffect(() => {
    const selectedWizardAssets = projectWizard.selectedAssets;

    // If there is no selected style then do default selections
    if (selectedStyles.length === 0) {
      setSelectedAssets(selectedWizardAssets);
      const updatedStyles = update_selected_styles_by_selected_assets(
        selectedStyles,
        selectedWizardAssets
      );
      onAddSelectedStyles(updatedStyles);
      return;
    }

    // Updating the local assets from projectWizardAssets along with filteration on the basis of prereq
    let latestSelectedAssets = [...projectWizard.selectedAssets];
    selectedStyles?.forEach((selectedStyle) => {
      let newAssets = [...latestSelectedAssets];
      selectedStyle.types.forEach((styleType) => {
        const newSelectedAssets = updateProjectWizardAssetsByPrereq({
          prevAssets: projectWizard.selectedAssets,
          selectedAssets: newAssets,
          currAssetTitle: selectedStyle.title,
          currSelectedOption: { ...styleType, items: styleType.selectedItems },
        });
        newAssets = [...newSelectedAssets];
      });

      latestSelectedAssets = newAssets;
    });
    setSelectedAssets(latestSelectedAssets);

    // Update the styles on the basis of latest selected assets
    const updatedStyles = update_selected_styles_by_selected_assets(
      selectedStyles,
      [...latestSelectedAssets]
    );
    onAddSelectedStyles(updatedStyles);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectWizard.selectedAssets]);

  return (
    <SimpleGrid maxW="7xl" columns={1} spacing={5} pb={5}>
      {selectedAssets.map((currentValue: any, index: number) => {
        return (
          <StyleCardForSteps
            key={index}
            id={currentValue?.id}
            title={currentValue?.title}
            options={currentValue.stylesData}
            isReadOnly={isReadOnly}
            showSelectedOnly={showSelectedOnly}
            onSelected={(selectedTypeOption) => {
              const newSelectedAssets = updateProjectWizardAssetsByPrereq({
                prevAssets: projectWizard.selectedAssets,
                selectedAssets,
                currAssetTitle: currentValue?.title,
                currSelectedOption: selectedTypeOption,
              });

              // Previous selectedAssets (previous state that is not updated yet)
              const prevTargetAsset = selectedAssets.find(
                (asset) => asset.title === selectedTypeOption.asset.title
              );
              const prevTargetAssetTypes = [...prevTargetAsset.stylesData];
              const prevTargetAssetTypeIndex = prevTargetAssetTypes.findIndex(
                (type) => type.type === selectedTypeOption.type
              );
              const prevTargetAssetNextType =
                prevTargetAssetTypes[prevTargetAssetTypeIndex + 1];

              // 1- Clone existing selected styles
              const updatedSelectedStyles = [...selectedStyles];

              // 2- Filter target Asset/style
              const targetStyle = updatedSelectedStyles.find(
                (asset) => asset.title === selectedTypeOption.asset.title
              );
              const targetStyleIndex = updatedSelectedStyles.findIndex(
                (asset) => asset.title === selectedTypeOption.asset.title
              );
              const targetAsset = newSelectedAssets.find(
                (asset) => asset.title === selectedTypeOption.asset.title
              );

              // 3- Filter target type of asset/style
              const targetStyleTypes = [...targetStyle.types];
              const targetAssetTypes = [...targetAsset.stylesData];
              const targetStyleType = targetStyleTypes.find(
                (type) => type.type === selectedTypeOption.type
              );
              const targetStyleTypeIndex = targetStyleTypes.findIndex(
                (type) => type.type === selectedTypeOption.type
              );
              const targetAssetTypeIndex = targetAssetTypes.findIndex(
                (type) => type.type === selectedTypeOption.type
              );
              const targetAssetNextType =
                targetAssetTypes[targetAssetTypeIndex + 1];

              const prevTargetStyleTypes = targetStyleTypes.slice(
                0,
                targetStyleTypeIndex
              );
              const updatedTargetStyleType = {
                ...targetStyleType,
                selectedItems: selectedTypeOption.items,
              };
              let nextTargetStyleType =
                targetStyleTypes[targetStyleTypeIndex + 1];
              let restStyleTypes = [];
              let updatedTypes = [
                ...prevTargetStyleTypes,
                updatedTargetStyleType,
              ];

              //1- Check if target type is last element of not, if it is last then do nothing with next types
              if (targetStyleTypeIndex === targetAssetTypes.length - 1) {
                updatedTypes = [
                  ...updatedTypes,
                  ...targetStyleTypes.slice(targetStyleTypeIndex + 1),
                ];
              } //2-  Only update next type when nextType no of 'types' are changed
              else if (
                prevTargetAssetNextType.items.length !==
                targetAssetNextType.items.length
              ) {
                nextTargetStyleType = {
                  ...targetStyleTypes[targetStyleTypeIndex + 1],
                  selectedItems: !targetAssetTypes[targetStyleTypeIndex + 1]
                    .items[0]
                    ? []
                    : [targetAssetTypes[targetStyleTypeIndex + 1].items[0]],
                };
                // Update 'restStyleTypes' with the next types as it is without any changes
                restStyleTypes = targetStyleTypes.slice(
                  targetStyleTypeIndex + 2
                );

                updatedTypes = [
                  ...updatedTypes,
                  nextTargetStyleType,
                  ...restStyleTypes,
                ];
              } // If next types have same number of types as previous
              else {
                updatedTypes = [
                  ...updatedTypes,
                  ...targetStyleTypes.slice(targetStyleTypeIndex + 1),
                ];
              }

              updatedSelectedStyles[targetStyleIndex] = {
                ...targetStyle,
                types: updatedTypes.filter(
                  (type) => type.selectedItems.length > 0
                ), // the only types having selectedItems empty then exclude them from that selectedStyle.
              };

              onAddSelectedStyles(updatedSelectedStyles);
              setSelectedAssets(newSelectedAssets);
            }}
          />
        );
      })}
    </SimpleGrid>
  );
};

export default StyleStep;