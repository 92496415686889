import { createSlice } from '@reduxjs/toolkit'

export const planSlice = createSlice({
  name: 'plan',
  initialState: {
    planDuration: "monthly",
    planType:null,
    selectedPlanPrice:null,
    selectedPlanId:null,
    currentPlan:'prod_LPdBLO52cHIsca/aaaaa',
    stripePlans: null,
  },
  reducers: {
    changePlanDuration: (state, action) => {
      state.planDuration = action.payload
    },
    changePlanType: (state, action) => {
      state.planType = action.payload
    },
    changePlanPrice: (state, action) => {
      state.selectedPlanPrice = action.payload
    },
    setStripePlans: (state, action) => {
      state.stripePlans = action.payload
    },
    setSelectedPlanId: (state, action) => {
      state.selectedPlanId = action.payload
    },
    setCurrentPlan: (state, action) => {
      state.currentPlan = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { changePlanDuration, changePlanPrice, setStripePlans, setSelectedPlanId, changePlanType, setCurrentPlan } = planSlice.actions;

export default planSlice.reducer