/////////////////////
// PricingCard Component
/////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  BoxProps,
  Flex,
  AccordionItem,
  ListItem,
  Accordion,
  Text,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  ListIcon,
  List,
  useBreakpointValue,
} from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Services Imports
import { FaCheckCircle } from "react-icons/fa";
 

// Interfaces
interface PricingCardProps extends BoxProps {
  data: {
    name: string;
    description: string;
    price: number;
    amountSaved?: number;
    duration: "monthly" | "yearly";
    benefits: string[];
  };
}


// Functions
const BillingBadge = (props: BoxProps) => (
  <Box
    rounded="full"
    fontSize="sm"
    bg="white"
    color="gray.900"
    px="3"
    py="1"
    pos="absolute"
    top="4"
    right="4"
    fontWeight="bold"
    {...props}
  />
);

// Functions
export const PricingCard = (props: PricingCardProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const {  data, ...rest } = props;
  const { name, description, price, amountSaved, duration, benefits } = data;
  const isFree = price === 0;
  const isSaving = amountSaved !== 0;

  return (
    <Flex
      direction="column"
      px={isMobile ? "1" :"6"}
      py={isMobile ? "1" :"8"}
      rounded="lg"
      pos="relative"
      maxW="xl"
      mx="auto"
      {...rest}
    >
      <Box flex="1">
         {!isSaving && (
          <BillingBadge>
            {`Amount saved ${amountSaved}!`}
          </BillingBadge>
        )}
        <Text fontSize="md" lineHeight="1" fontWeight="bold" color="black">
          {name}
        </Text>
        <Flex align="center"  mt="4" mb="3">
          <Text
            fontSize={{ base: "sm", xl: "xl" }}
            fontWeight="extrabold"
            lineHeight="1"
            color="black"
            flexShrink={0}
          >
            {price}
          </Text>
          {!isFree && (
            <>
              <Box mx={isMobile ? "0": "4"}>
                <Box
                  w="2px"
                  h="12"
                  opacity={0.5}
                  transform="rotate(15deg)"
                />
              </Box>
              <Text fontSize={{ base: "xm", xl: "2xl" }} lineHeight="1.2" color="gray.600">
                / {duration}
              </Text>
            </>
          )}
        </Flex>
        <Box>{isFree ? <>&nbsp;</> : 
            null
        }</Box>
        <Box mt={isMobile ? "2" :"6"}>
          <Text fontSize={{ base: "sm", xl: "xl" }} fontWeight="semibold" mb="6" color="gray.600">
            {description}
          </Text>
          <Accordion allowMultiple borderColor='white'>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Plan Features
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} pl={0} pr={0}>
                <List spacing={3} textAlign="start" fontSize={{ base: "sm", xl: "md" }} wordBreak={{ base: "break-word", xl: "unset" }}>
                  {benefits.map((item, index) => (
                    <ListItem key={index} display='flex' alignItems='start'>
                      <ListIcon mt={1} as={FaCheckCircle} color="green.500" />
                      {item}
                    </ListItem>
                  ))}
                </List>
              </AccordionPanel>
            </AccordionItem>
            </Accordion>
         
        </Box>
      </Box>
    </Flex>
  );

};

