import { useToast, UseToastOptions } from "@chakra-ui/react";

// interface Props extends UseToastOptions {}
const useCustomToast = (toastOptions?: UseToastOptions) => {
  const toast = useToast({
    isClosable: true,
    position: "top",
    duration: 5000,
    ...toastOptions,
  });

  const errorToast = ({ title, ...restProps }: UseToastOptions) => {
    toast({
      title,
      status: "error",
      ...restProps,
    });
  };
  const successToast = ({ title, ...restProps }: UseToastOptions) => {
    toast({
      title,
      status: "success",
      ...restProps,
    });
  };
  const warningToast = ({ title, ...restProps }: UseToastOptions) => {
    toast({
      title,
      status: "warning",
      ...restProps,
    });
  };

  return {
    toast,
    errorToast,
    successToast,
    warningToast,
  };
};

export default useCustomToast;
