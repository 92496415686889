import axios from "api/axios";
import { render_do_space_db } from "schemas/comment_schema";
import { API_URL } from "settings/site-credentials";

interface DataProps {
  text: string;
  // parentId: string
  html: string;
  projectId: string;
  attachments: any[];
}
export async function createComment(data: DataProps, userToken: string) {
  const body = {
    text: data.text,
    // parentId: data.parentId,
    htmlText: data.html,
    projectId: data.projectId,
    attachments: data.attachments,
  };

  try {
    const { data } = await axios.post(`${API_URL}/comments`, body, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

    return { data: data.data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function fetchAllCommentsByProjectId(
  projectId: string,
  userToken: string
) {
  try {
    const { data } = await axios.get(
      `${API_URL}/projects/${projectId}/comments`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function getCommentById(commentId: string, userToken: string) {
  try {
    const { data } = await axios.get(`${API_URL}/comments/${commentId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function fetchAllCommentsByAttachmentId(
  attachmentId: string,
  userToken: string
) {
  try {
    const { data } = await axios.get(
      `${API_URL}/attachments/${attachmentId}/comments`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}
