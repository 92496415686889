/////////////////////
// Card Header UI
/////////////////////

// Basic Imports
import * as React from "react";

// Design Imports
import { Flex, FlexProps, Heading } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Interfaces
interface CardHeaderProps extends FlexProps {
  title: string;
  action: React.ReactNode;
}

// Functions
export const CardHeader = (props: CardHeaderProps) => {
  const { title, action, ...flexProps } = props;
  return (
    <Flex justifyContent="space-between" alignItems="center" {...flexProps}>
      <Heading
        size="md"
        fontWeight="extrabold"
        letterSpacing="tight"
        marginEnd="6"
      >
        {title}
      </Heading>
      {action}
    </Flex>
  );
};
