/////////////////////
// Chat Section
/////////////////////

// Basic Imports
import { FC, useCallback, useState } from "react";

// Design Imports
import {
  Box,
  BoxProps,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";

// Layout and Section Imports
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";

// Data Imports
import { AssetForm } from "components/forms/AssetForm";
import { useProject } from "context/project/ProjectProvider";
import useFetchComments from "hooks/useFetchComments";
import { renderAssetTypeAsDB } from "schemas/assets_schema";
import ChatGalleryWithSwiper from "./ChatGalleryWithSwiper";
import CommentsSection from "./CommentsSection";
import UploadSection from "./UploadSection";
import useFetchAssets from "hooks/useFetchAssets";

// Interfaces
interface ChatProps extends BoxProps {
  project;
}

const Chat: FC<ChatProps> = ({ project, ...restProps }) => {
  const {
    fetchCommentsLoading,
    comments,
    uploadLoading,
    onUploadComment,
    setComment,
    comment,
  } = useFetchComments();
  const { projectWizard } = useProject();
  const [assetUploadedFiles, setAssetUploadedFiles] = useState(null);
  const [fileToEdit, setFileToEdit] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { assets, refetch } = useFetchAssets();

  const closeModalForm = () => {
    onClose();
    refetch();
  };
  const getDefaultSelectedAssets = useCallback(() => {
    const formattedTargetAssets = projectWizard.selectedOutputs.map(
      (assetOutput) => {
        const targetAsset = projectWizard.selectedAssets.find(
          (asset) => asset.title === assetOutput.title
        );

        return {
          id: assetOutput.id,
          alt: assetOutput.title || "-",
          quantity: assetOutput.quantity,
          icon: targetAsset?.icon,
          assetType: renderAssetTypeAsDB[targetAsset?.value],
          name: "",
          imgUrl: "",
          format: "",
          thumbnailUrl: "",
        };
      }
    );

    const spreadUponQuantity = [];
    formattedTargetAssets.forEach((asset) => {
      for (let i = 0; i < asset.quantity; i++) {
        spreadUponQuantity.push({
          ...asset,
          id: `${asset.id}-${i + 1}`,
          quantity: i + 1,
        });
      }
    });

    return spreadUponQuantity;
  }, [projectWizard, assets]);

  return (
    <Stack>
      <Card {...restProps}>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: "4", md: "10" }}
        >
          <CardContent>
            <HStack as="section" mb={5}>
              {/* Modal to add asset Start*/}
              <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered
                scrollBehavior="inside"
                size="xl"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Add to gallery</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <AssetForm
                      uploadOption={project}
                      project={projectWizard}
                      assets={assets}
                      close={closeModalForm}
                      setComment={setComment}
                      setAssetUploadedFiles={setAssetUploadedFiles}
                    />
                  </ModalBody>
                  <ModalFooter>
                    {/* <Button onClick={onClose}>Close</Button> */}
                  </ModalFooter>
                </ModalContent>
              </Modal>
              {/* Modal to ddd to gallery End*/}
              <CardHeader
                title="Assets"
                w="full"
                action={
                  <>
                    <Button
                      colorScheme="blue"
                      onClick={onOpen}
                      disabled={
                        projectWizard?.projectStatus === "paused" ? true : null
                      }
                    >
                      Add to gallery
                    </Button>
                  </>
                }
              />
            </HStack>
            <Box as="div" id="top">
              {" "}
            </Box>

            {/* Gallery images */}
            <ChatGalleryWithSwiper
              defaultSelectedAssets={getDefaultSelectedAssets()}
            />

            {/* All Comments */}
            <CardHeader title="" action="" mt="14" />
            <CommentsSection
              isLoading={fetchCommentsLoading}
              comments={comments}
              onEdit={(file) => setFileToEdit(file)}
            />

            <Box as="div" id="bottom">
              {" "}
            </Box>

            <UploadSection
              uploadLoading={uploadLoading}
              assetUploadedFiles={assetUploadedFiles}
              onUploadComment={onUploadComment}
              fileToEdit={fileToEdit}
              setComment={setComment}
              comment={comment}
              onCloseEditing={() => setFileToEdit(null)}
            />
          </CardContent>
        </Stack>
      </Card>
    </Stack>
  );
};

export default Chat;
