/////////////////////
// Profile Page
/////////////////////

// Basic Imports
import { useEffect, useState } from "react";

// Design Imports
import { usePrevious } from "@chakra-ui/react";

// Layout and Section Imports

// Custom Hooks and Services
import useAuth from "hooks/useAuth";
import {
  logoutUserServices,
  updateEmailService,
  updateUserProfile,
} from "services/userProfileService";
import { useNavigate } from "react-router-dom";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";

// Interfaces and Types
export type FormDataTypes = {
  firstName: string;
  lastName: string;
  company: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
  city: string;
  province: string;
  postalCode: string;
  avatar: string;
  email: string;
};
export type UpdateEmailFormDataTypes = {
  currEmail: string;
  newEmail: string;
  password: string;
};

const useProfilePage = () => {
  const { user, setUser, userLoading, userError, token }: any = useAuth();
  const { successToast, errorToast } = useCustomToast();
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [updateEmailFormSubmitLoading, setUpdateEmailFormSubmitLoading] =
    useState(false);
  const [formData, setFormData] = useState<FormDataTypes>({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    company: user?.company || "",
    addressLine1: user?.addressLine1 || "",
    addressLine2: user?.addressLine2 || "",
    country: user?.country || "",
    city: user?.city || "",
    postalCode: user?.postalCode || "",
    province: user?.province || "",
    avatar: user?.avatar || "",
    email: user?.email,
  });
  const prevFormState = usePrevious(formData);
  const navigate = useNavigate();

  useEffect(() => {
    setFormData({
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      company: user?.profile?.company || "",
      addressLine1: user?.profile?.addressLine1 || "",
      addressLine2: user?.profile?.addressLine2 || "",
      country: user?.profile?.country || "",
      city: user?.profile?.city || "",
      postalCode: user?.profile?.postalCode || "",
      province: user?.profile?.province || "",
      avatar: user?.profile?.avatar || "",
      email: user?.email || "",
    });
  }, [user]);

  /**
   * Handlers
   */

  const handleFormSubmit = async (formData: FormDataTypes) => {
    setFormSubmitLoading(true);

    const { data, error } = await updateUserProfile(formData, token);
    setFormSubmitLoading(false);

    if (!!error) {
      errorToast({ title: ERRORS.GENERIC });
      return;
    }

    setUser(data.data.user);
    successToast({ title: SUCCESS.PROFILE_UPDATED });
  };

  const handleUpdateEmailFormSubmit = async (
    formData: UpdateEmailFormDataTypes
  ) => {
    setUpdateEmailFormSubmitLoading(true);
    const { error } = await updateEmailService(formData, token);
    setUpdateEmailFormSubmitLoading(false);
    if (!!error) {
      errorToast({ title: ERRORS.UPDATE_EMAIL });

      return;
    }

    logoutUserServices();

    navigate("/login");
  };

  return {
    formData,
    setFormData,
    prevFormState,
    formSubmitLoading,
    updateEmailFormSubmitLoading,
    user,
    setUser,
    userLoading,
    userError,
    handleFormSubmit,
    handleUpdateEmailFormSubmit,
  };
};

export default useProfilePage;
