/////////////////////
// Login Form
/////////////////////

// Basic Imports

// Design Imports
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackProps,
} from "@chakra-ui/react";

// Layout and Section Imports
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";

// Data Imports

// Custom Hooks and Services
import useAuthForm from "../useAuthForm";
import { useRef, useState } from "react";
import TurnTileWidget from "pages/AuthPage/TurnsTile/TurnTile";

// Interfaces

// Functions
export const LoginForm = (props: StackProps) => {
  const { user, setEmail, setPassword, handleSignin } = useAuthForm();
  const formContainer = useRef(null);
  const [verified, setVerified] = useState(false);

  return (
    <Stack spacing="6" {...props}>
      <FormHeader
        title="Login to your account"
        description="Don't have an account?"
      />

      <FormWrapper onSubmit={handleSignin}>
        <Stack ref={formContainer} spacing="5">
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              placeholder="Enter your email"
              type="email"
              name="email"
              value={user.email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              id="password"
              placeholder="********"
              type="password"
              name="password"
              value={user.password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </FormControl>
        </Stack>
        {/* <TurnTileWidget setVerified={setVerified} /> TODO: Make sure to uncomment it when domain registrar issue resolves */}
        <Stack spacing="4">
          <Button
            variant="primary"
            color="white"
            bg="blue.500"
            _hover={{ backgroundColor: "blue.600" }}
            type="submit"
            isLoading={user.isLoading}
            // isDisabled={!verified} TODO: Make sure to uncomment it when domain registrar issue resolves
          >
            Sign in
          </Button>
        </Stack>
      </FormWrapper>
    </Stack>
  );
};
