/////////////////////
// Supporting Document Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Text, SimpleGrid, Stack } from "@chakra-ui/react";

import UploadedFiles from "./UploadedFiles";
import UploadingForm from "./UploadingForm";
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props {
  suppliedDocuments?: any[];
  prevStep?: () => any;
  nextStep?: () => any;
  onSubmit?: (uploaded: any[]) => void;
  onStepClick?: () => void;
  showHeader?: boolean;
  showUploadingForm?: boolean;
  showDeleteButton?: boolean;
}

// Functions
const SupportingDocumentsStep: FC<Props> = ({
  showHeader,
  showUploadingForm = true,
  showDeleteButton,
}) => {
  return (
    <Card>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "6", md: "14" }}
      >
        <CardContent>
          <Stack spacing={4}>
            {!!showHeader && (
              <CardHeader title="Supporting Documents" action={<></>} />
            )}
            <Text>
              Form for Uploading your Supporting Documents. (i.e.
              Specifications, architect drawings, concept images, etc.){" "}
            </Text>
          </Stack>
          {!!showUploadingForm ? (
            <SimpleGrid
              mt="6"
              spacing="4"
              columns={{ base: 1, md: 2 }}
              alignItems="top"
            >
              <UploadedFiles showDeleteButton={showDeleteButton} />
              <UploadingForm />
            </SimpleGrid>
          ) : (
            <UploadedFiles showDeleteButton={showDeleteButton} />
          )}
        </CardContent>
      </Stack>
    </Card>
  );
};

export default SupportingDocumentsStep;