/////////////////////
// All Projects Page
/////////////////////

// Basic Imports
import { FC, Fragment } from "react";

// Design Imports
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Layout and Section Imports
import DragDropTable from "components/sections/ProjectsSection/Table/DragDropTable";
import { columns } from "components/sections/ProjectsSection/Table/Body";
import { REACT_QUERY_KEYS } from "settings/constants/ReactQuery";
import { TABLE_NAME } from "settings/constants/QueryTable";

// Data Imports
import {
  fetchProjectsAxiosRequest,
  ProjectPage,
} from "services/projectsService";
import useFetchProjects from "hooks/useFetchProjects";

// Interfaces

// Custom Hooks and Services
import useAuth from "hooks/useAuth";

// Functions
const AllProjects = ({ notificationData, isConnected }: ProjectPage) => {
  const { auth, user }: any = useAuth();
  const { projects, isLoading, setProjects, onChangePriorities } =
    useFetchProjects(
      [REACT_QUERY_KEYS.ACTIVE_PROJECTS, user?._id, auth?.accessToken],
      () => fetchProjectsAxiosRequest(auth?.accessToken || "", user._id || ""),
      { enabled: !!user?._id }
    );

  if (!!isLoading) return <SkeletonLoading />;
  return (
    <Fragment>
      <DragDropTable
        columns={columns({ tableName: TABLE_NAME.ALL_TABLE })}
        data={projects}
        notificationData={notificationData}
        updateDataOnPriority={onChangePriorities}
        setData={setProjects}
      />
    </Fragment>
  );
};

export default AllProjects;
