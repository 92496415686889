/////////////////////
// Forgot (Password) Page
/////////////////////

// Basic Imports


// Design Imports


// Layout and Section Imports
import AuthLayout from "components/layouts/AuthLayout";

import { ForgotForm } from "components/forms/AuthForm/ForgotForm";

// Data Imports


// Interfaces


// Custom Hooks and Services


// Functions
const ForgotPage = () => (
  <AuthLayout>
    <ForgotForm
      px={{ base: "4", md: "8" }}
      py={{ base: "12", md: "48" }}
      width="full"
      maxW="md"
    />
  </AuthLayout>
);

export default ForgotPage;
