import colors from "../settings/theme/colors";

export function getColorOnPercentage(percentage: string | number) {
  let color = colors.success;

  const _percentage = typeof percentage === "string" ? Number(percentage) : percentage;

  if (_percentage >= 80) return colors.success;
  if (_percentage >= 50) return colors.warning;
  if (_percentage < 50) return colors.error;

  return color;
}
