/////////////////////
// Use Stripe Hook
/////////////////////

// Basic Imports
import { useQuery } from 'react-query';

// Design Imports


// Layout and Section Imports


// Data Imports


// Custom Hooks and Services


// Interfaces


// Functions
const useStripe = (axiosReqFn:any) => {
  const { data, error, isLoading, isFetching }:any = useQuery('plan', axiosReqFn, { initialData: {}, refetchOnWindowFocus: false });
  const plan = data;

  // useEffect(() => {
  //   const project = data?.data?.data?.project || null
  //   if (!project) {
  //     setProject({});
  //     return;
  //   }

  //   setProject(project_table_schema(project));
  // }, [data]);

  return {
    error,
    isLoading: isLoading || isFetching,
    plan: plan ? plan : {}
  }
}

export default useStripe