///////////////////////////////
// Individual Project Page
///////////////////////////////

// Basic Imports
import _ from "lodash";

// Design Imports
import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Wrap,
} from "@chakra-ui/react";
import ActionButton from "components/sections/ProjectsSection/Table/Body/ActionButton";

import {
  BsArrowLeft,
  BsChatSquareQuote,
  BsThreeDotsVertical,
} from "react-icons/bs";

import {
  AiTwotoneDelete,
  AiFillEdit,
  AiFillCheckCircle,
  AiFillPauseCircle,
} from "react-icons/ai";

import { VscDebugContinue } from "react-icons/vsc";

// Layout and Section Imports
import TableHeading from "components/sections/ProjectsSection/Table/TableHeading";
import { STATUS } from "settings/constants/project";

// Custom Hooks and Services
import { getButtonsFromProjectStatus } from "utils/getButtonsFromProjectStatus";
import { useNavigate } from "react-router-dom";
import useActionButtons from "hooks/useActionButton";

// Interfaces

const ProjectPageHeader = ({ project }) => {
  const { btnLoading, handlers } = useActionButtons();
  const possibleButtons = getButtonsFromProjectStatus(project.status);
  const navigate = useNavigate();
  const createdBy = project?.createdBy;
  const createdByString = createdBy ? (createdBy?.profile?.company ? createdBy?.profile?.company : (createdBy.firstName + ' ' + createdBy.lastName)) + ' : ' : '';
  const projectName = project?.name;
  const result = createdByString + projectName;

  return (
    <Wrap justify="space-between" spacing="8" px="2">
      <HStack justify="left">
        <BsArrowLeft size={25} cursor="pointer" onClick={() => navigate(-1)} />
      </HStack>
      <HStack flex={1} justify="center">
        <TableHeading fontSize={{ base: "18", md: "24" }}>
          {result}
        </TableHeading>
      </HStack>
      <HStack>
        {/* {project.status !== "deleted" && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<BsThreeDotsVertical />}
              variant="outline"
            />

            <MenuList w="10" zIndex={2}>
              {_.includes(possibleButtons, "edit") && (
                <ActionButton
                  as={MenuItem}
                  justifySelf="flex-center"
                  btnText="Edit"
                  colorScheme="transparent"
                  color="black"
                  width="100%"
                  //no editLoading because user will immediately navigate to edit page
                  isLoading={false}
                  rightIcon={<AiFillEdit />}
                  justifyContent="space-between"
                  fontSize="sm"
                  alertDialog={{
                    title: "Edit Project",
                    description: "Are you sure you want to edit the project?", // TODO: Eric should change suitable description
                    onSuccess: handlers.redirectToWizard,
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                />
              )}

              {_.includes(possibleButtons, "resume") && (
                <ActionButton
                  as={MenuItem}
                  justifySelf="flex-center"
                  btnText="Resume"
                  colorScheme="transparent"
                  color="black"
                  width="100%"
                  isLoading={
                    btnLoading.btn === "active" && btnLoading.isLoading
                  }
                  rightIcon={<VscDebugContinue />}
                  justifyContent="space-between"
                  fontSize="sm"
                  alertDialog={{
                    title: "Resume Project",
                    description: "Resume this project? ",
                    onSuccess: () =>
                      handlers.updateProjectStatus(STATUS.ACTIVE),
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                />
              )}

              {_.includes(possibleButtons, "paused") && (
                <ActionButton
                  as={MenuItem}
                  justifySelf="flex-center"
                  btnText="Pause"
                  colorScheme="transparent"
                  color="black"
                  width="100%"
                  isLoading={
                    btnLoading.btn === "paused" && btnLoading.isLoading
                  }
                  rightIcon={<AiFillPauseCircle />}
                  justifyContent="space-between"
                  fontSize="sm"
                  alertDialog={{
                    title: "Pause Project",
                    description: "Pause this project?",
                    onSuccess: () => handlers.updateProjectStatus(STATUS.PAUSE),
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                />
              )}

              {_.includes(possibleButtons, "complete") && (
                <ActionButton
                  as={MenuItem}
                  justifySelf="flex-center"
                  btnText="Complete"
                  colorScheme="transparent"
                  color="black"
                  width="100%"
                  isLoading={
                    btnLoading.btn === "completed" && btnLoading.isLoading
                  }
                  rightIcon={<AiFillCheckCircle />}
                  justifyContent="space-between"
                  fontSize="sm"
                  alertDialog={{
                    title: "Complete Project",
                    description: "Mark this project as complete?",
                    onSuccess: () =>
                      handlers.updateProjectStatus(STATUS.COMPLETE),
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                />
              )}

              {_.includes(possibleButtons, "download") && (
                <ActionButton
                  as={MenuItem}
                  justifySelf="flex-center"
                  btnText="Download"
                  colorScheme="transparent"
                  color="black"
                  width="100%"
                  mr={2}
                  isLoading={false}
                  rightIcon={<BsChatSquareQuote />}
                  justifyContent="space-between"
                  fontSize="sm"
                  alertDialog={{
                    title: "Download Project",
                    description: "Download project's marketing asset files?", // TODO: Eric should change suitable description
                    onSuccess: () => {
                      //TODO: DO somethings here
                    },
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                />
              )}
              {_.includes(possibleButtons, "revert") && (
                <ActionButton
                  as={MenuItem}
                  justifySelf="flex-center"
                  btnText="Revert"
                  colorScheme="transparent"
                  color="black"
                  width="100%"
                  mr={2}
                  isLoading={
                    btnLoading.btn === "active" && btnLoading.isLoading
                  }
                  rightIcon={<BsChatSquareQuote />}
                  justifyContent="space-between"
                  fontSize="sm"
                  alertDialog={{
                    title: "Revert Project",
                    description: "Are you sure? It will revert the project.", // TODO: Eric should change suitable description
                    onSuccess: () =>
                      handlers.updateProjectStatus(STATUS.ACTIVE),
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                />
              )}

              {_.includes(possibleButtons, "deleted") && (
                <ActionButton
                  as={MenuItem}
                  justifySelf="flex-center"
                  btnText="Delete"
                  colorScheme="transparent"
                  color="red"
                  width="100%"
                  mr={2}
                  isLoading={
                    btnLoading.btn === "deleted" && btnLoading.isLoading
                  }
                  rightIcon={<AiTwotoneDelete />}
                  justifyContent="space-between"
                  fontSize="sm"
                  alertDialog={{
                    title: "Delete Project",
                    description:
                      "Are you sure? It will delete anything that you've entered.", // TODO: Eric should change suitable description
                    onSuccess: () =>
                      handlers.updateProjectStatus(STATUS.DELETE),
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                />
              )}
            </MenuList>
          </Menu>
        )} */}
      </HStack>
    </Wrap>
  );
};

export default ProjectPageHeader;