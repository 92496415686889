/////////////////////
// Output Card UI used on Output Step of New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { 
  HStack, 
  Icon, 
  Text, 
  Tooltip, 
  Wrap 
} from "@chakra-ui/react";

import { MdHd } from "react-icons/md";

// Layout and Section Imports
import { ToggleButton } from "components/ui/common/ToggleButton";
import { ToggleButtonGroup } from "components/ui/common/ToggleButtonGroup";

// Interfaces
interface Props {
  title: string;
  defaultValue?: string;
  options: string[];
  onSelect?: (value: string) => void;
  selected: string;
  readOnly?: boolean
}

const RenderQuality: FC<Props> = ({
  title,
  options,
  onSelect,
  selected,
  readOnly
}) => {
  return (
    <HStack>
      <Icon as={MdHd} color="gray.500" />
      <Tooltip
        hasArrow
        placement="auto-start"
        label="Low = 3 to 5 days and about 250 Kb files, Medium = 1 to 2 weeks and 5Mb files, High = 2 to 4 weeks and 20Mb files"
      >
        <Text fontWeight="semibold">{title}</Text>
      </Tooltip>
      <Wrap shouldWrapChildren>
        <ToggleButtonGroup
          value={selected}
          defaultValue={selected}
          isAttached
          variant="outline"
          aria-label={title}
          onChange={(value) => onSelect(value)}
          readOnly={readOnly}
        >
          {options.map((option: any, idx: number) => (
            <ToggleButton key={idx.toString()} value={option} />
          ))}
        </ToggleButtonGroup>
      </Wrap>
    </HStack>
  );
};

export default RenderQuality;
