/////////////////////
// Forgot (Password) Form
/////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports
import FormWrapper from "../AuthForm/FormWrapper";
import React, { useState } from "react";

// Custom Hooks and Services
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import useCustomToast from "hooks/useCustomToast";
import useAssetForm from "./useAssetForm";
import { ERRORS } from "settings/constants/toastMessages";
import { format } from "date-fns";
import { assetType } from "schemas/asset_schema";
import _ from "lodash";
import { RiImageEditFill } from "react-icons/ri";
import { getCommentFile } from "services/assetService";
import { Dropzone, FilePreview, ImageEditor } from "hr-design-system";

// Interfaces
export type Asset = {
  title: string;
  name: string;
  description: string;
  assetType: string;
  version: string;
  notes: string;
  fileSize: string;
  chatUpload: boolean;
};
interface UploadedFile {
  file: File;
  url: string;
}

// Functions
const schema = yup.object().shape({
  //desiredAsset: yup.string().required().label("Asset desired asset"),
  title: yup.string().required().label("Asset title"),
  //assets: yup.string().label("Another assets type"),
  name: yup.string().label("Asset name"),
  description: yup.string().required().label("Asset description"),
  assetType: yup.string().label("Asset status"),
  version: yup.string().required().label("Asset Date (Version)"),
  notes: yup.string().required().label("Project comment"),
  fileSize: yup.string().required().label("File size"),
  chatUpload: yup.boolean().required().label("Populate chat with assets"),
});

export const AssetForm = ({
  project,
  assets,
  close,
  uploadOption,
  setAssetUploadedFiles,
  setComment,
}) => {
  const [fileToEdit, setFileToEdit] = useState({
    path: "",
    name: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { errorToast } = useCustomToast();
  const {
    register,
    setValue,
    resetField,
    handleSubmit,
    formState: { errors },
    clearErrors,
    getValues,
  } = useForm<Asset>({ resolver: yupResolver(schema) });
  const {
    handleUploadAsset,
    uploadLoading,
    checkAssetForAttchement,
    updateAsset,
  } = useAssetForm();
  const [isRenderAsset, setRenderAsset] = React.useState(false);
  const [title, setTile] = React.useState(
    "Please upload an asset to get the name"
  );
  const [cameraCaptureLocations, setCameraCaptureLocations] = React.useState(
    []
  );
  const [options, setOptions] = React.useState([]);
  const [assetQuantity, setAssetQuantity] = React.useState(1);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  React.useEffect(() => {
    const selectedOutput = project.selectedOutputs;

    if (selectedOutput.length > 0) {
      let assetOptions = new Array();
      for (var i = 0; i < selectedOutput.length; i++) {
        let quantity = selectedOutput[i].quantity;
        for (var j = 0; j < quantity; j++) {
          assetOptions.push({
            name: selectedOutput[i].title,
            value: selectedOutput[i].title + " " + (j + 1),
            title: `_${_.replace(
              selectedOutput[i].title,
              / /g,
              "_"
            ).toLowerCase()}`,
            quant: j + 1,
          });
        }
        //options.push({ title: output.title + " " + (i + 1) });
      }
      assetOptions.push({
        name: "Other",
        value: "Other",
        title: "_other",
        quant: 0,
      });
      setOptions(assetOptions);
    }
    getTitleValue();
  }, [uploadedFiles]);

  const handleSubmitAsset = async (formData: any) => {
    const uploadedAssets = assets;
    let uploadedAsset = null;
    const chatUpload = formData?.chatUpload;
    if (uploadedFiles.length === 0) {
      errorToast({ title: ERRORS.ASSET_UPLOAD_ERROR });
      return;
    }
    const parent = checkAssetForAttchement(
      uploadedAssets,
      formData,
      assetQuantity
    );
    if (parent.length === 0) {
      const { attachment, error } = await handleUploadAsset({
        formData: formData,
        attachments: uploadedFiles,
        assetQuantity: assetQuantity,
      });
      uploadedAsset = attachment;
      if (!!error) return;
    } else {
      formData.assetId = parent[0]._id;
      const { attachment, error } = await updateAsset({
        formData: formData,
        attachments: uploadedFiles,
        assetQuantity: assetQuantity,
        parent: parent[0],
      });
      uploadedAsset = attachment;
      if (!!error) return;
    }
    if (chatUpload) {
      setComment({
        text: "The following image has been added to the gallery",
        html: "The following image has been added to the gallery",
      });
      const commentFile = getCommentFile(uploadedFiles, uploadedAsset);
      if (commentFile) setAssetUploadedFiles(commentFile);
    }
    // resetState();
    close();
  };

  //Update form with asset type
  const updateFormWithAssetType = (e) => {
    //New Code
    try {
      resetField("description");
      let quant =
        e.target.options[e.target.selectedIndex].getAttribute("data-quant");
      let name =
        e.target.options[e.target.selectedIndex].getAttribute("data-name");
      let type = e.target.options[e.target.selectedIndex].title;
      if (type.includes("/")) {
        type = type.split("/")[0];
      }
      type = assetType[type];
      setValue("assetType", type);
      setAssetQuantity(Number(quant));
      if (
        type === assetType._3d_render ||
        type === assetType._virtual_staging ||
        type === assetType._other ||
        type === assetType._3d_commercial_
      ) {
        const assetWithLocation = project.selectedOutputs.filter(
          (output) => output.title.toLowerCase() == name.toLowerCase()
        );
        if (
          assetWithLocation &&
          assetWithLocation[0]?.cameraCaptureLocations.length > 0
        ) {
          let options = new Array();
          assetWithLocation[0]?.cameraCaptureLocations.forEach((c) => {
            options.push({ title: c });
          });
          setCameraCaptureLocations(options);
          setRenderAsset(true);
        } else {
          setRenderAsset(false);
          setCameraCaptureLocations([]);
          setValue("description", "", { shouldValidate: false });
        }
      } else {
        setCameraCaptureLocations([]);
        setRenderAsset(false);
        setValue("description", "", { shouldValidate: false });
      }
    } catch (e) {
      console.log("Failed to add asset title");
    }
  };
  const getVersionValue = () => {
    let todayDate = format(new Date(), "yyyy.MM.dd");
    if (assets && assets.length > 0) {
      let counter = 1;
      let todaysAsset = assets.filter(
        (date) => format(new Date(date.createdAt), "yyyy.MM.dd") === todayDate
      );
      counter = todaysAsset.length + 1;
      return todayDate.slice(0, 10).replaceAll("-", ".") + " (" + counter + ")";
    } else {
      return todayDate;
    }
  };

  const getTitleValue = () => {
    // Resetting the name field
    resetField("name");

    // Fetching the necessary values
    let version = getValues("version");
    let description = getValues("description") || "Set a description";
    let notes = getValues("notes");
    let fileSize = getValues("fileSize");

    // Initial assignment to title
    let title =
      uploadedFiles &&
      uploadedFiles.length > 0 &&
      version &&
      description &&
      notes &&
      fileSize
        ? ""
        : "Please upload an asset to get the name";

    if (title !== "Please upload an asset to get the name") {
      // Constructing the title for each uploaded file
      title = uploadedFiles
        .map(
          (file, index) =>
            `${version} - ${
              project?.projectInfo?.name
            } | ${description} | ${notes}${fileSize ? " - " + fileSize : ""}.${
              file.file.type.split("/")[1] || file.file.type
            }`
        )
        .join(" , ");

      // Clears errors related to 'name'
      clearErrors(["name"]);
    }

    // Sets the calculated title
    setTile(title);
    // Sets the 'name' field's value to the calculated title
    setValue("name", title);
  };

  return (
    <>
      <Stack spacing="8">
        <FormWrapper onSubmit={handleSubmit(handleSubmitAsset)}>
          <Stack spacing="4">
            <FormControl>
              <FormLabel htmlFor="name">Asset name</FormLabel>
              <Input
                id="name"
                placeholder="Enter asset name"
                type="text"
                name="text"
                disabled
                value={title}
                {...register("name")}
              />
              {!errors?.name && (
                <Text fontSize="xs">
                  Use this filename for your file before uploading.
                </Text>
              )}
              {errors?.name && (
                <Text color="red" mt="1">
                  {errors.name.message}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="description">Asset title</FormLabel>
              <Select
                placeholder="Select option"
                {...register("title", {
                  onChange: (e) => {
                    updateFormWithAssetType(e);
                    getTitleValue();
                    // setAssetQuantity(e.target.selectedIndex);
                  },
                })}
              >
                {options.length > 0 &&
                  options.map((output, index) => (
                    <option
                      value={output.value}
                      key={index}
                      title={output.title}
                      data-quant={output.quant}
                      data-name={output.name}
                    >
                      {output.value}
                    </option>
                  ))}
              </Select>
              {errors?.title && (
                <Text color="red" mt="1">
                  {errors.title.message}
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="version">Asset Date (Version)</FormLabel>
              <Input
                id="version"
                placeholder="Version"
                type="text"
                name="text"
                {...register("version", {
                  value: getVersionValue(),
                  onChange: (e) => {
                    getTitleValue();
                  },
                })}
              />
              {errors?.version && (
                <Text color="red" mt="1">
                  {errors.version.message}
                </Text>
              )}
            </FormControl>
            {isRenderAsset ? (
              <FormControl>
                <FormLabel htmlFor="description">Asset description</FormLabel>
                <Select
                  placeholder="Select option"
                  {...register("description", {
                    onChange: (e) => {
                      getTitleValue();
                    },
                  })}
                >
                  {cameraCaptureLocations.map((output, index) => (
                    <option value={_.upperFirst(output.title)} key={index}>
                      {_.upperFirst(output.title)}
                    </option>
                  ))}
                </Select>
                {errors?.description && (
                  <Text color="red" mt="1">
                    {errors.description.message}
                  </Text>
                )}
              </FormControl>
            ) : (
              <FormControl>
                <FormLabel htmlFor="description">Asset description</FormLabel>
                <Input
                  id="description"
                  placeholder="Enter asset description"
                  type="text"
                  name="text"
                  {...register("description", {
                    onChange: (e) => {
                      getTitleValue();
                    },
                  })}
                />
                {errors?.description && (
                  <Text color="red" mt="1">
                    {errors.description.message}
                  </Text>
                )}
              </FormControl>
            )}

            <FormControl>
              <FormLabel htmlFor="fileSize">File dimension</FormLabel>
              <Input
                id="fileSize"
                placeholder="Enter asset dimension"
                type="text"
                name="fileSize"
                {...register("fileSize", {
                  onChange: (e) => {
                    getTitleValue();
                  },
                })}
              />
              {errors?.fileSize && (
                <Text color="red" mt="1">
                  {errors.fileSize.message}
                </Text>
              )}
            </FormControl>
            <Tooltip label="Public visibility">
              <FormControl>
                <FormLabel htmlFor="notes">Asset Label</FormLabel>
                <Select
                  id="notes"
                  placeholder="Select label"
                  {...register("notes", {
                    onChange: (e) => {
                      getTitleValue();
                    },
                  })}
                >
                  <option value="Draft">Draft</option>
                  <option value="Update">Update</option>
                </Select>
                {errors?.notes && (
                  <Text color="red" mt="1">
                    {errors.notes.message}
                  </Text>
                )}
              </FormControl>
            </Tooltip>
          </Stack>
          <Stack>
            <FormControl>
              <Checkbox defaultChecked {...register("chatUpload")}>
                Populate chat with this assets
              </Checkbox>
            </FormControl>
            <Wrap>
              {uploadedFiles.map((file) => (
                <FilePreview
                  fileName={file.file.name}
                  fileType={file.file.type}
                  fileURL={file.url}
                  allowRemove
                  allowEdit
                  allowClickToEnlarge
                  onRemove={() => {
                    setUploadedFiles(
                      uploadedFiles.filter((f) => f.url !== file.url)
                    );
                  }}
                  onEdit={() => {
                    setFileToEdit({
                      path: file.url,
                      name: file.file.name,
                    });
                    onOpen();
                  }}
                />
              ))}
            </Wrap>
          </Stack>
          <Dropzone
            wrapInModal={false}
            showPreviews={false}
            allowMultiple={false}
            onUpload={(files) => {
              if (Array.isArray(files)) {
                const modified = files.map((file) => ({
                  file,
                  url: URL.createObjectURL(file),
                }));
                setUploadedFiles([...uploadedFiles, ...modified]);
              } else {
                setUploadedFiles([
                  ...uploadedFiles,
                  { file: files, url: URL.createObjectURL(files) },
                ]);
              }
            }}
          />
          <Box
            borderColor="gray.300"
            borderStyle="dashed"
            borderWidth="2px"
            rounded="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="ghost"
              w="full"
              colorScheme="gray"
              leftIcon={<RiImageEditFill />}
              onClick={() => {
                setFileToEdit({ path: "", name: "" });
                onOpen();
              }}
            >
              Edit and upload attachment
            </Button>
          </Box>

          <Stack spacing="4">
            <Button
              variant="primary"
              color="white"
              bg="blue.500"
              _hover={{ backgroundColor: "blue.600" }}
              type="submit"
              isLoading={uploadLoading}
            >
              Submit
            </Button>
          </Stack>
        </FormWrapper>
      </Stack>

      {isOpen && (
        <ImageEditor
          defaultUploadFile={{
            path: fileToEdit?.path || "",
            name: fileToEdit?.name || "",
          }}
          wrapInModal={true}
          onUpload={async (file) => {
            const convertedFile = new File([file.blobFile], file.fileName, {
              type: file.blobFile.type,
            });

            const clonedUploadedFiles = [...uploadedFiles];

            const alreadyUploaded = uploadedFiles.findIndex(
              (f) => f.file.name === convertedFile.name
            );
            console.log({ alreadyUploaded });

            if (alreadyUploaded !== -1) {
              clonedUploadedFiles[alreadyUploaded] = {
                file: convertedFile,
                url: URL.createObjectURL(convertedFile),
              };

              setUploadedFiles(clonedUploadedFiles);
              onClose();
              return;
            }

            setUploadedFiles([
              ...clonedUploadedFiles,
              {
                file: convertedFile,
                url: URL.createObjectURL(convertedFile),
              },
            ]);

            onClose();
          }}
          onModalClose={() => {
            onClose();
          }}
        />
      )}
    </>
  );
};
