/////////////////////
// Output Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import _ from "lodash";
import { FC } from "react";

// Design Imports
import {
  Avatar,
  Box,
  BoxProps,
  Circle,
  Divider,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

// Layout and Section Imports
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";

// Data Imports
import Activity from "./Activity/Activity";
import { GrProjects } from "react-icons/gr";
import { getActivities } from "services/activityService";

// Interfaces
interface Props {
  styles: BoxProps;
  activity: any;
  projectId?: string;
  readOnly?: boolean;
}

// Functions

const ActivityCard: FC<Props> = ({ activity, styles, projectId, readOnly }) => {
  const activities = activity.diff;
  return (
    <Card id={projectId} {...styles}>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "6", md: "14" }}
      >
        <CardContent>
          <CardHeader title="" action={<></>} />
          <Box>
            <VStack align="left">
              <HStack spacing="4">
                <Circle bg={"blue.500"} p={2} color="white">
                  <GrProjects fontSize="14px" className="customIcon" />
                </Circle>
                <VStack spacing="0" align="left">
                  <Text as="b" fontSize="md" color="black">
                    Project Wizard
                  </Text>
                  {/* <HStack pt={"2"}>
                    <Avatar
                      name={activity?.updatedBy?.firstName + ' ' + activity?.updatedBy?.lastName}
                      size="xs"
                      src={activity?.updatedBy?.profile?.avatar}
                    />
                    <Text fontSize="sm" color="black">
                      {activity?.updatedBy?.firstName + ' ' + activity?.updatedBy?.lastName}
                    </Text>
                  </HStack> */}
                </VStack>
              </HStack>
              <Stack direction="row" pl={2}>
                <Divider
                  ml={"7px"}
                  h="auto"
                  orientation="vertical"
                  borderColor="blue.500"
                  borderWidth="1px"
                  variant="dashed"
                  size="xl"
                />
                <Activity activity={activities} styles={{ pl: 4, pt: 0 }} />
              </Stack>
            </VStack>
          </Box>
        </CardContent>
      </Stack>
    </Card>
  );
};

export default ActivityCard;
