/////////////////////
// CurrencySwitch Component
/////////////////////

// Basic Imports
import * as React from "react";

// Design Imports
import {
  Flex,
  HStack,
  Text,
  useColorModeValue as mode,
  useControllableState,
} from "@chakra-ui/react";

// Layout and Section Imports
import { Circle } from "./Circle";

// Data Imports

// Services Imports
// import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { useDispatch } from 'react-redux';
import  { changeCurrencyType } from 'app/currencyReducer'
import  { changePlanPrice, changePlanType, setSelectedPlanId } from 'app/planReducer'

// Interfaces


type Currency = "USD" | "CAD" ;

interface CurrencySwitchProps {
  defaultValue?: Currency;
  value?: Currency;
  onChange?: (value: Currency) => void;
}

// Functions
function useSwitch(props: CurrencySwitchProps) {
  const dispatch = useDispatch()
  const [value, setValue] = useControllableState(props);
  const isUSD = value === "USD";
  const isCAD = value === "CAD";

  return {
    value,
    setValue,
    isUSD,
    isCAD,
    buttonProps: {
      "aria-checked": isUSD,
      role: "switch",
      style: {
        userSelect: "none",
      } as React.CSSProperties,
      onClick() {
        dispatch(changePlanPrice(null));
        dispatch(setSelectedPlanId(null));
        dispatch(changePlanType(null));
        const nextValue = value === "USD" ?  "CAD"  : "USD";
        setValue(nextValue);
        dispatch(changeCurrencyType(nextValue));
      },
    },
  };
}

// Functions
export const CurrencySwitch = (props: CurrencySwitchProps) => {
  const { buttonProps, isUSD, isCAD } = useSwitch(props);

  return (
    <HStack
      as="button"
      fontSize="md"
      fontWeight="semibold"
      spacing="4"
      display="inline-flex"
      {...buttonProps}
    >
      <Text color={isUSD ? mode("blue.600", "blue.400") : "black" }>
      USD
      </Text>
      <Flex
        align="center"
        justify={isUSD ? "flex-start" : "flex-end" }
        height="24px"
        w="60px"
        rounded="full"
        bg="gray.300"
        px="1"
       >
         
        <Circle layout  />
      </Flex>
      <Text color={isCAD ? mode("blue.600", "blue.400") : "black" }>
        CAD
      </Text>
    </HStack>
  );
};


