/////////////////////
// Schemas
/////////////////////

// Util Import
import { getColorOnPercentage } from "utils/getColorOnPercentage";
import { getCompletionPercentage } from "utils/getCompletionPercentage";
import { getNextStep } from "utils/getNextStep";

// Functions
export function project_table_schema(project) {
  if (!project || Object.keys(project).length === 0) return {}

  const projectInfo = {
    name: project.name,
    description: project.description,
    ...project.details
  }

  const completionPercentage = getCompletionPercentage({
    projectInfo,
    selectedAssets: project.selectedStyles,
    selectedOutputs: project.selectedStyles,
    selectedStyles: project.selectedStyles,
    attachments: project.attachments
  })

  const row = {
    id: project._id,
    name: project.name,
    state: project.status,
    priority: 3,
    completion: {
      stage_name: "Modelling",
      percent: project?.completionPercentage || "0",
      color: project?.completionPercentage ? getColorOnPercentage(project.completionPercentage) : "",
    },
    next_step: { assignee: project.assignee, client: project.createdBy },  //getNextStep(project)
    information: {
      percent: completionPercentage,
      color: getColorOnPercentage(completionPercentage),
    },
  };

  return row;
}
