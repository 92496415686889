/////////////////////
// Draggable Table Row
/////////////////////

// Basic Imports

// Design Imports
import { Td, Tr, Box, Tooltip } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import useAuth from "hooks/useAuth";
import { haveUnreadNotifications } from "services/projectsService";

// Interfaces

// Functions
export const DraggableTableRow = ({ row, notificationData }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
    isOver: isDraggingOver,
  } = useSortable({
    id: row.original.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };
  const { user }: any = useAuth();
  const unReadNotification = haveUnreadNotifications(
    row,
    notificationData,
    user._id
  );
  return (
    <Tooltip
      label={
        row.values?.state == "paused"
          ? "This project is paused and you cannot work on it"
          : null
      }
      placement="top"
      hasArrow
    >
      <Tr rounded="md" ref={setNodeRef} style={style} {...row.getRowProps()}>
        <Box
          border="1px solid #7070705c"
          mb={5}
          position="relative"
          borderRadius={10}
          style={
            {
              // pointerEvents: row?.values?.state == 'paused' ? 'none' : 'auto',
              // opacity: row.values?.state == 'paused' ? '0.5' : '1',
            }
          }
        >
          {unReadNotification ? (
            <Box
              position="absolute"
              top="5px"
              left="5px"
              backgroundColor="red.500"
              width="10px"
              height="10px"
              rounded="xl"
              boxShadow="0 0px 4px 0px rgba(230,62,62,1)"
            />
          ) : null}
          {isDragging ? (
            <Td bg="rgba(127, 207, 250, 0.3)" colSpan={row.cells.length}>
              &nbsp;
            </Td>
          ) : (
            row.cells.map((cell) => {
              if (cell.column.id === "state") return;
              if (cell.column.id === "priority") {
                return (
                  <Td
                    bg="white"
                    border="none"
                    {...cell.getCellProps()}
                    pt={4}
                    pb={4}
                    borderRadius={15}
                  >
                    <span>
                      {cell.render("Cell", {
                        sortingAttributes: attributes,
                        sortingListeners: listeners,
                        isDraggingOver,
                      })}
                    </span>
                  </Td>
                );
              }
              if (cell.column.id === "actions") {
                return (
                  <Td
                    width="full"
                    bg="white"
                    border="none"
                    {...cell.getCellProps()}
                    pt={4}
                    pb={4}
                    borderRadius={15}
                  >
                    <span>
                      {cell.render("Cell", {
                        sortingAttributes: attributes,
                        sortingListeners: listeners,
                        isDraggingOver,
                      })}
                    </span>
                  </Td>
                );
              }

              if (cell.column.id === "next_step") {
                return (
                  <Td
                    width="full"
                    bg="white"
                    border="none"
                    {...cell.getCellProps()}
                    pt={4}
                    pb={4}
                    borderRadius={15}
                  >
                    <span>
                      {cell.render("Cell", {
                        sortingAttributes: attributes,
                        sortingListeners: listeners,
                        isDraggingOver,
                      })}
                    </span>
                  </Td>
                );
              }
              return (
                <Td {...cell.getCellProps()} border="none" pt={4} pb={4}>
                  {cell.render("Cell")}
                </Td>
              );
            })
          )}
        </Box>
      </Tr>
    </Tooltip>
  );
};
