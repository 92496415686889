/////////////////////
// PageContent (appPages) UI
/////////////////////

// Basic Imports


// Design Imports
import {
  Box,
  BoxProps,
  useColorModeValue as mode,
} from '@chakra-ui/react'

// Layout and Section Imports


// Data Imports


// Interfaces


// Functions
export default function PageContent({children, ...restProps }: BoxProps) {
  if (children)

    return (
      <Box
        as="section"
        width="100%"
        maxW="7xl"
        py="2"
        px={{ base: '1', md: '0' }}
        flex="1"
        {...restProps}
      >
        <Box
          mx="auto"
          bg={mode('white', 'gray.700')}
          p="4"
          rounded="lg"
          w="100%"
          minH="250"
        >
          {children}
        </Box>
      </Box>
    )
  return (
    <Box
      as="section"
      w="100%"
      maxW="7xl"
      py="8"
      px={{ base: '1', md: '0' }}
      flex="1"
      {...restProps}
    >
      <Box
        bg={mode('white', 'gray.700')}
        p="4"
        rounded="lg"
        shadow="base"
        w="100%"
        minH="250"
      >
        <Box
          border="3px dashed currentColor"
          color={mode('gray.200', 'gray.600')}
          rounded="lg"
          minH="240"
        />
      </Box>
    </Box>
  )
}
