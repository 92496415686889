import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchAssetsByProjectId } from "services/assetService";
import useAuth from "./useAuth";
import useCustomToast from "./useCustomToast";
import { createTaskService } from "services/taskService";
import { SUCCESS } from "settings/constants/toastMessages";

const useFetchAssets = () => {
  const { token }: any = useAuth();
  const { id: projectId } = useParams();
  const { errorToast, successToast } = useCustomToast();

  // Data
  const cameraCaptureLocations: any[] = [
    { value: "bar", label: "Bar" },
    { value: "bathroom", label: "Bathroom" },
    { value: "breakfastnook", label: "Breakfast Nook" },
    { value: "bedroom", label: "Bedroom" },
    { value: "diningroom", label: "Dining Room" },
    { value: "entrance", label: "Entrance" },
    { value: "familyroom", label: "Family Room" },
    { value: "greatroom", label: "Great Room" },
    { value: "kitchen", label: "Kitchen" },
    { value: "livingroom", label: "Living Room" },
    { value: "mainbathroom", label: "Main Bathroom" },
    { value: "masterbedroom", label: "Master Bedroom" },
    { value: "masterbedroomensuite", label: "Master Bedroom Ensuite" },
    { value: "mainbathroom", label: "Main Bathroom" },
    { value: "Office", label: "Office" },
    { value: "frontelevation", label: "Front Elevation" },
    { value: "leftelevation", label: "Left Elevation" },
    { value: "rightelevation", label: "Right Elevation" },
    { value: "backelevation", label: "Back Elevation" },
    { value: "northelevation", label: "North Elevation" },
    { value: "eastelevation", label: "East Elevation" },
    { value: "southelevation", label: "South Elevation" },
    { value: "westelevation", label: "West Elevation" },
    { value: "frontperspective", label: "Front Perspective" },
    { value: "frontyard", label: "Front Yard" },
    { value: "backyard", label: "Back Yard" },
    { value: "pool", label: "Pool" },
    { value: "deck", label: "Deck" },
    { value: "patio", label: "Patio" },
    { value: "roof", label: "Roof" },
    { value: "rearperspective", label: "Rear Perspective" },
    { value: "leftsideperspective", label: "Left Side Perspective" },
    { value: "rightsideperspective", label: "Right Side Perspective" },
    { value: "topperspective", label: "Top Perspective" },
    { value: "topelevation", label: "Top Elevation" },
    { value: "study", label: "Study" },
    { value: "den", label: "Den" },
    { value: "gameroom", label: "Game Room" },
    { value: "lanai", label: "Lanai" },
    { value: "porch", label: "Porch" },
    { value: "cellar", label: "Cellar" },
    { value: "gym", label: "Gym" },
    { value: "walkincloset", label: "Walk In Closet" },
    { value: "playroom", label: "Playroom" },
    { value: "sunroom", label: "Sun Room" },
    { value: "mudroom", label: "Mud Room" },
    { value: "laundry", label: "Laundry" },
  ];

  let { data, ...restQueryResponse } = useQuery(["assets", projectId], () =>
    fetchAssetsByProjectId(projectId, token)
  );

  const addBatchTaskMutationQuery = useMutation({
    mutationFn: async (formDataList: any[]) => {
      try {
        const response = await Promise.all(
          formDataList.map((formData) =>
            createTaskService(formData, token ? token : "")
          )
        );

        return response.map((res) => res.data.data.task);
      } catch (error) {
        errorToast({ title: "Something went wrong..." });
        throw error;
      }
    },
    onSuccess: async (updatedTasks) => {
      console.log({ updatedTasks });
      successToast({ title: SUCCESS.TASK_CREATED });
    },
  });
  return {
    assets: data?.assets || [],
    ...restQueryResponse,
    addBatchTaskMutationQuery: addBatchTaskMutationQuery,
    cameraCaptureLocations: cameraCaptureLocations,
  };
};

export default useFetchAssets;
