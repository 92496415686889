/////////////////////
// [Replace me] Page
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  Box,
  Center,
  HStack,
  Link as ChakraLink,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props {
  children: JSX.Element;
}

// Functions
const AuthLayout: FC<Props> = ({ children }) => {
  return (
    <HStack
      as="section"
      justifyContent="center"
      bg={mode("gray.50", "gray.400")}
      h="100vh"
      pos="relative"
      px={{ base: "6", lg: "12" }}
    >
      <Box w="full">
        <Center justifyContent="center" h="100vh">
          <Stack
            align="center"
            boxShadow="rgb(0 0 0 / 3%) 0px 8px 20px;"
            p={5}
            borderRadius="3xl"
            bg="white"
          >
            {children}
            <Text fontSize="sm">
              © {new Date().getFullYear()}{" "}
              <ChakraLink href="https://progressguide.com" isExternal>
                ProgressGuide Solutions Inc.
              </ChakraLink>{" "}
              All rights reserved.
            </Text>
          </Stack>
        </Center>
      </Box>
    </HStack>
  );
};

export default AuthLayout;
