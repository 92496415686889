import { Container, Progress, Stack } from '@chakra-ui/react';
import FormHeader from 'components/forms/AuthForm/FormHeader';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { storeCustomerTokenIntoLocalStorage } from 'services/authService';

const SuccessPage = () => {
  const { token: authToken } = useParams();

  useEffect(() => {
    storeCustomerTokenIntoLocalStorage(authToken);

    // Delay for 3 sec
    setTimeout(() => {
      window.location.replace('/');
    }, 3000);
  }, []);

  return (
    <Container pt="10">
      <Stack>
        <FormHeader 
          title="Verifying your account"
          description="Redirecting..."
        />
        <Progress size="xs" isIndeterminate />
      </Stack>
    </Container>
  );
};

export default SuccessPage;
