import axios from "axios";
import { RESOURCETYPE } from "settings/constants/task";

import { API_URL } from "settings/site-credentials";

export const TASK_STATUS = [
  { displayValue: "Completed", value: "completed" },
  { displayValue: "Assigned", value: "assigned" },
  { displayValue: "Not assigned", value: "not-assigned" },
  { displayValue: "In progress", value: "in-progress" },
];
export const TASK_STATUS_NAMING = {
  completed: "Completed",
  assigned: "Assigned",
  "not-assigned": "Not assigned",
  "in-progress": "In progress",
};

export const taskThumnail = {
  "2d_floor_plan":
    "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/2DFloorPlan/1_-_Drafting_Style_-_Contrast_between_solid_void_arzq1m.jpg",
  landscape_plan:
    "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/LandspacePlan/1_-_Landscaping_Plan_-_Landscape_Layout_j9tsao.jpg",
  marketing_brochure:
    "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/Brochure/1_-_Type_-_Single_Sheet_v0iegz.png",
  specification_sheet:
    "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/SpecSheet/1_-_Type_-_Bulleted_List_ohft20.png",
  floor_plan_3d:
    "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/1_-_Interior_Design_Style_-_Empty_spyygf.jpg",
  "3d_render":
    "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/1_-_Interior_Design_Style_-_Empty_spyygf.jpg",
  "3d_model":
    "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/3DModel/1_-_3D_Model_-_Product_kst7lp.jpg",
  virtual_stating:
    "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/2_-_Interior_Design_Style_-_Classic_wuqm0l.jpg",
  commercial_3d:
    "https://homerender.sfo3.digitaloceanspaces.com/HR/APP/Styles/InteriorStyle/2_-_Interior_Design_Style_-_Classic_wuqm0l.jpg",
  other:
    "https://homerender.sfo3.cdn.digitaloceanspaces.com/HR/WEBSITE/OtherAssetTask%20.jpg",
};

export function taskService(output: any[], task: any[]): any[] {
  if ((!output || output.length === 0) && (!task || task.length === 0))
    return [];

  if (!output || output.length === 0) {
    return [
      {
        output: [],
        _id: "",
        title: "Zombie Task",
        initialData: "",
        imageSize: "",
        sourceFile: "",
        quantity: "",
        outputFileType: [],
        tasks: task.length === 0 ? [] : task,
      },
    ];
  }

  return output.map((selectedOutput) => ({
    output: selectedOutput,
    title: selectedOutput.title,
    _id: selectedOutput._id,
    initialData: selectedOutput.initialData,
    imageSize: selectedOutput.imageSize,
    sourceFile: selectedOutput.sourceFile,
    quantity: selectedOutput.quantity,
    outputFileType: selectedOutput.outputFileType,
    tasks: task.filter((t) => t.outputId === selectedOutput._id),
  }));
}

export const updateTaskStatus = async (
  token: string,
  taskId: string,
  status: string
) => {
  try {
    const { data } = await axios.put(
      `${API_URL}/tasks/${taskId}`,
      {
        status: status,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data, status: true };
  } catch (error) {
    return { error: "Something went wrong!", status: false };
  }
};
export async function updateTaskService(
  taskId: string,
  formData: any,
  token: string
) {
  try {
    const { data } = await axios.put(`${API_URL}/tasks/${taskId}`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}

export const updateTaskStage = async (
  token: string,
  taskId: string,
  stage: string
) => {
  try {
    const { data } = await axios.put(
      `${API_URL}/tasks/${taskId}`,
      {
        stage: stage,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data, status: true };
  } catch (error) {
    return { error: "Something went wrong!", status: false };
  }
};

export const fetchAllTaskByAssignee = (token: string, assignee: string) =>
  axios.get(`${API_URL}/staffs/${assignee}/tasks`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const fetchAllStaffMembers = (token: string) =>
  axios.get(`${API_URL}/staffs`, {
    headers: { Authorization: `Bearer ${token}` },
  });

export const fetchAllClients = (token: string) =>
  axios.get(`${API_URL}/users`, {
    headers: { Authorization: `Bearer ${token}` },
  });

export default function getFullName(first: string, last: string): string {
  return `${first} ${last}`;
}

export async function createTaskService(formData: any, token: string) {
  try {
    const { data } = await axios.post(
      `${API_URL}/tasks`,
      { ...formData, status: !!formData.assignee ? "assigned" : "unassigned" },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { data };
  } catch (error) {
    return { status: error.response.status, error: "Something went wrong!" };
  }
}
