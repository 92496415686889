export const SET_ITEMS = "SET_ITEMS";
export const SET_PROJECT_INFO = "SET_PROJECT_INFO";
export const SET_SELECTED_ASSETS = "SET_SELECTED_ASSETS";
export const SET_SELECTED_OUTPUTS = "SET_SELECTED_OUTPUTS";
export const SET_SELECTED_STYLES = "SET_SELECTED_STYLES";
export const SET_SUPPORTING_DOCUMENTS = "SET_SUPPORTING_DOCUMENTS";
export const SET_PROJECT_ID = "SET_PROJECT_ID";
export const SET_DRAFT_PROJECTS = "SET_DRAFT_PROJECTS";
export const SET_PROJECT_WIZARD = "SET_PROJECT_WIZARD";
export const SET_NEW_SELECTED_ASSETS = "SET_NEW_SELECTED_ASSETS";
export const SET_COMPLETION_STAGE = "SET_COMPLETION_STAGE";
export const SET_HAS_PROJECT_INITIALIZED = "SET_HAS_PROJECT_INITIALIZED";
export const SET_PROJECT_STATUS = "SET_PROJECT_STATUS";

// PROJECT INFO
export const SET_PROJECT_NAME = "SET_PROJECT_NAME";
export const SET_PROJECT_CREATEDBY = "SET_PROJECT_CREATEDBY";
export const SET_PROJECT_STREET_ADDRESS = "SET_PROJECT_STREET_ADDRESS";
export const SET_PROJECT_DESCRIPTION = "SET_PROJECT_DESCRIPTION";
export const SET_PROJECT_ADDRESS_LINE_1 = "SET_PROJECT_ADDRESS_LINE_1";
export const SET_PROJECT_ADDRESS_LINE_2 = "SET_PROJECT_ADDRESS_LINE_2";
export const SET_PROJECT_CITY = "SET_PROJECT_CITY";
export const SET_PROJECT_STATE = "SET_PROJECT_STATE";
export const SET_PROJECT_POSTAL_CODE = "SET_PROJECT_POSTAL_CODE";
export const SET_PROJECT_BEDROOMS = "SET_PROJECT_BEDROOMS";
export const SET_PROJECT_BATHROOMS = "SET_PROJECT_BATHROOMS";
export const SET_PROJECT_STORIES = "SET_PROJECT_STORIES";
export const SET_PROJECT_SIZE = "SET_PROJECT_SIZE";
export const SET_PROJECT_WIDTH = "SET_PROJECT_WIDTH";
export const SET_PROJECT_DEPTH = "SET_PROJECT_DEPTH";
export const SET_PROJECT_COUNTRY = "SET_PROJECT_COUNTRY";
