/////////////////////
// Completed Projects Page
/////////////////////

// Basic Imports
import { FC, Fragment } from "react";

// Design Imports
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Layout and Section Imports
import DragDropTable from "components/sections/ProjectsSection/Table/DragDropTable";
import { columns } from "components/sections/ProjectsSection/Table/Body";

// Data Imports
import {
  fetchCompletedProjectsAxiosRequest,
  ProjectPage,
} from "services/projectsService";
import useFetchProjects from "hooks/useFetchProjects";
import { REACT_QUERY_KEYS } from "settings/constants/ReactQuery";

// Interfaces

// Custom Hooks and Services
import useAuth from "hooks/useAuth";

// Functions

const CompletedProjects = ({ notificationData, isConnected }: ProjectPage) => {
  const { auth, user }: any = useAuth();
  const { projects, isLoading, onChangePriorities, setProjects } =
    useFetchProjects(
      [REACT_QUERY_KEYS.ACTIVE_PROJECTS, user?._id, auth?.accessToken],
      () =>
        fetchCompletedProjectsAxiosRequest(
          auth?.accessToken || "",
          user?._id || ""
        ),
      { enabled: !!user?._id }
    );

  if (!!isLoading) return <SkeletonLoading />;
  return (
    <Fragment>
      <DragDropTable
        columns={columns()}
        notificationData={notificationData}
        data={projects.filter((p) => p.state === "completed")}
        setData={setProjects}
        updateDataOnPriority={onChangePriorities}
      />
    </Fragment>
  );
};

export default CompletedProjects;
