export enum ERRORS {
  GENERIC = "Something went wrong...",
  ACCOUNT = "Something wrong with user account",
  USER = "User is not a staff member",
  INVALID_TOKEN = "Invalid token! Try again...",
  DUPLICATE_ATTACHMENTS = "Duplicate attachments are not allowed",
  FIELDS_REQUIRED = "The fields are required",
  UPLOAD_IMAGE = "Something wrong while uploading an image.",
  UPDATE_EMAIL = "Something wrong while updating email.",
  INVALID_DATE = "Invalid Date! Choose an past date...",
  DELETE_PROJECT = "Something wrong while deleting project.",
  UPDATE_PROJECT = "Something wrong while updating project.",
  SCHEMA_ERROR = "Invalid schema!",
  ASSET_UPLOAD_ERROR = "Please upload atleast one attachment",
}

export enum SUCCESS {
  LOGGED_IN = "Logged in successfully",
  ACCOUNT_VERIFIED = "Account is verified!",
  PASSWORD_RESET = "Password has been reset!",
  ATTACHMENT_DELETED = "Attachment has been deleted.",
  PROJECT_PAUSED = "Project has been paused",
  PROJECT_MARKED_AS_COMPLETED = "Project has been marked as completed",
  PROJECT_RESUMED = "Project has been resumed successfully",
  PROJECT_SUBMITTED = "Project has been submitted successfully",
  PROJECT_REVERTED_TO_ACTIVE = "Project has been reverted to active project",
  PROFILE_UPDATED = "Profile updated successfully!",
  COMMENT_UPLOADED = "Comment uploaded successfully",
  ASSET_UPLOADED = "Asset uploaded successfully",
  PROJECT_DELETED = "Deleted project draft successfully.",
  SAVED_AS_DRAFT = "Saved as draft successfully.",
  EMAIL_CHANGED = "Email changed successfully",
  PASSWORD_CHANGED = "Password changed successfully",
  RECEIPT_SUBMITTED = "Receipt Request submitted successfully!",
  PROJECT_UPDATED = "Project has been updated",
  TASK_UPDATED = "Task has been updated",
  TASK_CREATED = "Task has been created successfully",
  ASSET_DELETED = "Asset deleted successfully",
}
