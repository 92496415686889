/////////////////////
// Use Fetch Task Hook
/////////////////////

// Basic Imports
import { useEffect, useState } from "react";

// Design Imports
import useCustomToast from "./useCustomToast";

// Layout and Section Imports

// Data Imports
import { useQuery, UseQueryOptions } from "react-query";

// Custom Hooks and Services
import { updateProjectsOrder } from "services/projectsService";

// Interfaces
import { project_table_schema } from "schemas/project_table";
import { ERRORS } from "settings/constants/toastMessages";

// Functions

const useFetchTasks = (
  queryKey: any[],
  axiosReqFn: any,
  queryOptions?: UseQueryOptions
) => {
  const { isLoading, error, data, isFetching, refetch }: any = useQuery(
    queryKey,
    axiosReqFn,
    { refetchOnWindowFocus: false, ...queryOptions }
  );
  const task = data?.data?.data?.tasks;

  return {
    task,
    isLoading: isLoading || isFetching,
    error,
    refetch,
  };
};

export default useFetchTasks;
