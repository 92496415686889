import { ProjectStatusesProps } from "types/project";

// type ReturnTypes = ["edit", "deleted", "active"] | ["complete", "paused", "deleted"] | ["download", "revert", "deleted"] | ["resume", "deleted"]
type Status =
  | "edit"
  | "deleted"
  | "active"
  | "complete"
  | "paused"
  | "download"
  | "revert"
  | "resume";
type ReturnTypes = Status[];
export function getButtonsFromProjectStatus(
  projectStatus: ProjectStatusesProps
): ReturnTypes {
  /**
   * // Conclusion
   * Draft --------> Edit, Delete, Active
   * Active -------> Paused, Complete, Pause, Delete
   * Completed ----> Download, Revert, Delete
   * Paused -------> Resume, Delete
   */

  if (projectStatus === "draft") return ["edit", "deleted", "active"];
  if (projectStatus === "active")
    return ["edit", "complete", "paused", "deleted"];
  if (projectStatus === "completed") return ["download", "revert", "deleted"];
  if (projectStatus === "paused") return ["resume", "deleted"];
}