import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { stage2D, stage3D, stageOther } from "services/assetService";
import getFullName, { updateTaskService } from "services/taskService";
import FormInputField from "./FormInputField";
import FormPopoverSelectField from "./FormPopoverSelectField";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";

interface YupSchemaProps {
  isAssetAvailable: boolean;
}

// Validation Schema
const schema = ({ isAssetAvailable }: YupSchemaProps) =>
  yup.object().shape({
    name: yup.string().required().label("Name"),
    description: yup.string().required().label("Description"),
    assignee: yup.string().required().label("Assignee"),
    stage: yup.string().required().label("Stage"),
    ...(isAssetAvailable
      ? { assetId: yup.string().required().label("Asset") }
      : {}), // Dynamic: only show this isAssetAvailable is true
  });

const TaskEditModal = ({
  isOpen,
  onClose,
  taskId,
  defaultValues,
  assetType,
  staffMemebrs,
  allAssets,
  token,
  fetchFlag,
}) => {
  const renderStageList = (assetType: any) => {
    if (assetType) {
      if (assetType.includes("2D")) return stage2D;
      if (assetType.includes("3D")) return stage3D;
      if (!assetType.includes("3D") && !assetType.includes("2D"))
        return stageOther;
    } else {
      return stageOther;
    }
  };
  const { successToast, errorToast } = useCustomToast();

  const updateTask = async (formData: any) => {
    const { data, status } = await updateTaskService(taskId, formData, token);
    if (!!data) successToast({ title: SUCCESS.TASK_UPDATED });
    if (!!status) errorToast({ title: ERRORS.GENERIC });
    fetchFlag();
  };
  const methods = useForm<any>({
    defaultValues: defaultValues,
    resolver: yupResolver(
      schema({
        isAssetAvailable: false,
      })
    ),
  });

  const { handleSubmit } = methods;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <FormProvider {...methods}>
          <Box
            as="form"
            bg="bg-surface"
            borderRadius="lg"
            w="full"
            onSubmit={handleSubmit((formData: any) => {
              updateTask(formData);
            })}
          >
            <ModalContent>
              <ModalHeader>Edit Task</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Text fontWeight="bold" textAlign="center">
                  {assetType ? assetType : ""}
                </Text>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "4" }}
                  py={{ base: "5", md: "6" }}
                >
                  <FormInputField
                    name="name"
                    label="Title"
                    variant="input"
                    placeholder="Name for subtask"
                  />
                  <FormInputField
                    name="description"
                    label="Description"
                    variant="textarea"
                    placeholder="Description for the task"
                  />
                  <FormPopoverSelectField
                    calledFor="staff"
                    label="Assign to"
                    name="assignee"
                    list={staffMemebrs.map((staffMember: any) => ({
                      id: staffMember._id,
                      label: getFullName(
                        staffMember?.firstName || "",
                        staffMember?.lastName || ""
                      ),
                      description: staffMember.email,
                      avatar: staffMember?.profile?.avatar || "",
                    }))}
                  />
                  {/* {allAssets && allAssets.length > 0 ? (
                    <FormInputField
                      name="assetId"
                      label="Asset"
                      variant="select"
                      placeholder="Select asset"
                      list={allAssets.map((asset) => ({
                        label: asset.title,
                        value: asset.title,
                      }))}
                    />
                  ) : null} */}
                  <FormInputField
                    name="stage"
                    label="Stage"
                    variant="select"
                    placeholder="Select stage"
                    list={renderStageList(assetType).map((stage: any) => ({
                      label: stage.display,
                      value: stage.display,
                    }))}
                  />
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Box>
        </FormProvider>
      </Modal>
    </>
  );
};

export default TaskEditModal;
