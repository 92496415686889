/////////////////////
// Steps Hook
/////////////////////

// Basic Imports
import { useState } from "react";

// Interfaces
interface Options {
  initialStep: number;
} 

// Functions
export const useSteps = (options: Options) => {
  const { initialStep } = options;
  const [activeStep, setActiveStep] = useState(initialStep);

  const nextStep = () => setActiveStep((current) => current + 1);
  const prevStep = () => setActiveStep(activeStep - 1);
  const reset = () => setActiveStep(0);
  const setStep = (step: number) => setActiveStep(step)

  return { nextStep, prevStep, reset, activeStep, setStep };
};
