/////////////////////
// Theme Foundations
/////////////////////
import { theme } from "@chakra-ui/react";

const foundations = {
  colors: {
    ...theme.colors,
    primary: "blue",
    secondary: "blue.400",
    defaultLight: "gray.800",
    defaultDark: "gray.200",
    //dark: theme.colors.blackAlpha[400],
    /** Example */
    // teal: {
    //   ...theme.colors.teal,
    //   700: "#005661",
    //   500: "#00838e",
    //   300: "#4fb3be",
    // },
  },

  layerStyles: {
    base: {
      bg: "pink",
      border: "2px solid",
      borderColor: "purple",
    },
    selected: {
      bg: "red",
      color: "green",
      borderColor: "blue",
    },
  },
  fonts: {
    ...theme.fonts,
    /** Example */
    // body: "Work Sans, sans-serif",
    // heading: "Markazi Text, serif",
  },
  borders: {
    ...theme.borders,
    primary: {
      borderColor: "red",
      // ".carouselSliderBorder": {
      //   //   border: props.colorMode === "light" ? props.theme.borders['2px'] : props.theme.borders['4px'],
      //   //   borderColor: props.colorMode === "light" ? "gray.800" : "#DCC87F"
      //   // },
    },
  },
};

export default foundations;
