/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Avatar,
  Box,
  CloseButton,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import FuzzySearch from "fuzzy-search";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import { useProject } from "context/project/ProjectProvider";

interface ListItemProps {
  label: string;
  description: string;
  avatar?: string;
  id: string | number;
}
interface Props {
  list: ListItemProps[];
  name: string;
  label?: string;
  calledFor?: string;
}
const FormPopoverSelectField: FC<Props> = ({
  list,
  name,
  label,
  calledFor,
}) => {
  const {
    //    projectWizard,
    onChangeProjectClient,
  } = useProject();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<any>();
  const [inputValue, setInputValue] = useState("");
  const [selectedItem, setSelectedItem] = useState<ListItemProps | null>(null);
  const listSearcher = new FuzzySearch(list, ["label", "description", "id"]);

  useEffect(() => {
    const selectedValue = getValues()[name];
    const targetItem = list.find(
      (listItem: ListItemProps) => listItem.id === selectedValue
    );
    setSelectedItem(targetItem || null);
  }, [getValues()[name]]);

  useEffect(() => {
    if (!selectedItem) {
      setInputValue("");
      return;
    }

    if (!isOpen && !!selectedItem) {
      setInputValue(selectedItem.label);
    }
  }, [selectedItem, isOpen]);

  const errorMessage = (_.get(errors, name)?.message || "") as string;
  return (
    <Box w="full">
      <Popover
        isLazy
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-start"
        autoFocus={false}
      >
        <PopoverTrigger>
          <FormControl id="assignee">
            {label ? <FormLabel>{label}</FormLabel> : null}
            <Input
              placeholder={
                calledFor === "clients" ? "Select owner" : "Assign to"
              }
              hidden
            />
            <InputGroup>
              <Input
                onClick={onOpen}
                placeholder="Assign to "
                variant={errorMessage ? "error" : "outline"}
                onChange={(e) => {
                  setInputValue(e.currentTarget.value);
                  onOpen();
                }}
                value={inputValue}
              />
              <InputRightElement width="4.5rem">
                <CloseButton
                  onClick={() => {
                    setSelectedItem(null);
                    setValue(name, "", {
                      shouldValidate: true,
                    });
                  }}
                />
              </InputRightElement>
            </InputGroup>
            {errors[name] ? (
              <Text color="red" mt="1">
                {errors[name]?.message as string}
              </Text>
            ) : null}
          </FormControl>
        </PopoverTrigger>
        <PopoverContent zIndex="popover">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody p={3} maxH={"140px"} overflow="auto" w="full">
            <Stack spacing={3} w="full">
              {listSearcher
                .search(inputValue)
                .map((listItem: ListItemProps) => {
                  return (
                    <HStack
                      key={listItem.label}
                      cursor="pointer"
                      userSelect="none"
                      align="flex-start"
                      onClick={() => {
                        setInputValue(listItem.label);
                        if (calledFor === "clients") {
                          onChangeProjectClient(listItem.id);
                        }
                        setValue(name, listItem.id, {
                          shouldValidate: true,
                        });
                        onClose();
                      }}
                    >
                      <Avatar
                        size="xs"
                        name={listItem.label}
                        src={listItem.avatar}
                      />
                      <Stack spacing={0}>
                        <Text
                          noOfLines={1}
                          fontSize="sm"
                          fontWeight="medium"
                          minW={"40%"}
                        >
                          {listItem.label}
                        </Text>
                        <Text color="GrayText" fontSize="xs" noOfLines={1}>
                          {listItem.description}
                        </Text>
                      </Stack>
                    </HStack>
                  );
                })}

              {listSearcher.search(inputValue).length === 0 ? (
                <Text fontSize="xs" fontWeight="bold" minW={"40%"}>
                  No results found...
                </Text>
              ) : null}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default FormPopoverSelectField;
