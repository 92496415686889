/////////////////////
// Dashboard Container
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Container, ContainerProps } from "@chakra-ui/react";

// Interfaces
interface Props extends ContainerProps {
  isProjectContainer?: boolean;
}

// Functions
const DashboardContainer: FC<Props> = ({
  children,
  isProjectContainer,
  ...restProps
}) => {
  return (
    <Container
      overflow="auto"
      maxW="container.xl"
      mx="auto"
      //my="6"
      px={{ base: "2", md: "6" }}
      rounded="md"
      {...restProps}
    >
      {children}
    </Container>
  );
};

export default DashboardContainer;
