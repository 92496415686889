import useAuth from "hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "settings/constants/routes";

const AuthPagesLayout = () => {
  // @ts-ignore
  const { auth } = useAuth();
  const location = useLocation();

  if (!!auth?.accessToken)
    return (
      <Navigate
        to={ROUTES.PROJECTS_ACTIVE}
        state={{ from: location }}
        replace
      />
    );
  return <Outlet />;
};

export default AuthPagesLayout;
