import {
  FaInfoCircle,
  FaPause,
  FaUserAlt,
  FaUserAltSlash
} from 'react-icons/fa'
import {
    BsQuestionOctagonFill,
} from 'react-icons/bs'
import {
    GrDocumentMissing,
} from 'react-icons/gr'

export interface ProjectData {
  id: string
  name: string
  state: string
  priority?: number
  completion: {
    stage_name: string
    percent:number
    color:string
  }
  next_step: {
    icon?: React.ElementType
    icon_status: 'error' | 'info' | 'warning' | 'success'
    text: string
    color?: string
    w?: number
    h?: number
    mr?: number
  }
  information: {
    percent:number
    color:string
  }
}

export const projectData: ProjectData[] = [
  {
    id: '1',
    name: 'PROJECT 123',
    state: 'active',
    priority: 3,
    completion: {
      stage_name: 'Info Gathering',
      percent: 20,
      color: 'red',
    },
    next_step: {
      icon: BsQuestionOctagonFill,
      icon_status: 'error',
      text: 'A question is awaiting your response.',
      color:"red.600",
      mr:3,
    },
    information: {
      percent:95,
      color:'green',
    },
  },
  {
    id: '2',
    name: 'PROJECT 124',
    state: 'active',
    priority: 4,
    completion: {
      stage_name: 'Info Gathering',
      percent: 10,
      color: 'red',
    },
    next_step: {
      icon: BsQuestionOctagonFill,
      icon_status: 'error',
      text: 'A question is awaiting your response.',
      color:"red.600",
      mr:3,
    },
    information: {
      percent:75,
      color:'green',
    },
  },
  {
    id: '3',
    name: 'PROJECT 125',
    state: 'active',
    priority: 5,
    completion: {
      stage_name: 'Info Gathering',
      percent: 10,
      color: 'red',
    },
    next_step: {
      icon: BsQuestionOctagonFill,
      icon_status: 'error',
      text: 'A question is awaiting your response.',
      color:"red.600",
      mr:3,
    },
    information: {
      percent:45,
      color:'yellow',
    },
  },
  {
    id: '4',
    name: 'PROJECT 211',
    state: 'active',
    priority: 1,
    completion: {
      stage_name: 'Modelling',
      percent: 30,
      color: 'yellow',
    },
    next_step: {
      icon: FaUserAlt,
      icon_status: 'info',
      text: 'Currently being worked on.',
      color:"blue.500",
      mr:3,
    },
    information: {
      percent:45,
      color:'yellow',
    },
  },
  {
    id: '5',
    name: 'PROJECT 212',
    state: 'active',
    priority: 2,
    completion: {
      stage_name: 'Materials',
      percent: 40,
      color: 'yellow',
    },
    next_step: {
      icon: FaUserAltSlash,
      icon_status: 'warning',
      text: 'Waiting on available queue slot.',
      color:"orange.300",
      w: 5,
      h: 5,
      mr:3,
    },
    information: {
      percent:100,
      color:'green',
    },
  },
  {
    id: '6',
    name: 'PROJECT 333',
    state: 'paused',
    completion: {
      stage_name: 'Materials',
      percent: 40,
      color: 'yellow.400',
    },
    next_step: {
      icon: FaPause,
      icon_status: 'warning',
      text: 'You have placed this project on pause. Click the Resume button to add back to active queue',
      color:"orange.300",
      w: 5,
      h: 5,
      mr:3,
    },
    information: {
      percent:100,
      color:'green',
    },
  },
  {
    id: '7',
    name: 'PROJECT 334',
    state: 'paused',
    completion: {
      stage_name: 'Materials',
      percent: 40,
      color: 'yellow.400',
    },
    next_step: {
      icon: FaPause,
      icon_status: 'warning',
      text: 'You have placed this project on pause. Click the Resume button to add back to active queue',
      color:"orange.300",
      w: 5,
      h: 5,
      mr:3,
    },
    information: {
      percent:100,
      color:'green',
    },
  },
  {
    id: '8',
    name: 'PROJECT 335',
    state: 'paused',
    completion: {
      stage_name: 'Materials',
      percent: 40,
      color: 'yellow.400',
    },
    next_step: {
      icon: FaPause,
      icon_status: 'warning',
      text: 'You have placed this project on pause. Click the Resume button to add back to active queue',
      color:"orange.300",
      w: 5,
      h: 5,
      mr:3,
    },
    information: {
      percent:100,
      color:'green',
    },
  },
  {
    id: '9',
    name: 'PROJECT 336',
    state: 'paused',
    completion: {
      stage_name: 'Materials',
      percent: 40,
      color: 'yellow.400',
    },
    next_step: {
      icon: FaPause,
      icon_status: 'warning',
      text: 'You have placed this project on pause. Click the Resume button to add back to active queue',
      color:"orange.300",
      w: 5,
      h: 5,
      mr:3,
    },
    information: {
      percent:100,
      color:'green',
    },
  },
  {
    id: '10',
    name: 'PROJECT 337',
    state: 'paused',
    completion: {
      stage_name: 'Materials',
      percent: 40,
      color: 'yellow.400',
    },
    next_step: {
      icon: FaPause,
      icon_status: 'warning',
      text: 'You have placed this project on pause. Click the Resume button to add back to active queue',
      color:"orange.300",
      w: 5,
      h: 5,
      mr:3,
    },
    information: {
      percent:100,
      color:'green',
    },
  },
  {
    id: '11',
    name: 'PROJECT 338',
    state: 'paused',
    completion: {
      stage_name: 'Materials',
      percent: 40,
      color: 'yellow.400',
    },
    next_step: {
      icon: FaPause,
      icon_status: 'warning',
      text: 'You have placed this project on pause. Click the Resume button to add back to active queue',
      color:"orange.300",
      w: 5,
      h: 5,
      mr:3,
    },
    information: {
      percent:100,
      color:'green',
    },
  },
  {
    id: '12',
    name: 'PROJECT 339',
    state: 'paused',
    completion: {
      stage_name: 'Materials',
      percent: 40,
      color: 'yellow.400',
    },
    next_step: {
      icon: FaPause,
      icon_status: 'warning',
      text: 'You have placed this project on pause. Click the Resume button to add back to active queue',
      color:"orange.300",
      w: 5,
      h: 5,
      mr:3,
    },
    information: {
      percent:100,
      color:'green',
    },
  },
  {
    id: '13',
    name: 'PROJECT 4444444444444444444444444444',
    state: 'completed',
    completion: {
      stage_name: 'Completed',
      percent: 100,
      color: 'green',
    },
    next_step: {
      icon: FaInfoCircle,
      icon_status: 'info',
      text: 'Files are available to download.',
      color:"blue.500",
      mr:3,

    },
    information: {
      percent:100,
      color:'green',
    },
  },
  {
    id: '14',
    name: 'Untitled 1',
    state: 'draft',
    completion: {
      stage_name: 'Info Gathering',
      percent: 5,
      color: 'red',
    },
    next_step: {
      icon: FaInfoCircle,
      icon_status: 'warning',
      text: 'Information provided is enough for us to start project, but producing results will be slower.',
      color:"orange.300",
      mr:3,
    },
    information: {
      percent:50,
      color:'yellow',
    },
  },
  {
    id: '15',
    name: 'Untitled 2',
    state: 'draft',
    completion: {
      stage_name: 'Info Gathering',
      percent: 0,
      color: 'red',
    },
    next_step: {
      icon: GrDocumentMissing,
      icon_status: 'error',
      text: 'Not enough information to start project.',
      color:"red.600",
      mr:3,
    },
    information: {
      percent:10,
      color:'red',
    },
  },
  {
    id: '16',
    name: 'Untitled 3',
    state: 'draft',
    completion: {
      stage_name: 'Info Gathering',
      percent: 20,
      color: 'red',
    },
    next_step: {
      icon: FaInfoCircle,
      icon_status: 'info',
      text: 'Majority information provided, can submit project.',
      color:"blue.500",
      mr:3,
    },
    information: {
      percent:90,
      color:'green',
    },
  },
]
