import { useEffect, useMemo, useState } from "react";
import { API_URL, SOCKET_API_URL } from "settings/site-credentials";
import io from "socket.io-client";
import useAuth from "./useAuth";
import _ from "lodash";
const useSocket = () => {
  const { token }: any = useAuth();
  const socket = useMemo(
    () =>
      io(SOCKET_API_URL(API_URL), {
        withCredentials: true,
        // path: "/socket.io",
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [token]
  );

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [notificationData, setNotificationData] = useState({
    notifications: [],
    unread: 0,
  });

  useEffect(() => {
    if (!isConnected) {
      socket.on("connect", () => {
        setIsConnected(true);
      });
    }

    // socket.on("all_notifications", (data) => {
    //   setNotificationData(data);
    // });

    // socket.on("disconnect", () => {
    //   setIsConnected(false);
    // });

    return () => {
      // if (!!socket.connected) {
      socket.off("connect");
      socket.off("disconnect");
      // socket.off("all_notifications");
    };
  }, [socket]);

  // BETA: Experimental piece of code
  useEffect(() => {
    if (!isConnected) return;

    socket.on("all_notifications", (data) => {
      setNotificationData(data);
    });

    // socket.on("notification_updated", (data) => {
    //   console.log({ data });
    // });

    return () => {
      socket.off("all_notifications");
    };
  }, [isConnected]);

  return {
    notificationData,
    isConnected,
    onDeleteNotification: (id: string | string[]) => {
      const allNotifications = notificationData.notifications;

      const filteredNotifications =
        typeof id === "string"
          ? allNotifications.filter((n) => n._id !== id)
          : _.differenceBy(
              allNotifications,
              id.map((id) => ({ _id: id })),
              "_id"
            );

      setNotificationData({
        ...notificationData,
        unread: filteredNotifications.length,
        notifications: filteredNotifications,
      });
    },
  };
};

export default useSocket;
