/////////////////////
// Step Context
/////////////////////

// Basic Imports
import * as React from "react";

// Interfaces
export interface Context {
  step: number;
  isActive: boolean;
  isCompleted: boolean;
  isLastStep: boolean;
}

// Functions
export const StepContext = React.createContext<Context | null>(null);
