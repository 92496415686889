/////////////////////
// Toggle Button Group UI
// Only Allows one item of a group to be selected
/////////////////////

// Basic Imports
import * as React from "react";

// Design Imports
import {
  ButtonGroup,
  ButtonGroupProps,
  useRadioGroup,
  Wrap,
} from "@chakra-ui/react";

import { ToggleButtonProps } from "./ToggleButton";
// Layout and Section Imports

// Data Imports

// Interfaces
interface ToggleButtonGroupProps<T> extends Omit<ButtonGroupProps, "onChange"> {
  name?: string;
  value: T;
  defaultValue?: string;
  onChange?: (value: T) => void;
  readOnly?: boolean
}

// Functions
export const ToggleButtonGroup = <T extends string>(
  props: ToggleButtonGroupProps<T>
) => {
  const { children, name, defaultValue, value, onChange, readOnly,  ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const buttons = React.useMemo(
    () =>
      React.Children.toArray(children)
        .filter<React.ReactElement<ToggleButtonProps>>(React.isValidElement)
        .map((button, index, array) => {
          const isFirstItem = index === 0;
          const isLastItem = array.length === index + 1;

          const styleProps = Object.assign({
            ...(isFirstItem && !isLastItem ? { borderRightRadius: 0 } : {}),
            ...(!isFirstItem && isLastItem ? { borderLeftRadius: 0 } : {}),
            ...(!isFirstItem && !isLastItem ? { borderRadius: 0 } : {}),
            ...(!isLastItem ? { mr: "-px" } : {}),
          });

          return React.cloneElement(button, {
            ...styleProps,
            // @ts-ignore
            radioProps: getRadioProps({
              value: button.props.value,
              disabled: props.isDisabled || button.props.isDisabled,
            }),
          });
        }),
    [children, getRadioProps, props.isDisabled]
  );
  return (
    <ButtonGroup isDisabled={readOnly} {...getRootProps(rest)}>
      <Wrap spacing="0">{buttons}</Wrap>
    </ButtonGroup>
  );
};
