const LIGHT = "gray.600";
const DARK = "gray.300";

const colors = {
  light: LIGHT,
  dark: DARK,
  black: "black",
  white: "white",
  text: {
    light: LIGHT,
    dark: DARK,
  },
  error: "red",
  warning: "orange",
  success: "green",
  blue: "blue.600",
};

export default colors;
