/////////////////////
// Require Plan Layout
// Anything with this layout require a plan. 
/////////////////////

// Basic Imports
import { useLocation, Navigate, Outlet } from "react-router-dom";

// Design Imports


// Layout and Section Imports


// Data Imports


// Custom Hooks and Services
import useAuth from "hooks/useAuth";
import { ROUTES } from "settings/constants/routes";

// Interfaces


// Functions

const RequirePlanLayout = () => {
  // @ts-ignore
  const { user } = useAuth();
  const location = useLocation();
 
  if(location.pathname === ROUTES.ACCOUNT_BILLING){
    return  <Outlet />;
  }

  // ! Remove the negation for production so it prevents accessing the page without a plan
  return !user?.hasPlan ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.ACCOUNT_BILLING}   />
  );
};

export default RequirePlanLayout;
