/////////////////////
// Use Auth Form
/////////////////////

// Basic Imports
import { useState } from "react";
import { useParams } from "react-router-dom";

// Design Imports

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services
import useAuth from "hooks/useAuth";

import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";
import {
  addAttchementtoAsset,
  createAsset,
  updateAssetinDB,
} from "services/assets";

// Interfaces
export type SignUpUserTypes = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  isLoading: boolean;
  error: string;
};

// Functions
const useAssetForm = () => {
  const { auth }: any = useAuth();
  const { id: projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const { successToast, errorToast } = useCustomToast();

  const checkAssetForAttchement = (
    asset: any,
    requestAsset: any,
    quantity: string | number
  ) => {
    if (asset.length === 0) return [];
    requestAsset.quantity = quantity;
    const parent = asset.filter(
      (a) =>
        Number(a.quantity) === Number(requestAsset.quantity) &&
        a.assetType === requestAsset.assetType &&
        a.title.replaceAll(" ", "").toLowerCase() ===
          requestAsset.title.replaceAll(" ", "").toLowerCase()
    );
    return parent;
  };

  const updateAsset = async ({
    formData,
    attachments,
    assetQuantity,
    parent,
  }) => {
    setLoading(true);
    try {
      const { data: attachment, error: e } = await addAttchementtoAsset(
        {
          formData,
          projectId: projectId,
          attachments,
          assetQuantity,
        },
        auth.accessToken
      );
      const { data, error } = await updateAssetinDB(
        {
          formData,
          projectId: projectId,
          attachments: [
            ...parent.attachments.map((a) => a._id),
            attachment.attachment._id,
          ],
          assetQuantity,
        },
        auth.accessToken,
        parent?._id
      );

      if (!!e) throw new Error("Something wrong while creating comment");
      successToast({ title: SUCCESS.ASSET_UPLOADED });
      return { attachment };
    } catch (error) {
      console.log({ error });
      errorToast({ title: ERRORS.GENERIC });
      return { error: "Something went wrong..." };
    } finally {
      setLoading(false);
    }
  };

  const handleUploadAsset = async ({
    formData,
    attachments,
    assetQuantity,
  }) => {
    setLoading(true);
    try {
      const { data: attachment, error: e } = await addAttchementtoAsset(
        {
          formData,
          projectId: projectId,
          attachments,
          assetQuantity,
        },
        auth.accessToken
      );
      const { data, error } = await createAsset(
        {
          formData,
          projectId: projectId,
          attachments: [attachment.attachment._id],
          assetQuantity,
        },
        auth.accessToken
      );
      //code

      if (!!error) throw new Error("Something wrong while creating comment");

      successToast({ title: SUCCESS.ASSET_UPLOADED });

      return { attachment };
    } catch (error) {
      console.log({ error });
      errorToast({ title: ERRORS.GENERIC });
      return { error: "Something went wrong..." };
    } finally {
      setLoading(false);
    }
  };
  return {
    handleUploadAsset: handleUploadAsset,
    updateAsset: updateAsset,
    uploadLoading: loading,
    checkAssetForAttchement: checkAssetForAttchement,
  };
};

export default useAssetForm;
