/////////////////////
// Button Radio UI
/////////////////////

// Basic Imports
import * as React from "react";

// Design Imports
import {
  Box,
  chakra,
  Image,
  Text,
  useRadio,
  UseRadioProps,
  VStack,
  useColorModeValue as mode,
  useId,
  useCheckbox,
  RadioProps,
  CheckboxProps,
} from "@chakra-ui/react";

import {
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
} from "react-icons/md";

// Layout and Section Imports

// Data Imports
const RadioBox = chakra("div", {
  baseStyle: {
    borderWidth: "3px",
    px: "3",
    pt: "3",
    pb: "3",
    borderRadius: "md",
    cursor: "pointer",
    transition: "all 0.2s",
    _focus: { shadow: "outline" },
  },
});

// Interfaces
interface ButtonRadioProps extends UseRadioProps {
  icon: React.ReactElement;
  label: string;
  description: string;
}

interface ButtonRadioImgProps extends UseRadioProps {
  image: string;
  label: string;
  description: string;
}

// Functions
interface RadioBoxIconProps extends RadioProps {
  checked: boolean;
}
const RadioboxIcon: React.FC<RadioBoxIconProps> = (props) => (
  <Box
    fontSize="4xl"
    color={
      props.checked
        ? props.isDisabled
          ? "blue.200"
          : "blue.600"
        : props.isDisabled
        ? mode("gray.100", "whiteAlpha.200")
        : mode("gray.300", "whiteAlpha.400")
    }
  >
    {props.checked ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
  </Box>
);

interface CheckboxIconProps extends CheckboxProps {
  checked: boolean;
}
const CheckboxIcon: React.FC<CheckboxIconProps> = (props) => (
  <Box
    fontSize="4xl"
    color={
      props.checked
        ? props.isDisabled
          ? "blue.200"
          : "blue.600"
        : props.isDisabled
        ? mode("gray.100", "whiteAlpha.200")
        : mode("gray.300", "whiteAlpha.400")
    }
  >
    {props.checked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
  </Box>
);

export const ButtonRadio = (props: ButtonRadioProps) => {
  const { label, icon, description } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } =
    useRadio(props);
  const id = useId();

  const checkedStyles = {
    bg: mode("blue.50", "rgb(0 31 71)"),
    borderColor: "blue.600",
  };

  return (
    <label style={{ width: "100%" }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox {...getCheckboxProps()} _checked={checkedStyles} id={id}>
        <VStack spacing="4">
          <VStack textAlign="center">
            <Box
              aria-hidden
              fontSize="4xl"
              mb="3"
              color={state.isChecked ? "blue.600" : undefined}
            >
              {icon}
            </Box>
            <Text fontWeight="extrabold" fontSize="xl">
              {label}
            </Text>
            <Text fontSize="sm">{description}</Text>
          </VStack>
          <RadioboxIcon checked={state.isChecked} />
        </VStack>
      </RadioBox>
    </label>
  );
};

export const ButtonRadioImg = (props: ButtonRadioImgProps) => {
  const { label, image, description } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } =
    useRadio(props);
  const id = useId();

  const checkedStyles = {
    bg: mode("blue.50", "rgb(0 31 71)"),
    borderColor: "blue.600",
  };

  return (
    <label style={{ width: "100%" }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox
        cursor={state.isReadOnly ? "not-allowed" : "pointer"}
        {...getCheckboxProps()}
        _checked={checkedStyles}
        id={id}
      >
        <VStack>
          <Box
            aria-hidden
            fontSize="4xl"
            color={state.isChecked ? "blue.600" : undefined}
          ></Box>
          <Image
            opacity={state?.isReadOnly ? 0.5 : 1}
            src={image}
            alt={label}
          />
          <Text fontWeight="extrabold" fontSize="xl">
            {label}
          </Text>
          <Text fontSize="sm" color="inherit">
            {description}
          </Text>
          <RadioboxIcon
            isDisabled={state.isReadOnly}
            checked={state.isChecked}
          />
        </VStack>
      </RadioBox>
    </label>
  );
};

export const ButtonCheckboxImg = (props: ButtonRadioImgProps) => {
  const { label, image, description } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } =
    useCheckbox(props);
  const id = useId();

  const checkedStyles = {
    bg: mode("blue.50", "rgb(0 31 71)"),
    borderColor: "blue.600",
  };

  return (
    <label style={{ width: "100%" }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox {...getCheckboxProps()} _checked={checkedStyles} id={id}>
        <VStack>
          <Box
            aria-hidden
            fontSize="4xl"
            color={state.isChecked ? "blue.600" : undefined}
          ></Box>
          <Image
            opacity={state?.isReadOnly ? 0.5 : 1}
            src={image}
            alt={label}
          />
          <Text fontWeight="extrabold" fontSize="xl">
            {label}
          </Text>
          <Text fontSize="sm">{description}</Text>
          <CheckboxIcon
            isDisabled={state.isReadOnly}
            checked={state.isChecked}
          />
        </VStack>
      </RadioBox>
    </label>
  );
};
