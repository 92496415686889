// Design imports
import { Wrap, useCheckboxGroup } from "@chakra-ui/react";
import { useEffect, FC } from "react";
import { ButtonCheckbox } from "./Checkbox";

// Interface imports
interface Props {
  options: string[];
  defaultValue?: string[];
  onChange?: (value: any) => void;
  readOnly?: boolean
}
const ButtonCheckboxApp: FC<Props> = ({ options, defaultValue, onChange, readOnly }) => {  
  const { getCheckboxProps, value, setValue } = useCheckboxGroup({
    defaultValue: defaultValue ? defaultValue : [options[0]],
  });

  /**
   * 1. If not readOnly, then do nothing just break it
   * 2. If readOnly then update the values on defaultValue props change
   */
  useEffect(() => {
    if (!readOnly) return
    setValue(defaultValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  /**
   * 1. If it is readOnly or doesn't have onChange function then no need to execute onChange function that update the project wizard.
   */
  useEffect(() => {
    if (!!readOnly || !onChange) return;

    onChange(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Wrap>
      {options.map((value: any) => (
        <ButtonCheckbox readOnly={readOnly} key={value} {...getCheckboxProps({ value, isDisabled: readOnly })}>
          {value}
        </ButtonCheckbox>
      ))}
    </Wrap>
  );
};

export default ButtonCheckboxApp;
