/////////////////////
// Custom Theme Components
/////////////////////

// Data Imports
import getChakraColorMode from "utils/getChakraColorMode";
import colors from "../../settings/theme/colors";

const components = {
  /* Button */
  Button: {
    baseStyle: (props) => ({
      boxShadow: "none",
      _focus: {
        boxShadow: "none",
      },
      color: getChakraColorMode(props) === "light" ? colors.text.light : colors.text.dark,
    }),
  },

  /* Form label */
  FormLabel: {
    baseStyle: (props) => ({
      color: getChakraColorMode(props) === "light" ? colors.text.light : colors.text.dark,
    }),
  },

  /* Select input fields */
  Select: {
    baseStyle: (props) => ({
      field: {
        color:
          getChakraColorMode(props) === "light" ? colors.text.light : colors.text.dark,
        colorScheme: "gray",
      },
      icon: {
        color: getChakraColorMode(props) === "light" ? colors.light : colors.dark,
      },
    }),
  },

  /* Text */
  Text: {
    baseStyle: (props) => ({
      color: getChakraColorMode(props) === "light" ? colors.text.light : colors.text.dark,
    }),
    variants: {
      "hero-description": (props) => ({
        textColor: getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        fontStyle: "italic",
        fontSize: "xl",
        mt: "4",
        maxW: "xl",
      }),
      "section-description": (props) => ({
        textColor: getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        fontStyle: "italic",
        fontSize: "md",
        fontWeight: "normal",
      }),
      textBox: (props) => ({
        textColor: getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        fontSize: "lg",
        fontWeight: "normal",
        mx: "5",
        my: "3",
      }),
      "header-prefix": (props) => ({
        textTransform: "uppercase",
        fontWeight: "semibold",
        color: "blue.400",
        letterSpacing: "wide",
      }),
    },
  },

  /* Heading */
  Heading: {
    baseStyle: {
      textTransform: "uppercase",
    },
    variants: {
      "light-weight": {
        fontWeight: "normal",
      },
      "hero-title": (props) => ({
        size: "2xl",
        textColor: getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        textTransform: "uppercase",
        fontWeight: "extrabold",
      }),

      "section-title": (props) => ({
        textColor: getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        textAlign: "center",
        letterSpacing: "tight",
        fontWeight: "extrabold",
      }),
      head1: (props) => ({
        textColor: getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        my: { base: "4", md: "10" },
        fontSize: "2xl",
      }),
      head2: (props) => ({
        textColor: getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        my: "2",
        fontSize: "xl",
      }),
    },
  },
};

export default components;
