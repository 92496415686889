/////////////////////
// ProfileDropdown (appMenu) UI
/////////////////////

// Basic Imports


// Design Imports
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useMenuButton,
  UseMenuButtonProps,
  useColorModeValue as mode,
  AvatarBadge,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

import SkeletonLoading from "../Loaders/SkeletonLoading";

// Layout and Section Imports

// Data Imports
import useAuth from "hooks/useAuth";
import { ROUTES } from "../../../constants";
import {
  //  getUserAccountDetails, 
  removeCustomerEmailIntoLocalStorage,
  removeCustomerTokenFromLocalStorage
} from "services/authService";
import { useDispatch } from "react-redux";


// Interfaces

// Functions

const ProfileMenuButton = (props: UseMenuButtonProps) => {
  const buttonProps = useMenuButton(props);
  // @ts-ignore
  const { user } = useAuth();

  return (
    <Flex
      as="button"
      {...buttonProps} //TEMP:COMBAK: It was creating a warning due to button props in Flex.
      flexShrink={0}
      rounded="lg"
      outline="0"
      _focus={{ shadow: "outline" }}
    >
      <Box srOnly>Open user menu</Box>
      <Avatar border={'2px solid #3182ce'} size="sm" src={user?.profile && user?.profile.avatar} name={user?.firstName} ><AvatarBadge boxSize='1.25em' bg='green.500' /></Avatar>
    </Flex>
  );
};

export const ProfileDropdown = () => {
  // @ts-ignore
  const { user } = useAuth();

  const dispatch = useDispatch()

  const handleLogout = async () => {
    // Remove token from localStorage
    removeCustomerTokenFromLocalStorage();
    removeCustomerEmailIntoLocalStorage();
    dispatch({ payload: null, type: "RESET" })
    window.open("/login", "_self");
  };
  return (
    <Menu>
      <ProfileMenuButton />
      <MenuList
        rounded="md"
        shadow="lg"
        py="1"
        color={mode("gray.600", "inherit")}
        fontSize="sm"
        zIndex={99}
      >
        {!user ? <SkeletonLoading /> :
          <HStack px="3" py="4">
            <Avatar size="sm" src={user?.profile && user?.profile.avatar} name={user?.firstName} />
            <Box lineHeight="1">
              <Text align="left" fontWeight="semibold">
                {user?.firstName + ' ' + user?.lastName}
              </Text>
              <Text mt="1" fontSize="xs" color="gray.500">
                {user?.email}
              </Text>
            </Box>
          </HStack>
        }
        {/* <Link to={ROUTES.ACCOUNT}>
        <MenuItem
          color={mode("black.800", "whiteAlpha.800")}
          fontWeight="medium"
        >
          Account Settings
        </MenuItem>
        </Link> */}
        {/* <Link to={ROUTES.ACCOUNT_BILLING}>
        <MenuItem
          color={mode("black.800", "whiteAlpha.800")}
          fontWeight="medium"
        >
         Billing
        </MenuItem>
        </Link> */}
        <Link to={ROUTES.ACCOUNT}>
          <MenuItem
            color={mode("black.800", "whiteAlpha.800")}
            fontWeight="medium"
          >
            Account Settings
          </MenuItem>
        </Link>
        <MenuItem
          onClick={handleLogout}
          fontWeight="medium"
          color={mode("red.500", "red.300")}
        >
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
