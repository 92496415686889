/////////////////////
// Login Page
/////////////////////

// Layout and Section Imports
import { LoginForm } from "../../../components/forms/AuthForm/LoginForm";
import AuthLayout from "components/layouts/AuthLayout";

const LoginPage = () => (
  <AuthLayout>
    <LoginForm
      px={{ base: "4", md: "5" }}
      py={{ base: "10", md: "38" }}
      width="full"
      maxW="md"
    />
  </AuthLayout>
);

export default LoginPage;
