/////////////////////
// Checkbox Box UI
/////////////////////

// Basic Imports
import * as React from "react";

// Design Imports
import { Box, BoxProps, useColorModeValue as mode } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports

// Interfaces

interface CheckboxBoxDisProps extends BoxProps {
  icon?: React.ReactElement;
  title?: string;
  description?: string;
  isDisable?: boolean;
  children?: React.ReactNode;
}
// Functions
export const CheckboxBox = (props: CheckboxBoxDisProps) => (
  <Box
    borderWidth="2px"
    px="4"
    py="3"
    borderRadius="md"
    cursor="pointer"
    transition="all 0.2s"
    _focus={{ shadow: "outline" }}
    _checked={{
      bg: mode("gray.50", "whiteAlpha.100"),
      borderColor: mode("blue.500", "blue.300"),
    }}
    {...props}
  />
);
