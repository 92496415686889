////////////////////////////////
// Projects Table Heading
///////////////////////////////

// Basic Imports

// Design Imports
import { Heading, HeadingProps, useColorModeValue as mode } from "@chakra-ui/react";
import { FC } from "react";
import colors from "settings/theme/colors";

// Layout and Section Imports

// Data Imports

// Custom Hook Imports

// Functions

// Interfaces
interface Props extends HeadingProps {}

const TableHeading:FC<Props> = ({ children, ...resetProps }) => {
  return (
    <Heading
      fontSize="24"
      py="4"
      textAlign="center"
      color={mode(colors.light, colors.dark)}
      {...resetProps}
    >
      {children}
    </Heading>
  );
};

export default TableHeading;
