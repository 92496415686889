import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updateProjectByStatus } from "services/projectsService";
import { ROUTES } from "settings/constants/routes";
import { ERRORS } from "settings/constants/toastMessages";
import { ProjectStatusesProps } from "types/project";
import useAuth from "./useAuth";
import useCustomToast from "./useCustomToast";

interface BtnLoadingProps {
  btn: ProjectStatusesProps;
  isLoading: boolean;
}

const useActionButtons = () => {
  const [btnLoading, setBtnLoading] = useState<BtnLoadingProps>({
    btn: "draft",
    isLoading: false,
  });
  const { id: projectId } = useParams();
  const { auth }: any = useAuth();
  const { errorToast } = useCustomToast();
  const navigate = useNavigate();

  const updateProjectStatus = async (status: ProjectStatusesProps) => {
    setBtnLoading({ btn: status, isLoading: true });
    const { error } = await updateProjectByStatus(
      projectId,
      { status },
      auth.accessToken
    );
    setBtnLoading({ btn: status, isLoading: false });

    if (!!error) return errorToast({ title: ERRORS.GENERIC });

    if (status === "active") return navigate(ROUTES.PROJECTS_ACTIVE);
    if (status === "completed") return navigate(ROUTES.PROJECTS_COMPLETED);
    if (status === "deleted") return navigate(ROUTES.PROJECTS_DELETED);
    if (status === "draft") return navigate(ROUTES.PROJECTS_DRAFT);
    if (status === "paused") return navigate(ROUTES.PROJECTS_PAUSED);
  };

  const redirectToWizard = () =>
    navigate(`${ROUTES.PROJECTS_NEW}/${projectId}`);

  return {
    btnLoading,
    handlers: { updateProjectStatus, redirectToWizard },
  };
};

export default useActionButtons;
