/////////////////////
// Style Card UI used on Style Step of New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Button, FlexProps, Icon, Stack } from "@chakra-ui/react";

import { GoPencil } from "react-icons/go";

// Layout and Section Imports
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";

// Data Imports
import { StyleCardProps } from "data/projectWizardData/styleCardData";
import StyleCards from "components/ui/common/StyleCardForSteps/StyleCardsForSteps";

// Interfaces
interface StyleProps extends FlexProps {
  id: string;
  title: string;
  options: StyleCardProps[];
  onChange?: any;
  onSelected?: any;
  showSelectedOnly?: boolean;
  isReadOnly?: boolean;
}
export interface Item {
  label: string;
  value: string;
}

export const StyleCardForSteps: FC<StyleProps> = ({
  id,
  title,
  options,
  onSelected,
  showSelectedOnly,
  isReadOnly,
}) => {
  if (options.length === 0) return null;
  return (
    <Card>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "4", md: "10" }}
      >
        <CardContent>
          <CardHeader
            title={title}
            action={
              <Button
                hidden={true}
                variant="outline"
                leftIcon={
                  <Icon as={GoPencil} color="gray.400" marginStart="-1" />
                }
              >
                Edit
              </Button>
            }
          />

          {/* Content Section */}
          <StyleCards
            id={id}
            title={title}
            options={options}
            isReadOnly={isReadOnly}
            selectedOptions={[]}
            showSelectedOnly={showSelectedOnly}
            onChange={(selectedTypeOption) => {
              onSelected(selectedTypeOption);
            }}
            onSelected={onSelected}
          />
        </CardContent>
      </Stack>
    </Card>
  );
};