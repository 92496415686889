/////////////////////
// 404 Page not found
/////////////////////

// Basic Imports
import { Link } from "react-router-dom";

// Design Imports
import { Box, Container, Heading, Stack, Text } from "@chakra-ui/react";

import { Logo } from "components/ui/logo/Logo2";
import Lottie from "lottie-react";
import pageNotFound from "animation/404-robot-error.json";

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
const Custom404 = () => {
  return (
    <Container centerContent py="16">
      <Link to="/">
        <Stack spacing="4">
          <Logo h="10" />
          <Box textAlign="center">
            <Lottie
              animationData={pageNotFound}
              autoplay={true}
              loop={true}
              style={{ height: "200px" }}
            />
            <Heading fontSize="md">Page not found</Heading>
            <Text>Click here to go back to homepage.</Text>
          </Box>
        </Stack>
      </Link>
    </Container>
  );
};

export default Custom404;
