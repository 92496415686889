/////////////////////
// ChakraUI Theme Config
/////////////////////

import { extendTheme, ThemeConfig } from "@chakra-ui/react";

import styles from "./styles";
import foundations from "./foundations";
import components from "./components";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const customTheme = extendTheme(
  { config },
  {
    /* Global style overrides */
    styles: styles,

    /* Foundational style overrides */
    colors: foundations.colors,
    layerStyles: foundations.layerStyles,
    fonts: foundations.fonts,
    // borders: foundations.borders,

    /* Component style overrides */
    components: components,
    // Used on home page for Floor Plan Headings (i.e. Unit A1)
    /*Box, Img, Input, ModalHeader Doesn't work for some reason. Use global classes for styling above
     */
  }
);

export default customTheme;
