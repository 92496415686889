///////////////////////////////
// Individual Project Page
///////////////////////////////

// Basic Imports
import _ from "lodash";
import { useLocation, useParams } from "react-router-dom";

// Design Imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Badge,
  Box,
  Stack,
} from "@chakra-ui/react";
import SpinnerLoading from "components/ui/Loaders/SpinnerLoading";

// Layout and Section Imports
import ProjectInfoCard from "components/sections/ProjectsSection/Cards/AProjectInfo/ProjectInfoCard";
import DesiredAssetsCard from "components/sections/ProjectsSection/Cards/BDesiredAssets/DesiredAssetsCard";
import OutputCard from "components/sections/ProjectsSection/Cards/COutput/OutputCard";
import Chat from "components/sections/ProjectsSection/Chat";
import ProjectsContainer from "components/sections/ProjectsSection/ProjectsContainer";

// Data Imports
import { assets } from "data/projectWizardData";
import {
  render_db_project__project_info,
  render_project_wizard_schema_from_db_project,
} from "schemas/project_wizard_schema";
import { fetchProjectByIdService } from "services/projectsService";

// Custom Hooks and Services
import ActivityCard from "components/sections/ProjectsSection/Cards/Activity/ActivityCard";
import StyleCardsReadOnly from "components/sections/ProjectsSection/Cards/StyleCardsReadOnly";
import TaskCard from "components/sections/ProjectsSection/Cards/Task/TaskCard";
import SupportingDocumentsStep from "components/sections/ProjectsSection/Steps/ESupportingDocuments/SupportingDocumentsStep";
import { useProject } from "context/project/ProjectProvider";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import ProjectPageFooter from "./ProjectPageFooter";
import ProjectPageHeader from "./ProjectPageHeader";
import AccordionItemCustom from "components/ui/common/AccordionItemCustom";
import moment from "moment";

// Interfaces

// Functions
const ProjectPage = () => {
  const projectContext = useProject();
  const { id: projectId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isRedirectedForComment = queryParams.get("isComment") ? true : false;
  const { auth }: any = useAuth();
  const [project, setProject] = useState(null);
  const [activity, setActivity] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  async function getAsyncData() {
    const { data, error } = await fetchProjectByIdService(
      projectId,
      auth.accessToken
    );
    if (!!error) {
      setIsLoading(false);
      setIsError(true);
      return;
    }

    const project = data.data.project;

    const projectWizard = render_project_wizard_schema_from_db_project(project);
    projectContext.onUpdateProjectWizard({
      ...projectContext.projectWizard,
      hasProjectInitialized: true,
      ...projectWizard,
    });

    const activity = project?.history;
    setActivity(activity);
    setProject(project);
    setIsLoading(false);
  }
  useEffect(() => {
    setIsLoading(true);

    getAsyncData();
    // DOC: It will remove initialized project from project wizard.
    return () => {
      projectContext.onCloseProjectWizard();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  if (!!isLoading || !projectContext.projectWizard.id)
    return <SpinnerLoading />;
  if (!!isError)
    return (
      <Alert status="info">
        <AlertIcon />
        Nothing to show
      </Alert>
    );
  if (projectContext.projectWizard.id === "") return null;

  return (
    <ProjectsContainer>
      <ProjectPageHeader project={project} />
      {/* Task Section */}
      {project.tasks.length > 0 && (
        <TaskCard
          styles={{ my: 5 }}
          taskData={project.tasks}
          selectedOutput={project.selectedOutputs}
          getAsyncData={getAsyncData}
        />
      )}

      {projectContext.projectWizard.selectedAssets.length > 0 ? (
        <Accordion
          rounded="md"
          allowToggle
          pt={5}
          defaultIndex={isRedirectedForComment ? 0 : null}
        >
          <AccordionItem border="none">
            <h2>
              <AccordionButton
                _focus={{ boxShadow: "none" }}
                border="2px"
                borderColor="gray.200"
                rounded="md"
                _expanded={{ bg: "gray.200" }}
              >
                <Box flex="1" textAlign="left" fontSize="22" fontWeight="bold">
                  ASSETS AND PROJECT COMMENTS
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Chat my={5} project={project} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : null}

      <Accordion rounded="md" allowToggle pt={5}>
        <AccordionItem border="none">
          <h2>
            <AccordionButton
              _focus={{ boxShadow: "none" }}
              border="2px"
              borderColor="gray.200"
              rounded="md"
              _expanded={{ bg: "gray.200" }}
            >
              <Box flex="1" textAlign="left" fontSize="22" fontWeight="bold">
                SUMMARY
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Accordion allowToggle defaultIndex={[0]}>
              <Stack>
                <AccordionItemCustom title="Project Info">
                  <ProjectInfoCard
                    styles={{ my: 5 }}
                    data={render_db_project__project_info(project)}
                    projectId={project.id}
                    readOnly={true}
                  />
                </AccordionItemCustom>
                {project.selectedStyles.length > 0 && (
                  <AccordionItemCustom title="Assets">
                    <DesiredAssetsCard
                      styles={{ my: 5 }}
                      assets={_.intersectionBy(
                        assets,
                        project.selectedStyles,
                        "title"
                      )}
                      projectId={project.id}
                      readOnly={true}
                    />
                  </AccordionItemCustom>
                )}
                {project.selectedOutputs.length > 0 && (
                  <AccordionItemCustom title="Outputs">
                    <OutputCard
                      styles={{ my: 5 }}
                      data={project.selectedOutputs}
                      projectId={project.id}
                      readOnly={true}
                    />
                  </AccordionItemCustom>
                )}

                {projectContext.selectedStyles.length > 0 && (
                  <AccordionItemCustom title="Styles">
                    <StyleCardsReadOnly />
                  </AccordionItemCustom>
                )}

                {projectContext.supportingDocuments.length > 0 && (
                  <AccordionItemCustom title="Supporting Documents">
                    <SupportingDocumentsStep
                      showHeader
                      showUploadingForm={false}
                      showDeleteButton={false}
                    />
                  </AccordionItemCustom>
                )}
              </Stack>
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* Activity Section */}
      {activity && (
        <Accordion rounded="md" allowToggle pt={5}>
          <AccordionItem border="none">
            <h2>
              <AccordionButton
                _focus={{ boxShadow: "none" }}
                border="2px"
                borderColor="gray.200"
                rounded="md"
                _expanded={{ bg: "gray.200" }}
              >
                <Box flex="1" textAlign="left" fontSize="22" fontWeight="bold">
                  PROJECT HISTORY
                  <Badge ml={4} mb={1} colorScheme="blue">
                    {moment(activity?.updatedAt).format(
                      "MMM Do, YYYY, hh:mm:ss a"
                    )}
                  </Badge>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ActivityCard styles={{ my: 5 }} activity={activity} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}

      <ProjectPageFooter style={{ mt: 8 }} project={project} />
    </ProjectsContainer>
  );
};

export default ProjectPage;
