/////////////////////
// New Project Page
/////////////////////

// Basic Imports
import { Fragment, useEffect, useState } from "react";

// Design Imports
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  Tooltip,
  Wrap,
} from "@chakra-ui/react";
import { HiPlus } from "react-icons/hi";

// Layout and Section Imports
import { Steps } from "components/ui/steps/Steps";
import ProjectInfoStep from "components/sections/ProjectsSection/Steps/AProjectInfo/ProjectInfoStep";
import DesiredAssetsStep from "components/sections/ProjectsSection/Steps/BDesiredAssets/DesiredAssetsStep";
import OutputStep from "components/sections/ProjectsSection/Steps/COutput/OutputStep";
import StyleStep from "components/sections/ProjectsSection/Steps/DStyle/StyleStep";
import SupportingDocumentsStep from "components/sections/ProjectsSection/Steps/ESupportingDocuments/SupportingDocumentsStep";
import SummaryStep from "components/sections/ProjectsSection/Steps/FSummary/SummaryStep";

import ProjectsContainer from "components/sections/ProjectsSection/ProjectsContainer";

// Data Imports
import { assets } from "data/projectWizardData";
import useNewProjectPage from "./useNewProjectPage";
import useAuth from "hooks/useAuth";
import Alert from "components/features/Alert";
import Loader from "components/ui/common/Loader";
import WrapStep from "components/ui/steps/WrapStep";
import useAuthForm from "components/forms/AuthForm/useAuthForm";
import useCustomToast from "hooks/useCustomToast";
import DragDropTable from "components/sections/ProjectsSection/Table/DragDropTable";
import { columns } from "components/sections/ProjectsSection/Table/Body";
import { TABLE_NAME } from "settings/constants/QueryTable";
import TableHeading from "components/sections/ProjectsSection/Table/TableHeading";
import ButtonTooltip from "components/ui/common/Tooltips/ButtonTooltip";
import ActionButton from "components/sections/ProjectsSection/Table/Body/ActionButton";
import { TOOLTIP } from "settings/constants/projectWizard";
import { ProjectPage } from "services/projectsService";

// Services Imports

// Interfaces

// Functions
const NewProjectsPage = ({ notificationData, isConnected }: ProjectPage) => {
  const { stepState, draftProjects, projectContext, handlers, btnLoading } =
    useNewProjectPage({ shouldFetchDraftProjects: true });
  const { user }: any = useAuth();
  const { handleSendVerificationEmail } = useAuthForm();
  const [verificationLoading, setVerificationLoading] = useState(false);
  const { successToast } = useCustomToast();
  const [loading, setLoading] = useState(true);
  const [shouldAllowSave, setShouldAllowSave] = useState({
    isLoading: false,
    isTrue: false,
  }); // DOC: This loading means context api is loading and we can use to disable buttons that send context api state to backend

  useEffect(() => {
    projectContext.loadProjectWizardFromLocalStorage();
    setLoading(false);

    return () => {
      projectContext.onResetProjectWizard();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user || Object.keys(user).length === 0 || !!loading) return <Loader />;
  if (Object.keys(user).length > 0 && !user.verified)
    return (
      <Container centerContent>
        <Alert
          w="max"
          status="warning"
          title="Your account must be verified for creating project."
          link={{
            label: verificationLoading ? "Sending..." : "Resend Email",
            onClick: async () => {
              setVerificationLoading(true);
              const { status } = await handleSendVerificationEmail();
              setVerificationLoading(false);

              if (status === 200) {
                successToast({
                  title: "Verification email has been sent successfully!",
                });
              }
            },
          }}
          showCloseIcon={false}
        />
      </Container>
    );
  return (
    <Fragment>
      <DragDropTable
        isLoading={draftProjects.isLoading}
        notificationData={notificationData}
        title="Resume a Draft"
        columns={columns({ tableName: TABLE_NAME.DRAFT_TABLE })}
        data={draftProjects.projects.slice(0, 5)}
        updateDataOnPriority={draftProjects.onChangePriorities}
        setData={draftProjects.setProjects}
      />

      {/* INFO: First initialize the project with button then show the form */}
      {!projectContext.projectWizard.hasProjectInitialized ? (
        <Flex justifyContent="right">
          <Tooltip hasArrow placement="bottom" label="">
            <Button
              isLoading={projectContext.initializeLoading}
              colorScheme="blue"
              leftIcon={<Icon fontSize="24" as={HiPlus} />}
              onClick={() => projectContext.onInitializeProject()}
              isDisabled={false}
            >
              Create new project
            </Button>
          </Tooltip>
        </Flex>
      ) : (
        <ProjectsContainer>
          <Wrap justify="space-between" pb="6" align="center">
            <TableHeading>New Project Wizard</TableHeading>
            <ButtonTooltip label="It will remove this project from draft projects.">
              <ActionButton
                btnText="Delete Draft"
                colorScheme="red"
                mr={2}
                isLoading={btnLoading.btn === "delete" && btnLoading.isLoading}
                alertDialog={{
                  title: "Delete Project Draft",
                  description:
                    "Are you sure? This action will delete all the information that you've entered.",
                  onSuccess: () =>
                    handlers.deleteProjectFromDB({ shouldRedirect: true }),
                }}
              />
            </ButtonTooltip>
            <HStack>
              <ButtonTooltip
                label="It will reset the project wizard"
                placement="top"
              >
                <ActionButton
                  btnText="Close"
                  colorScheme="blue"
                  variant="outline"
                  mr={2}
                  isLoading={btnLoading.btn === "close" && btnLoading.isLoading}
                  alertDialog={{
                    title: "Close Draft",
                    description:
                      "Are you sure? Any recent unsaved changes will be discarded",
                    onSuccess: async () => {
                      await handlers.addDraftProjectIntoDB({
                        btnPressed: "close",
                      });
                      projectContext.onCloseProjectWizard();
                    },
                  }}
                />
              </ButtonTooltip>
              <ButtonTooltip
                placement="top"
                label="It will save project in draft projects"
              >
                <Button
                  colorScheme="blue"
                  mr={2}
                  isDisabled={!shouldAllowSave.isTrue}
                  isLoading={btnLoading.btn === "draft" && btnLoading.isLoading}
                  onClick={() =>
                    handlers.addDraftProjectIntoDB({ btnPressed: "draft" })
                  }
                >
                  Save As Draft
                </Button>
              </ButtonTooltip>
            </HStack>
          </Wrap>
          <Steps activeStep={stepState.activeStep}>
            <WrapStep
              title="Project Info"
              nextStep={() => {
                stepState.nextStep();
                if (!shouldAllowSave.isTrue) return;

                handlers.addDraftProjectIntoDB();
              }}
              onStepClick={() => stepState.setStep(0)}
            >
              <ProjectInfoStep />
            </WrapStep>
            <DesiredAssetsStep
              assets={assets}
              prevStep={stepState.prevStep}
              onStepClick={() => stepState.setStep(1)}
              onSelected={(selectedItems) =>
                setShouldAllowSave((prevState) => ({
                  ...prevState,
                  isTrue: selectedItems.length > 0,
                }))
              }
              isStepLoading={(value) =>
                setShouldAllowSave((prevState) => ({
                  ...prevState,
                  isLoading: value,
                }))
              }
              nextStep={() => {
                stepState.nextStep();
                handlers.addDraftProjectIntoDB();
              }}
            />

            <WrapStep
              title="Outputs"
              prevStep={stepState.prevStep}
              disableNextButton={!shouldAllowSave.isTrue}
              titleTooltip={
                !shouldAllowSave.isTrue && TOOLTIP.MINIMUM_DESIRED_ASSETS
              }
              disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
              onStepClick={() => stepState.setStep(2)}
              nextStep={() => {
                stepState.nextStep();
                handlers.addDraftProjectIntoDB();
              }}
            >
              <OutputStep />
            </WrapStep>

            <WrapStep
              title="Styles"
              prevStep={stepState.prevStep}
              onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(3)}
              disableNextButton={!shouldAllowSave.isTrue}
              disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
              titleTooltip={
                !shouldAllowSave.isTrue && TOOLTIP.MINIMUM_DESIRED_ASSETS
              }
              nextStep={async () => {
                stepState.nextStep();
                handlers.addDraftProjectIntoDB();
              }}
            >
              <StyleStep />
            </WrapStep>

            <WrapStep
              title="Supporting Documents"
              prevStep={stepState.prevStep}
              onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(4)}
              disableNextButton={!shouldAllowSave.isTrue}
              disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
              titleTooltip={
                !shouldAllowSave.isTrue && TOOLTIP.MINIMUM_DESIRED_ASSETS
              }
              nextStep={async () => {
                stepState.nextStep();
                handlers.addDraftProjectIntoDB();
              }}
            >
              <SupportingDocumentsStep />
            </WrapStep>

            <WrapStep
              title="Summary"
              prevStep={stepState.prevStep}
              onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(5)}
              titleTooltip={
                !shouldAllowSave.isTrue && TOOLTIP.MINIMUM_DESIRED_ASSETS
              }
              disableNextButton={!shouldAllowSave.isTrue}
              disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
              nextStep={() => {
                stepState.nextStep();
              }}
            >
              <SummaryStep />
            </WrapStep>
          </Steps>

          <Box mt="8" ml="1">
            <ButtonTooltip
              hasArrow
              label={!shouldAllowSave.isTrue && TOOLTIP.MINIMUM_DESIRED_ASSETS}
            >
              <Button
                colorScheme="blue"
                isLoading={
                  btnLoading.btn === "active" ? btnLoading.isLoading : false
                }
                isDisabled={!shouldAllowSave.isTrue}
                onClick={() => {
                  handlers.submitProjectIntoDB({
                    shouldRedirect: true,
                    projectStatus: "active",
                  });
                }}
              >
                Submit
              </Button>
            </ButtonTooltip>
          </Box>
        </ProjectsContainer>
      )}
    </Fragment>
  );
};
export default NewProjectsPage;
