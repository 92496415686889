import { Button, StackProps, Wrap } from "@chakra-ui/react";
import { FC } from "react";
import { getButtonsFromProjectStatus } from "utils/getButtonsFromProjectStatus";
import _ from "lodash";
import useActionButtons from "hooks/useActionButtons";
import { STATUS } from "settings/constants/project";
import { FaPencilAlt, FaUndo } from "react-icons/fa";
import { MdDelete, MdPause } from "react-icons/md";
import { GoCheck } from "react-icons/go";

interface Props {
  style?: StackProps;
  project: any;
}
const ProjectPageFooter: FC<Props> = ({ project, style }) => {
  const { btnLoading, handlers } = useActionButtons();
  const possibleButtons = getButtonsFromProjectStatus(project.status);
  return (
    <Wrap justify="space-between" {...style}>
      {/* <Wrap>
        {_.includes(possibleButtons, "deleted") && (
          <Button
            rightIcon={<MdDelete />}
            colorScheme="red"
            isLoading={btnLoading.btn === "deleted" && btnLoading.isLoading}
            onClick={() => handlers.updateProjectStatus(STATUS.DELETE)}
          >
            Delete
          </Button>
        )}
      </Wrap> */}
      <Wrap>
        {/* {_.includes(possibleButtons, "paused") && (
          <Button
            isLoading={btnLoading.btn === "paused" && btnLoading.isLoading}
            onClick={() => handlers.updateProjectStatus(STATUS.PAUSE)}
            colorScheme="blue"
            variant="outline"
            rightIcon={<MdPause />}
          >
            Pause
          </Button>
        )} */}
        {_.includes(possibleButtons, "edit") && (
          <Button
            colorScheme="blue"
            rightIcon={<FaPencilAlt />}
            onClick={handlers.redirectToWizard}
            variant="outline"
          >
            Edit
          </Button>
        )}
        {/* {_.includes(possibleButtons, "revert") && (
          <Button
            colorScheme="blue"
            variant="outline"
            rightIcon={<FaUndo />}
            isLoading={btnLoading.btn === "active" && btnLoading.isLoading}
            onClick={() => handlers.updateProjectStatus(STATUS.ACTIVE)}
          >
            Revert
          </Button>
        )} */}
      </Wrap>
      <Wrap>
        {_.includes(possibleButtons, "download") && (
          <Button colorScheme="blue">Download</Button>
        )}
        {_.includes(possibleButtons, "complete") && (
          <Button
            rightIcon={<GoCheck />}
            colorScheme="green"
            isLoading={btnLoading.btn === "completed" && btnLoading.isLoading}
            onClick={() => handlers.updateProjectStatus(STATUS.COMPLETE)}
          >
            Complete
          </Button>
        )}
      </Wrap>
    </Wrap>
  );
};

export default ProjectPageFooter;
