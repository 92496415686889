/////////////////////
// Reset (Password) Form 
/////////////////////

// Basic Imports
import { useParams } from "react-router-dom";
import { FormEvent } from "react";

// Design Imports
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackProps,
} from "@chakra-ui/react";


// Layout and Section Imports
import Alert from "components/features/Alert";
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";

// Data Imports
import { ROUTES } from "settings/constants/routes";

// Custom Hooks and Services
import useAuthForm from "../useAuthForm";

// Interfaces

// Functions
export const ResetForm = (props: StackProps) => {
  const { token } = useParams();
  const { user, setPassword, setConfirmPassword, setError, handleResetPassword } = useAuthForm();

  return (
    <Stack spacing="8" {...props}>
      <FormHeader 
        title="Reset Password"
        description="Already have an account?"
        link={{
          label: "Sign in",
          to: ROUTES.LOGIN_IN
        }}
      />
      {!!user.error && <Alert status="error" onClose={() => setError("")} title={user.error} />}
      <FormWrapper 
        onSubmit={(e: FormEvent) => {
          e.preventDefault();

          handleResetPassword(token);
        }}
      >
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              required
              id="password"
              placeholder="********"
              type="password"
              name="password"
              value={user.password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Confirm password</FormLabel>
            <Input
              required
              id="confirmPassword"
              placeholder="********"
              type="password"
              name="confirmPassword"
              value={user.confirmPassword}
              onChange={(e) => setConfirmPassword(e.currentTarget.value)}
            />
          </FormControl>
        </Stack>
        <Stack spacing="4">
          <Button
            variant="primary"
            color="white"
            bg="blue.500"
            _hover={{ backgroundColor: "blue.600" }}
            type="submit"
            isLoading={user.isLoading}
          >
            Submit
          </Button>
        </Stack>
      </FormWrapper>
    </Stack>
  );
};
