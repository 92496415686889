import { Container, Spinner, SpinnerProps } from '@chakra-ui/react';
import { FC } from 'react';

interface ISpinnerLoading extends SpinnerProps {}
const SpinnerLoading:FC<ISpinnerLoading> = () => {
  return (
    <Container py="6" centerContent>
      <Spinner 
        size="lg"
        thickness='3px'
        color='blue.500'
        emptyColor='gray.200'
      />
    </Container>
  );
}

export default SpinnerLoading