import { Stack, chakra } from "@chakra-ui/react";

const FormWrapper = ({ onSubmit, children }) => {
  return (
    <chakra.form onSubmit={onSubmit}>
      <Stack spacing="6">{children}</Stack>
    </chakra.form>
  );
};

export default FormWrapper;
