/////////////////////
// Use Project Info Hook
/////////////////////

// Basic Imports

// Design Imports

// Layout and Section Imports

// Data Imports
import {
  SET_PROJECT_ADDRESS_LINE_1,
  SET_PROJECT_ADDRESS_LINE_2,
  SET_PROJECT_BATHROOMS,
  SET_PROJECT_BEDROOMS,
  SET_PROJECT_CITY,
  SET_PROJECT_COUNTRY,
  SET_PROJECT_DEPTH,
  SET_PROJECT_DESCRIPTION,
  SET_PROJECT_INFO,
  SET_PROJECT_NAME,
  SET_PROJECT_CREATEDBY,
  SET_PROJECT_POSTAL_CODE,
  SET_PROJECT_SIZE,
  SET_PROJECT_STATE,
  SET_PROJECT_STORIES,
  SET_PROJECT_WIDTH,
} from "context/project/ProjectTypes";

// Custom Hooks and Services

// Interfaces

// Functions
const useProjectInfo = (state, dispatch) => {
  const handleChangeProjectCreatedBy = (createdBy) => {
    dispatch({ type: SET_PROJECT_CREATEDBY, payload: createdBy });
  };
  const handleChangeProjectName = (name) => {
    dispatch({ type: SET_PROJECT_NAME, payload: name });
  };
  const handleChangeProjectDescription = (description) => {
    dispatch({ type: SET_PROJECT_DESCRIPTION, payload: description });
  };
  const handleChangeProjectAddressLine1 = (address) => {
    dispatch({ type: SET_PROJECT_ADDRESS_LINE_1, payload: address });
  };
  const handleChangeProjectAddressLine2 = (address) => {
    dispatch({ type: SET_PROJECT_ADDRESS_LINE_2, payload: address });
  };
  const handleChangeProjectCity = (city) => {
    dispatch({ type: SET_PROJECT_CITY, payload: city });
  };
  const handleChangeProjectState = (state) => {
    dispatch({ type: SET_PROJECT_STATE, payload: state });
  };
  const handleChangeProjectCountry = (country) => {
    dispatch({ type: SET_PROJECT_COUNTRY, payload: country });
  };
  const handleChangeProjectPostalCode = (postalCode) => {
    dispatch({ type: SET_PROJECT_POSTAL_CODE, payload: postalCode });
  };
  const handleChangeProjectBedrooms = (bedrooms) => {
    dispatch({ type: SET_PROJECT_BEDROOMS, payload: bedrooms });
  };
  const handleChangeProjectBathrooms = (bathrooms) => {
    dispatch({ type: SET_PROJECT_BATHROOMS, payload: bathrooms });
  };
  const handleChangeProjectStories = (stories) => {
    dispatch({ type: SET_PROJECT_STORIES, payload: stories });
  };
  const handleChangeProjectSize = (size) => {
    dispatch({ type: SET_PROJECT_SIZE, payload: size });
  };
  const handleChangeProjectWidth = (width) => {
    dispatch({ type: SET_PROJECT_WIDTH, payload: width });
  };
  const handleChangeProjectDepth = (depth) => {
    dispatch({ type: SET_PROJECT_DEPTH, payload: depth });
  };

  const handleAddProjectInfo = async (projectInfo) => {
    dispatch({ type: SET_PROJECT_INFO, payload: projectInfo });
  };

  const handleAddProjectInfoToDb = async (projectInfo) => {
    if (Object.keys(projectInfo)) {
      // It will check how many input fields has been filled
      // const percentage: number = getObjectFulFillmentPercentage(projectInfo);
    }

    return;

    // TODO: Just waiting for backend
    // return initializeProject({ completionStage: stage.toString(), ...project });
  };

  return {
    projectInfo: state.projectWizard.projectInfo,
    handleChangeProjectName,
    handleChangeProjectCreatedBy,
    handleChangeProjectDescription,
    handleChangeProjectAddressLine1,
    handleChangeProjectAddressLine2,
    handleChangeProjectCity,
    handleChangeProjectState,
    handleChangeProjectCountry,
    handleChangeProjectPostalCode,
    handleChangeProjectBedrooms,
    handleChangeProjectBathrooms,
    handleChangeProjectStories,
    handleChangeProjectSize,
    handleChangeProjectWidth,
    handleChangeProjectDepth,
    handleAddProjectInfo,
    handleAddProjectInfoToDb,
  };
};

export default useProjectInfo;
