/////////////////////
// Desired Assets Card
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  BoxProps,
  Stack,
} from "@chakra-ui/react";


// Layout and Section Imports
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";
import DesireAssetsBoxes from "./DesireAssetsBoxes";


// Data Imports

// Context API

// Interfaces
interface Props {
  assets: any
  styles: BoxProps
  projectId?: string
  readOnly?: boolean
}

// Functions
const DesiredAssetsCard: FC<Props> = ({ assets, styles, projectId, readOnly }) => {
  return (
    <Card id={projectId} {...styles}>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "4", md: "10" }}
      >
        <CardContent>
          <CardHeader
            title="Assets Selected"
            action={
              <></>
            }
          />
          {/* Selected Assets Options */}
          <DesireAssetsBoxes
            assets={assets}
            readOnly={readOnly}
          />
        </CardContent>
      </Stack>
    </Card>
  );
};

export default DesiredAssetsCard;