/////////////////////
// App Layout
/////////////////////

// Basic Imports
import { FC } from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";

// Design Imports
import {
  Badge,
  Box,
  Flex,
  HStack,
  Link,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";

import Alert from "components/features/Alert";
import useAuth from "hooks/useAuth";
import { ROUTES } from "settings/constants/routes";
import { MobileHamburgerMenu } from "../ui/appMenu/MobileHamburgerMenu";
import { NavMenu } from "../ui/appMenu/NavMenu";
import { ProfileDropdown } from "../ui/appMenu/ProfileDropdown";
import { useMobileMenuState } from "../ui/appMenu/useMobileMenuState";
import { Logo } from "../ui/logo/Logo2";
import NotificationMenu from "components/ui/appMenu/NotificationMenu";
import { ProgressGuideLogo } from "components/ui/logo/ProgressGuideLogo";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services

// Interfaces
interface AppLayout {
  notificationData: any;
  isConnected: boolean;
  onDeleteNotification: (id: string | string[]) => void;
}

// Functions
const AppLayout = ({
  notificationData,
  isConnected,
  onDeleteNotification,
}: AppLayout) => {
  const { isMenuOpen, toggle, onCloseModal } = useMobileMenuState();
  const { user }: any = useAuth();

  return (
    <Box w="full" minH="100vh" h="max">
      <Box
        color={"#4A5568"}
        minH="16"
        pt="4"
        pb="4"
        boxShadow="0px 8px 20px rgb(0 0 0 / 4%)"
      >
        <Flex
          justify="space-between"
          align="center"
          maxW="container.xl"
          mx="auto"
          px={{ base: "2", md: "6" }}
        >
          <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
          <NavMenu.Mobile isOpen={isMenuOpen} onClickNavItem={onCloseModal} />
          <VStack position="relative">
            <Box>
              <Link as={RouterLink} to="/">
                <Logo
                  display={{ base: "none", lg: "block" }}
                  flexShrink={0}
                  h="10"
                  marginEnd="10"
                />
              </Link>
            </Box>
            <HStack
              mt="-8px!important"
              opacity={useBreakpointValue({ base: "0", md: "1" })}
            >
              <Text color="gray.800" fontSize="xs" fontWeight="bold" mr="-1">
                BY
              </Text>
              <Box>
                <ProgressGuideLogo color="gray.600" />
              </Box>
            </HStack>

            <Badge
              display={{ base: "none", lg: "block" }}
              colorScheme="red"
              rounded="lg"
              variant="solid"
              position="absolute"
              transform="translate3d(-125%, -50%, 0)"
              right="-24px"
              top="2px"
            >
              <Text color="white">Staff</Text>
            </Badge>
          </VStack>

          {/* NavItems */}
          <NavMenu.Desktop />

          <Link as={RouterLink} to="/">
            <Logo
              flex={{ base: "1", lg: "0" }}
              display={{ lg: "none" }}
              flexShrink={0}
              h={{ base: 4, sm: 8, md: 10 }}
            />
          </Link>
          <Badge
            display={{ base: "block", lg: "none" }}
            colorScheme="red"
            rounded="lg"
            variant="solid"
            position="relative"
            transform="translate3d(-50%, -50%, 0)"
          >
            <Text color="white">Beta</Text>
          </Badge>
          <HStack spacing="9">
            <NotificationMenu
              isConnected={isConnected}
              notificationData={notificationData}
              onDeleteNotification={onDeleteNotification}
            />
            {/* <Popover>
              <PopoverTrigger>
                <Box>
                  <Notification display={{ base: "none", lg: "inline-flex" }} />
                </Box>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton color="black" justifyContent="center" />
                <PopoverHeader>
                  <Grid
                    templateColumns="repeat(3, 1fr)"
                    gap={6}
                    alignItems="center"
                  >
                    <Box as={GoBell} fontSize="lg" color="blue.600" />
                    <Heading as="h4" size="xs">
                      <Center h="32px" color="black">
                        Notification
                      </Center>
                    </Heading>
                  </Grid>
                </PopoverHeader>
                <PopoverBody p={0}>
                  <Box>
                    <NotificationBody></NotificationBody>
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover> */}
            <ProfileDropdown />
          </HStack>
        </Flex>
      </Box>
      {user?.verified === false && (
        <Alert
          showCloseIcon={false}
          status="warning"
          my="0"
          title="Your account is not verified!"
          link={{
            label: "Click here to verify",
            href: ROUTES.ACCOUNT_VERIFY,
          }}
        />
      )}
      <Outlet />
    </Box>
  );
};

export default AppLayout;
