import { createSlice } from '@reduxjs/toolkit'

export const currencySlice = createSlice({
  name: 'currency',
  initialState: {
    currencyType: "USD"
  },
  reducers: {
    changeCurrencyType: (state, action) => {
      state.currencyType = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { changeCurrencyType } = currencySlice.actions;

export default currencySlice.reducer