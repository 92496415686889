///////////////////////////////
// Help and Support Page
///////////////////////////////

// Basic Imports


// Design Imports
import { 
    SimpleGrid 
} from "@chakra-ui/react";

// Layout and Section Imports
import HelpSection from "components/sections/HelpSection";

// Data Imports


// Services Imports

// Interfaces

// Functions
const HelpPage = ({ project = [] }) => {

  return (
    <SimpleGrid columns={1} width="100%" spacing="6" id="SimpleGrid">
        {/* Help section */}
        <HelpSection />
    </SimpleGrid>
  );
};

export default HelpPage;
